import React, { Component } from "react";
import { Link } from "react-router-dom";
import { wp } from "../api/WpApi";
import { PostBadge } from "../alerts/PostBadge";
import "../../styles/wpStyles.scss";
import WpService from "./WpService";

import data from "./_setup.json";

import PageLoading from "./pageLoading";
import { Footer } from "../main/Footer";
import MetaTags from "../seo/MetaTags";
import { Helmet } from "react-helmet";

// Date Format: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString

class PostList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoading: true,
      posts: [],
      postsperpage: 10,
      currentpage: 1,
      totalpages: null,
      totalCategories: null,
      selectedRadio: [],
    };

    this.loadMore = this.loadMore.bind(this);
  }

  readTime(content) {
    const wordsPerMinute = 300; // Average case.
    let result;

    let textLength = content.split(" ").length; // Split by words
    if (textLength > 0) {
      let value = Math.ceil(textLength / wordsPerMinute);
      result = `~${value} min. read`;
    }
    return result;
  }

  loadMore() {
    this.setState((prev) => {
      return { currentpage: prev.currentpage + 1 };
    });

    this.getPosts(this.state.currentpage, null);
  }

  componentDidMount() {
    // Load initial
    this.loadMore();
    this.getCategories();
  }
  getCategories = () => {
    // Fetch pages with search value "xyz": http://wp-api.org/node-wpapi/using-the-client/#api-query-parameters
    wp.categories()
      .then((categories) => {
        categories != null &&
          this.setState({
            isLoading: true,
            totalCategories: categories, // Get first element from array
          });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  };

  handleRadioChange = (e) => {
    let checkedRadio = this.state.selectedRadio;
    e.target.checked
      ? checkedRadio.push(e.target.id)
      : (checkedRadio = checkedRadio.filter((f) => f !== e.target.id));

    this.setState({ isLoading: false, selectedRadio: checkedRadio }); // Update the state with the id of the selected radio button
    this.getPosts(1, checkedRadio);
  };

  cbChange = (e) => {
    var cbs = [];
    cbs.target = document.getElementsByClassName("cb");
    for (var i = 0; i < cbs.target.length; i++) {
      cbs.target[i].checked = false;
    }
    e.target.checked = false;
    this.setState({ isLoading: false, selectedRadio: [] }); // Update the state with the id of the selected radio button
    this.getPosts(1, []);
  };

  getPosts = (currentpage, categoryId) => {
    //console.log(currentpage);

    // Fetch posts: http://wp-api.org/node-wpapi/using-the-client/#api-query-parameters
    wp.posts()
      .categories(categoryId)
      // .param("before", new Date("2016-09-22")) // Sitaip galima filtrus det. Leidzia NULLL TODO: CIAIAIAISADIJASID
      // .param("category", this.state.selectedRadio)
      .perPage(this.state.postsperpage)
      .page(currentpage)
      .then((posts) => {
        //console.log(posts);

        // Append post data to existing posts
        if (currentpage === 1) {
          this.setState({
            isLoading: false,
            posts: posts,
            totalpages: posts._paging.totalPages,
          });
        } else {
          let existingposts = this.state.posts;
          this.setState({
            isLoading: false,
            posts: existingposts.concat(posts),
          });
        }
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  };

  render() {
    const { posts, post, error, isLoading } = this.state;

    if (error) {
      return <div className="content">Error: {error.message}</div>;
    } else if (isLoading) {
      return (
        <div className="container">
          <PageLoading />
        </div>
      );
    } else if (posts != []) {
      return (
        <div className="content container">
          {/* META TAGS FOR POST LIST */}
          {/* <MetaTags name="description" content="Here you can find Every Single Andrew's Cookin' Recipe from Videos and Even More!"></MetaTags> */}
          {/*  */}
          <div className="row">
            <div className="col-md"></div>
            <div className="col-md-10">
              <h1 className="fw-bold">All The Recipes</h1>
              <small className="fw-light">
                as seen on Andrew's Cookin' YouTube & more
              </small>
              {/* <Helmet><title>Andrew's Cookin' YouTube Channel All Recipes. No Bulls**t Recipes, Recipe Tutorial Videos, Recipe Search Engine & More</title></Helmet> */}
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md">
              Filter Recipes:
              <hr />
              <div className="list-group-horizontal-md align-items-start filter-list-group">
                <div className="form-check" key="default-category">
                  <input
                    key="default-category"
                    className="form-check-input"
                    type="checkbox"
                    name="exampleRadios1"
                    id="default"
                    value="Default"
                    onChange={this.cbChange}
                    style={{ cursor: "pointer" }}
                  ></input>
                  <label
                    key="default"
                    className="form-check-label"
                    htmlFor="default"
                    style={{ cursor: "pointer" }}
                  >
                    All Posts
                  </label>
                </div>
                {this.state.totalCategories != null &&
                  this.state.totalCategories.map((category) => (
                    <div className="form-check" key={category.id + "-category"}>
                      <input
                        key={category.id + "-category"}
                        className="form-check-input cb"
                        type="checkbox"
                        name="exampleRadios1"
                        id={category.id}
                        value={category.id}
                        onChange={this.handleRadioChange}
                        style={{ cursor: "pointer" }}
                      ></input>
                      <label
                        key={category.id}
                        className="form-check-label"
                        htmlFor={category.id}
                        style={{ cursor: "pointer" }}
                      >
                        {category.name}
                      </label>
                    </div>
                  ))}
              </div>
              <hr />
            </div>
            <div className="list-group col-lg-10">
              {posts.map((post) => (
                <Link
                  key={post.id}
                  to={post.slug}
                  className="shadow-sm list-group-item list-group-item-action transition ease-in-out delay-50 duration-300"
                >
                  {post.author === 1 && (
                    <PostBadge
                      text="Recipe"
                      style="absolute top-6 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20"
                      color="success"
                    ></PostBadge>
                  )}
                  <h2
                    style={{ padding: 20 }}
                    dangerouslySetInnerHTML={{
                      __html: post.title.rendered,
                    }}
                  />

                  <div className="post-category">
                    Categories:
                    <div>
                      {post.class_list !== null &&
                        WpService.getItemFromClassList(
                          post.class_list,
                          "category-"
                        ).map((item) => (
                          <PostBadge
                            key={item}
                            text={item}
                            style="child-category"
                            color="secondary"
                          ></PostBadge>
                        ))}
                    </div>
                  </div>
                  {post.yoast_head_json.og_image !== undefined &&
                    post.yoast_head_json && (
                      <div className="post-thumbnail">
                        <img src={post.yoast_head_json.og_image[0].url}></img>
                      </div>
                    )}
                  <p
                    className="excerpt-limit"
                    dangerouslySetInnerHTML={{
                      __html: post.excerpt.rendered,
                    }}
                  />
                  <div className="post-date absolute right-6 -translate-x-1/6 z-20">
                    <small>
                      {new Date(post.date).toLocaleDateString(
                        data.format.date.locale,
                        data.format.date.options
                      )}
                    </small>
                  </div>
                  <p className="post-read-time font-weight-light">
                    <u>{this.readTime(post.content.rendered)}</u>
                  </p>
                </Link>
              ))}
            </div>
          </div>
          {this.state.currentpage <= this.state.totalpages && (
            <div className="row">
              <div className="col-md"></div>
              <div className="col-md-10">
                <button
                  onClick={this.loadMore}
                  type="button"
                  className="btn btn-primary d-block my-5 mx-auto"
                >
                  Load more
                </button>
              </div>
            </div>
          )}
          <hr />
          <Footer></Footer>
        </div>
      );
    } else {
      return <div className="content">Nothing to View!</div>;
    }
  }
}

export default PostList;
