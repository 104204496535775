import React from "react";
import { Container } from "react-bootstrap";
import { Footer } from "../components/main/Footer";

class Privacy extends React.Component {
  render() {
    return (
      <Container>
        <div>
          <div>
            <strong>
              <span>
                <span data-custom-class="title">
                  <bdt className="block-component"></bdt>
                  <bdt className="question">PRIVACY POLICY</bdt>
                  <bdt className="statement-end-if-in-editor"></bdt>
                </span>
              </span>
            </strong>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <strong>
                <span>
                  <span data-custom-class="subtitle">
                    Last updated <bdt className="question">July 12, 2024</bdt>
                  </span>
                </span>
              </strong>
            </span>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  This privacy notice for{" "}
                  <bdt className="question">
                    Andrews Cookin<bdt className="block-component"></bdt>
                  </bdt>{" "}
                  (<bdt className="block-component"></bdt>"<strong>we</strong>,"
                  "<strong>us</strong>," or "<strong>our</strong>"
                  <bdt className="statement-end-if-in-editor"></bdt>
                </span>
                <span data-custom-class="body_text">
                  ), describes how and why we might collect, store, use, and/or
                  share (<bdt className="block-component"></bdt>"
                  <strong>process</strong>"
                  <bdt className="statement-end-if-in-editor"></bdt>) your
                  information when you use our services (
                  <bdt className="block-component"></bdt>"
                  <strong>Services</strong>"
                  <bdt className="statement-end-if-in-editor"></bdt>), such as
                  when you:
                </span>
              </span>
            </span>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li data-custom-class="body_text">
              <span>
                <span>
                  <span data-custom-class="body_text">
                    Visit our website<bdt className="block-component"></bdt> at{" "}
                    <span>
                      <bdt className="question">
                        <a
                          href="http://www.andrewscookin.com"
                          target="_blank"
                          data-custom-class="link"
                        >
                          http://www.andrewscookin.com
                        </a>
                      </bdt>
                    </span>
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          <span>
                            <span>
                              <bdt className="statement-end-if-in-editor">
                                , or any website of ours that links to this
                                privacy notice
                              </bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div>
            <bdt className="block-component">
              <span>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <span data-custom-class="body_text">
                          <bdt className="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </bdt>
          </div>
          <ul>
            <li data-custom-class="body_text">
              <span>
                <span>
                  <span data-custom-class="body_text">
                    Download and use<bdt className="block-component"></bdt> our
                    mobile application<bdt className="block-component"></bdt> (
                    <bdt className="question">
                      andrewscookin)
                      <span>
                        <span>
                          <span data-custom-class="body_text">
                            <span>
                              <span>
                                <span data-custom-class="body_text">
                                  <span>
                                    <span>
                                      <bdt className="statement-end-if-in-editor">
                                        ,
                                      </bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </span>
                  <span data-custom-class="body_text">
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          <span>
                            <span>
                              <bdt className="statement-end-if-in-editor">
                                <bdt className="block-component">
                                  {" "}
                                  or any other application of ours that links to
                                  this privacy notice
                                </bdt>
                              </bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li data-custom-class="body_text">
              <span>
                <span>
                  <span data-custom-class="body_text">
                    Engage with us in other related ways, including any sales,
                    marketing, or events
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          <span>
                            <span>
                              <bdt className="statement-end-if-in-editor"></bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <strong>Questions or concerns?&nbsp;</strong>Reading this
                  privacy notice will help you understand your privacy rights
                  and choices. If you do not agree with our policies and
                  practices, please do not use our Services.
                  <bdt className="block-component"></bdt> If you still have any
                  questions or concerns, please contact us at{" "}
                  <bdt className="question">privacy@andrewscookin.com</bdt>.
                </span>
              </span>
            </span>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <strong>
              <span>
                <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
              </span>
            </strong>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <span data-custom-class="body_text">
                <strong>
                  <em>
                    This summary provides key points from our privacy notice,
                    but you can find out more details about any of these topics
                    by clicking the link following each key point or by using
                    our&nbsp;
                  </em>
                </strong>
              </span>
            </span>
            <a data-custom-class="link" href="#toc">
              <span>
                <span data-custom-class="body_text">
                  <strong>
                    <em>table of contents</em>
                  </strong>
                </span>
              </span>
            </a>
            <span>
              <span data-custom-class="body_text">
                <strong>
                  <em>&nbsp;below to find the section you are looking for.</em>
                </strong>
              </span>
            </span>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <span data-custom-class="body_text">
                <strong>What personal information do we process?</strong> When
                you visit, use, or navigate our Services, we may process
                personal information depending on how you interact with us and
                the Services, the choices you make, and the products and
                features you use. Learn more about&nbsp;
              </span>
            </span>
            <a data-custom-class="link" href="#personalinfo">
              <span>
                <span data-custom-class="body_text">
                  personal information you disclose to us
                </span>
              </span>
            </a>
            <span data-custom-class="body_text">.</span>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <span data-custom-class="body_text">
                <strong>
                  Do we process any sensitive personal information?
                </strong>{" "}
                <bdt className="block-component"></bdt>We do not process
                sensitive personal information.
                <bdt className="else-block"></bdt>
              </span>
            </span>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <span data-custom-class="body_text">
                <strong>
                  Do we collect any information from third parties?
                </strong>{" "}
                <bdt className="block-component"></bdt>We do not collect any
                information from third parties.
                <bdt className="else-block"></bdt>
              </span>
            </span>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <span data-custom-class="body_text">
                <strong>How do we process your information?</strong> We process
                your information to provide, improve, and administer our
                Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent. We process
                your information only when we have a valid legal reason to do
                so. Learn more about&nbsp;
              </span>
            </span>
            <a data-custom-class="link" href="#infouse">
              <span>
                <span data-custom-class="body_text">
                  how we process your information
                </span>
              </span>
            </a>
            <span data-custom-class="body_text">.</span>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <span data-custom-class="body_text">
                <strong>
                  In what situations and with which{" "}
                  <bdt className="block-component"></bdt>parties do we share
                  personal information?
                </strong>{" "}
                We may share information in specific situations and with
                specific <bdt className="block-component"></bdt>third parties.
                Learn more about&nbsp;
              </span>
            </span>
            <a data-custom-class="link" href="#whoshare">
              <span>
                <span data-custom-class="body_text">
                  when and with whom we share your personal information
                </span>
              </span>
            </a>
            <span>
              <span data-custom-class="body_text">
                .<bdt className="block-component"></bdt>
              </span>
            </span>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <span data-custom-class="body_text">
                <strong>How do we keep your information safe?</strong> We have{" "}
                <bdt className="block-component"></bdt>organizational
                <bdt className="statement-end-if-in-editor"></bdt> and technical
                processes and procedures in place to protect your personal
                information. However, no electronic transmission over the
                internet or information storage technology can be guaranteed to
                be 100% secure, so we cannot promise or guarantee that hackers,
                cybercriminals, or other <bdt className="block-component"></bdt>
                unauthorized<bdt className="statement-end-if-in-editor"></bdt>{" "}
                third parties will not be able to defeat our security and
                improperly collect, access, steal, or modify your information.
                Learn more about&nbsp;
              </span>
            </span>
            <a data-custom-class="link" href="#infosafe">
              <span>
                <span data-custom-class="body_text">
                  how we keep your information safe
                </span>
              </span>
            </a>
            <span data-custom-class="body_text">.</span>
            <span>
              <span data-custom-class="body_text">
                <bdt className="statement-end-if-in-editor"></bdt>
              </span>
            </span>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <span data-custom-class="body_text">
                <strong>What are your rights?</strong> Depending on where you
                are located geographically, the applicable privacy law may mean
                you have certain rights regarding your personal information.
                Learn more about&nbsp;
              </span>
            </span>
            <a data-custom-class="link" href="#privacyrights">
              <span>
                <span data-custom-class="body_text">your privacy rights</span>
              </span>
            </a>
            <span data-custom-class="body_text">.</span>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <span data-custom-class="body_text">
                <strong>How do you exercise your rights?</strong> The easiest
                way to exercise your rights is by{" "}
                <bdt className="block-component">submitting a&nbsp;</bdt>
              </span>
            </span>
            <a
              data-custom-class="link"
              href="https://app.termly.io/notify/90f5534d-c7ed-436e-8e7e-ebcb009ec13a"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span>
                <span data-custom-class="body_text">
                  data subject access request
                </span>
              </span>
            </a>
            <span>
              <span data-custom-class="body_text">
                <bdt className="block-component"></bdt>, or by contacting us. We
                will consider and act upon any request in accordance with
                applicable data protection laws.
              </span>
            </span>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <span data-custom-class="body_text">
                Want to learn more about what we do with any information we
                collect?&nbsp;
              </span>
            </span>
            <a data-custom-class="link" href="#toc">
              <span>
                <span data-custom-class="body_text">
                  Review the privacy notice in full
                </span>
              </span>
            </a>
            <span>
              <span data-custom-class="body_text">.</span>
            </span>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <br></br>
          </div>
          <div id="toc">
            <span>
              <span>
                <span>
                  <strong>
                    <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                  </strong>
                </span>
              </span>
            </span>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <a data-custom-class="link" href="#infocollect">
                <span>1. WHAT INFORMATION DO WE COLLECT?</span>
              </a>
            </span>
          </div>
          <div>
            <span>
              <a data-custom-class="link" href="#infouse">
                <span>
                  2. HOW DO WE PROCESS YOUR INFORMATION?
                  <bdt className="block-component"></bdt>
                </span>
              </a>
            </span>
          </div>
          <div>
            <span>
              <a data-custom-class="link" href="#legalbases">
                <span>
                  3.{" "}
                  <span>
                    <span>
                      WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                      INFORMATION?
                    </span>
                  </span>
                  <bdt className="statement-end-if-in-editor"></bdt>
                </span>
              </a>
            </span>
          </div>
          <div>
            <span>
              <span>
                <a data-custom-class="link" href="#whoshare">
                  4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </a>
              </span>
              <span data-custom-class="body_text">
                <bdt className="block-component"></bdt>
              </span>
            </span>
          </div>
          <div>
            <span>
              <a data-custom-class="link" href="#3pwebsites">
                5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
                <bdt className="statement-end-if-in-editor"></bdt>
              </a>
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <span>
                      <bdt className="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div>
            <span>
              <a data-custom-class="link" href="#cookies">
                <span>
                  6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </span>
              </a>
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <span>
                      <bdt className="statement-end-if-in-editor"></bdt>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <span>
                      <span>
                        <span>
                          <bdt className="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div>
            <span>
              <a data-custom-class="link" href="#sociallogins">
                <span>
                  <span>
                    <span>7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>
                  </span>
                  &nbsp;
                </span>
                &nbsp;
              </a>
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <span>
                      <span>
                        <span>
                          <bdt className="statement-end-if-in-editor"></bdt>
                        </span>
                      </span>
                      <bdt className="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div>
            <span>
              <a data-custom-class="link" href="#intltransfers">
                <span>8. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</span>
              </a>
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <span>
                      <bdt className="statement-end-if-in-editor"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div>
            <span>
              <a data-custom-class="link" href="#inforetain">
                <span>9. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
              </a>
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <span>
                      <span>
                        <bdt className="block-component"></bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div>
            <span>
              <a data-custom-class="link" href="#infosafe">
                <span>10. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
              </a>
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <span>
                      <bdt className="statement-end-if-in-editor"></bdt>
                      <bdt className="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div>
            <span>
              <a data-custom-class="link" href="#infominors">
                <span>11. DO WE COLLECT INFORMATION FROM MINORS?</span>
              </a>
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <span>
                      <bdt className="statement-end-if-in-editor"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div>
            <span>
              <span>
                <a data-custom-class="link" href="#privacyrights">
                  12. WHAT ARE YOUR PRIVACY RIGHTS?
                </a>
              </span>
            </span>
          </div>
          <div>
            <span>
              <a data-custom-class="link" href="#DNT">
                <span>
                  13. CONTROLS FOR DO-NOT-TRACK FEATURES
                  <bdt className="block-component"></bdt>
                </span>
              </a>
            </span>
          </div>
          <div>
            <span>
              <a data-custom-class="link" href="#uslaws">
                <span>
                  14. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </span>
              </a>
            </span>
            <bdt className="block-component">
              <span>
                <span data-custom-class="body_text"></span>
              </span>
            </bdt>
          </div>
          <div>
            <bdt className="block-component">
              <span></span>
            </bdt>
            <bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
          </div>
          <div>
            <span>
              <a data-custom-class="link" href="#policyupdates">
                <span>15. DO WE MAKE UPDATES TO THIS NOTICE?</span>
              </a>
            </span>
          </div>
          <div>
            <a data-custom-class="link" href="#contact">
              <span>16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
            </a>
          </div>
          <div>
            <a data-custom-class="link" href="#request">
              <span>
                17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </span>
            </a>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <br></br>
          </div>
          <div id="infocollect">
            <span>
              <span>
                <span>
                  <span>
                    <span id="control">
                      <strong>
                        <span data-custom-class="heading_1">
                          1. WHAT INFORMATION DO WE COLLECT?
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div>
            <br></br>
          </div>
          <div id="personalinfo">
            <span data-custom-class="heading_2">
              <span>
                <strong>Personal information you disclose to us</strong>
              </span>
            </span>
          </div>
          <div>
            <div>
              <br></br>
            </div>
            <div>
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          <strong>
                            <em>In Short:</em>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          <strong>
                            <em>&nbsp;</em>
                          </strong>
                          <em>
                            We collect personal information that you provide to
                            us.
                          </em>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  We collect personal information that you voluntarily provide
                  to us when you{" "}
                  <span>
                    <bdt className="block-component"></bdt>
                  </span>
                  register on the Services,&nbsp;
                </span>
                <span>
                  <span data-custom-class="body_text">
                    <span>
                      <bdt className="statement-end-if-in-editor"></bdt>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    express an interest in obtaining information about us or our
                    products and Services, when you participate in activities on
                    the Services, or otherwise when you contact us.
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <span>
                    <bdt className="block-component"></bdt>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <strong>Personal Information Provided by You.</strong> The
                  personal information that we collect depends on the context of
                  your interactions with us and the Services, the choices you
                  make, and the products and features you use. The personal
                  information we collect may include the following:
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="forloop-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li data-custom-class="body_text">
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <span>
                      <span data-custom-class="body_text">
                        <bdt className="question">email addresses</bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="forloop-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li data-custom-class="body_text">
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <span>
                      <span data-custom-class="body_text">
                        <bdt className="question">usernames</bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="forloop-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li data-custom-class="body_text">
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <span>
                      <span data-custom-class="body_text">
                        <bdt className="question">passwords</bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="forloop-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li data-custom-class="body_text">
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <span>
                      <span data-custom-class="body_text">
                        <bdt className="question">
                          contact or authentication data
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="forloop-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li data-custom-class="body_text">
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <span>
                      <span data-custom-class="body_text">
                        <bdt className="question">billing addresses</bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="forloop-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li data-custom-class="body_text">
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <span>
                      <span data-custom-class="body_text">
                        <bdt className="question">
                          debit/credit card numbers
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="forloop-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li data-custom-class="body_text">
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <span>
                      <span data-custom-class="body_text">
                        <bdt className="question">names</bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="forloop-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li data-custom-class="body_text">
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <span>
                      <span data-custom-class="body_text">
                        <bdt className="question">phone numbers</bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="forloop-component"></bdt>
                    </span>
                    <span data-custom-class="body_text">
                      <bdt className="statement-end-if-in-editor"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div id="sensitiveinfo">
            <span>
              <span data-custom-class="body_text">
                <strong>Sensitive Information.</strong>{" "}
                <bdt className="block-component"></bdt>We do not process
                sensitive information.
              </span>
            </span>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <span data-custom-class="body_text">
                <bdt className="else-block"></bdt>
              </span>
            </span>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <strong>Payment Data.</strong> We may collect data necessary
                  to process your payment if you choose to make purchases, such
                  as your payment instrument number, and the security code
                  associated with your payment instrument. All payment data is
                  handled and stored by<bdt className="forloop-component"></bdt>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <span>
                          <span data-custom-class="body_text">
                            <bdt className="block-component"></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>{" "}
                  <bdt className="question">PayPal</bdt>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <span>
                          <span>
                            <span data-custom-class="body_text">
                              <span>
                                <span data-custom-class="body_text">
                                  <bdt className="block-component"></bdt>
                                </span>
                              </span>
                            </span>
                            <span data-custom-class="body_text">
                              <span>
                                <span>
                                  <span data-custom-class="body_text">
                                    <span>
                                      <span>
                                        <span data-custom-class="body_text">
                                          <span>
                                            <span>
                                              <span data-custom-class="body_text">
                                                <bdt className="forloop-component"></bdt>
                                                <span>
                                                  <span>
                                                    <span data-custom-class="body_text">
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component"></bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                          ,{" "}
                                          <span>
                                            <span>
                                              <span data-custom-class="body_text">
                                                <bdt className="question">
                                                  Stripe
                                                </bdt>
                                              </span>
                                            </span>
                                          </span>
                                          <bdt className="else-block"></bdt>
                                        </span>
                                      </span>
                                    </span>
                                    <span data-custom-class="body_text">
                                      <span>
                                        <span>
                                          <span data-custom-class="body_text">
                                            <span>
                                              <span>
                                                <span data-custom-class="body_text">
                                                  <span>
                                                    <span>
                                                      <span data-custom-class="body_text">
                                                        <bdt className="forloop-component"></bdt>
                                                        <span>
                                                          <span>
                                                            <span data-custom-class="body_text">
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component"></bdt>{" "}
                                                                  and{" "}
                                                                  <span>
                                                                    <span>
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="question">
                                                                          Paysera
                                                                        </bdt>
                                                                      </span>
                                                                      <span data-custom-class="body_text">
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="statement-end-if-in-editor"></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <span data-custom-class="body_text">
                                                                        <span>
                                                                          <span>
                                                                            <span data-custom-class="body_text">
                                                                              <span>
                                                                                <span>
                                                                                  <span data-custom-class="body_text">
                                                                                    <span>
                                                                                      <span>
                                                                                        <span data-custom-class="body_text">
                                                                                          <bdt className="forloop-component"></bdt>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  . You may find
                                                                  their privacy
                                                                  notice link(s)
                                                                  here:
                                                                  <span>
                                                                    <span>
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="forloop-component"></bdt>
                                                                        <span>
                                                                          <span>
                                                                            <span data-custom-class="body_text">
                                                                              <span>
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="block-component"></bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>{" "}
                                                                  <span>
                                                                    <bdt className="question">
                                                                      <a
                                                                        href="https://www.paypal.com/us/webapps/mpp/ua/privacy-full#personalData"
                                                                        target="_blank"
                                                                        data-custom-class="link"
                                                                      >
                                                                        https://www.paypal.com/us/webapps/mpp/ua/privacy-full#personalData
                                                                      </a>
                                                                    </bdt>
                                                                  </span>
                                                                  <span>
                                                                    <span>
                                                                      <span data-custom-class="body_text">
                                                                        <span>
                                                                          <span>
                                                                            <span data-custom-class="body_text">
                                                                              <span>
                                                                                <span>
                                                                                  <span data-custom-class="body_text">
                                                                                    <span>
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="block-component"></bdt>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <bdt className="forloop-component"></bdt>
                                                                        <span>
                                                                          <span>
                                                                            <span data-custom-class="body_text">
                                                                              <span>
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="block-component"></bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                            ,{" "}
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="question">
                                                                    <a
                                                                      href="http://www.stripe.com/privacy"
                                                                      target="_blank"
                                                                      data-custom-class="link"
                                                                    >
                                                                      http://www.stripe.com/privacy
                                                                    </a>
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <bdt className="else-block"></bdt>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                            <bdt className="forloop-component"></bdt>
                                            <span>
                                              <span>
                                                <span data-custom-class="body_text">
                                                  <span>
                                                    <span data-custom-class="body_text">
                                                      <bdt className="block-component"></bdt>{" "}
                                                      and{" "}
                                                      <span>
                                                        <span>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="question">
                                                              <a
                                                                href="https://www.paysera.com/v2/en/legal"
                                                                target="_blank"
                                                                data-custom-class="link"
                                                              >
                                                                https://www.paysera.com/v2/en/legal
                                                              </a>
                                                            </bdt>
                                                          </span>
                                                          <span data-custom-class="body_text">
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  <span>
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="statement-end-if-in-editor"></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <bdt className="forloop-component"></bdt>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          .
                                                          <bdt className="block-component"></bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="statement-end-if-in-editor">
                        <bdt className="block-component"></bdt>
                      </bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <strong>Social Media Login Data.&nbsp;</strong>We may provide
                  you with the option to register with us using your existing
                  social media account details, like your Facebook, X, or other
                  social media account. If you choose to register in this way,
                  we will collect certain profile information about you from the
                  social media provider, as described in the section called{" "}
                  <bdt className="block-component"></bdt>"
                  <bdt className="statement-end-if-in-editor"></bdt>
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <span>
                          <a data-custom-class="link" href="#sociallogins">
                            HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                          </a>
                        </span>
                      </span>
                    </span>
                  </span>
                  <bdt className="block-component"></bdt>"
                  <bdt className="statement-end-if-in-editor"></bdt> below.
                </span>
              </span>
            </span>
          </div>
          <div>
            <br></br>
          </div>
          <div>
            <span>
              <span>
                <span data-custom-class="body_text">
                  <span>
                    <bdt className="statement-end-if-in-editor">
                      <bdt className="statement-end-if-in-editor"></bdt>
                    </bdt>
                  </span>
                </span>
              </span>
            </span>
            <bdt className="block-component">
              <bdt className="block-component"></bdt>
            </bdt>
          </div>
          <div>
            <span data-custom-class="body_text">
              <span>
                <strong>Application Data.</strong> If you use our
                application(s), we also may collect the following information if
                you choose to provide us with access or permission:
                <bdt className="block-component"></bdt>
              </span>
            </span>
            <div>
              <bdt className="block-component">
                <span>
                  <span data-custom-class="body_text"></span>
                </span>
              </bdt>
              <div>
                <bdt className="block-component">
                  <span>
                    <span data-custom-class="body_text"></span>
                  </span>
                </bdt>
              </div>
              <ul>
                <li data-custom-class="body_text">
                  <span>
                    <span data-custom-class="body_text">
                      <em>Mobile Device Data.</em> We automatically collect
                      device information (such as your mobile device ID, model,
                      and manufacturer), operating system, version information
                      and system configuration information, device and
                      application identification numbers, browser type and
                      version, hardware model Internet service provider and/or
                      mobile carrier, and Internet Protocol (IP) address (or
                      proxy server). If you are using our application(s), we may
                      also collect information about the phone network
                      associated with your mobile device, your mobile device’s
                      operating system or platform, the type of mobile device
                      you use, your mobile device’s unique device ID, and
                      information about the features of our application(s) you
                      accessed.
                      <bdt className="statement-end-if-in-editor"></bdt>
                    </span>
                  </span>
                </li>
              </ul>
              <div>
                <bdt className="block-component">
                  <span>
                    <span data-custom-class="body_text"></span>
                  </span>
                </bdt>
              </div>
              <ul>
                <li data-custom-class="body_text">
                  <span>
                    <span data-custom-class="body_text">
                      <em>Push Notifications.</em> We may request to send you
                      push notifications regarding your account or certain
                      features of the application(s). If you wish to opt out
                      from receiving these types of communications, you may turn
                      them off in your device's settings.
                      <bdt className="statement-end-if-in-editor"></bdt>
                    </span>
                  </span>
                </li>
              </ul>
              <div>
                <span>
                  <span data-custom-class="body_text">
                    This information is primarily needed to maintain the
                    security and operation of our application(s), for
                    troubleshooting, and for our internal analytics and
                    reporting purposes.
                  </span>
                </span>
              </div>
              <div>
                <br></br>
              </div>
              <div>
                <bdt className="statement-end-if-in-editor">
                  <span>
                    <span data-custom-class="body_text"></span>
                  </span>
                </bdt>
              </div>
              <div>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      All personal information that you provide to us must be
                      true, complete, and accurate, and you must notify us of
                      any changes to such personal information.
                    </span>
                  </span>
                </span>
              </div>
              <div>
                <br></br>
              </div>
              <div>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </div>
              <div>
                <span data-custom-class="heading_2">
                  <span>
                    <strong>Information automatically collected</strong>
                  </span>
                </span>
              </div>
              <div>
                <div>
                  <br></br>
                </div>
                <div>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <span>
                          <span>
                            <span data-custom-class="body_text">
                              <strong>
                                <em>In Short:</em>
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span data-custom-class="body_text">
                        <span>
                          <span>
                            <span data-custom-class="body_text">
                              <strong>
                                <em>&nbsp;</em>
                              </strong>
                              <em>
                                Some information — such as your Internet
                                Protocol (IP) address and/or browser and device
                                characteristics — is collected automatically
                                when you visit our Services.
                              </em>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div>
                <br></br>
              </div>
              <div>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      We automatically collect certain information when you
                      visit, use, or navigate the Services. This information
                      does not reveal your specific identity (like your name or
                      contact information) but may include device and usage
                      information, such as your IP address, browser and device
                      characteristics, operating system, language preferences,
                      referring URLs, device name, country, location,
                      information about how and when you use our Services, and
                      other technical information. This information is primarily
                      needed to maintain the security and operation of our
                      Services, and for our internal analytics and reporting
                      purposes.
                    </span>
                  </span>
                </span>
              </div>
              <div>
                <br></br>
              </div>
              <div>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </div>
              <div>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      Like many businesses, we also collect information through
                      cookies and similar technologies.{" "}
                      <bdt className="block-component"></bdt>
                      <bdt className="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </div>
              <div>
                <br></br>
              </div>
              <div>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="statement-end-if-in-editor">
                        <span data-custom-class="body_text"></span>
                      </bdt>
                    </span>
                    <span data-custom-class="body_text">
                      <bdt className="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </div>
              <div>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      The information we collect includes:
                      <bdt className="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li data-custom-class="body_text">
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <em>Log and Usage Data.</em> Log and usage data is
                        service-related, diagnostic, usage, and performance
                        information our servers automatically collect when you
                        access or use our Services and which we record in log
                        files. Depending on how you interact with us, this log
                        data may include your IP address, device information,
                        browser type, and settings and information about your
                        activity in the Services
                        <span>&nbsp;</span>(such as the date/time stamps
                        associated with your usage, pages and files viewed,
                        searches, and other actions you take such as which
                        features you use), device event information (such as
                        system activity, error reports (sometimes called{" "}
                        <bdt className="block-component"></bdt>"crash dumps"
                        <bdt className="statement-end-if-in-editor"></bdt>), and
                        hardware settings).
                        <span>
                          <span>
                            <span data-custom-class="body_text">
                              <span>
                                <span>
                                  <bdt className="statement-end-if-in-editor"></bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div>
                <bdt className="block-component">
                  <span>
                    <span data-custom-class="body_text"></span>
                  </span>
                </bdt>
              </div>
              <ul>
                <li data-custom-class="body_text">
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <em>Device Data.</em> We collect device data such as
                        information about your computer, phone, tablet, or other
                        device you use to access the Services. Depending on the
                        device used, this device data may include information
                        such as your IP address (or proxy server), device and
                        application identification numbers, location, browser
                        type, hardware model, Internet service provider and/or
                        mobile carrier, operating system, and system
                        configuration information.
                        <span>
                          <span>
                            <span data-custom-class="body_text">
                              <span>
                                <span>
                                  <bdt className="statement-end-if-in-editor"></bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div>
                <bdt className="block-component">
                  <span>
                    <span data-custom-class="body_text"></span>
                  </span>
                </bdt>
              </div>
              <ul>
                <li data-custom-class="body_text">
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <em>Location Data.</em> We collect location data such as
                        information about your device's location, which can be
                        either precise or imprecise. How much information we
                        collect depends on the type and settings of the device
                        you use to access the Services. For example, we may use
                        GPS and other technologies to collect geolocation data
                        that tells us your current location (based on your IP
                        address). You can opt out of allowing us to collect this
                        information either by refusing access to the information
                        or by disabling your Location setting on your device.
                        However, if you choose to opt out, you may not be able
                        to use certain aspects of the Services.
                        <span>
                          <span>
                            <span data-custom-class="body_text">
                              <span>
                                <span>
                                  <bdt className="statement-end-if-in-editor"></bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div>
                <bdt className="block-component">
                  <span></span>
                </bdt>
                <bdt className="statement-end-if-in-editor"></bdt>
                <span data-custom-class="body_text">
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <span data-custom-class="body_text">
                          <bdt className="statement-end-if-in-editor">
                            <bdt className="block-component"></bdt>
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component"></bdt>
                  </span>
                </span>
              </div>
              <div>
                <br></br>
              </div>
              <div id="infouse">
                <span>
                  <span>
                    <span>
                      <span>
                        <span id="control">
                          <strong>
                            <span data-custom-class="heading_1">
                              2. HOW DO WE PROCESS YOUR INFORMATION?
                            </span>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div>
                <div>
                  <br></br>
                </div>
                <div>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <span>
                          <span>
                            <span data-custom-class="body_text">
                              <strong>
                                <em>In Short:&nbsp;</em>
                              </strong>
                              <em>
                                We process your information to provide, improve,
                                and administer our Services, communicate with
                                you, for security and fraud prevention, and to
                                comply with law. We may also process your
                                information for other purposes with your
                                consent.
                              </em>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div>
                <br></br>
              </div>
              <div>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <strong>
                        We process your personal information for a variety of
                        reasons, depending on how you interact with our
                        Services, including:
                      </strong>
                      <bdt className="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li data-custom-class="body_text">
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <strong>
                          To facilitate account creation and authentication and
                          otherwise manage user accounts.&nbsp;
                        </strong>
                        We may process your information so you can create and
                        log in to your account, as well as keep your account in
                        working order.
                        <span>
                          <span>
                            <span data-custom-class="body_text">
                              <span>
                                <span>
                                  <span data-custom-class="body_text">
                                    <span>
                                      <span>
                                        <span data-custom-class="body_text">
                                          <bdt className="statement-end-if-in-editor"></bdt>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div>
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <bdt className="block-component"></bdt>
                    </span>
                  </span>
                </span>
                <div>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <bdt className="block-component"></bdt>
                      </span>
                    </span>
                  </span>
                </div>
                <ul>
                  <li data-custom-class="body_text">
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          <strong>
                            To deliver and facilitate delivery of services to
                            the user.&nbsp;
                          </strong>
                          We may process your information to provide you with
                          the requested service.
                          <span>
                            <span>
                              <span data-custom-class="body_text">
                                <span>
                                  <span>
                                    <span data-custom-class="body_text">
                                      <span>
                                        <span>
                                          <span data-custom-class="body_text">
                                            <span>
                                              <span>
                                                <span data-custom-class="body_text">
                                                  <span>
                                                    <span>
                                                      <span data-custom-class="body_text">
                                                        <span>
                                                          <span>
                                                            <span data-custom-class="body_text">
                                                              <bdt className="statement-end-if-in-editor"></bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
                <div>
                  <span>
                    <span>
                      <span data-custom-class="body_text">
                        <bdt className="block-component"></bdt>
                      </span>
                    </span>
                  </span>
                  <div>
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          <bdt className="block-component"></bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li>
                      <span>
                        <span>
                          <span data-custom-class="body_text">
                            <strong>
                              To respond to user inquiries/offer support to
                              users.&nbsp;
                            </strong>
                            We may process your information to respond to your
                            inquiries and solve any potential issues you might
                            have with the requested service.
                            <bdt className="statement-end-if-in-editor"></bdt>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <bdt className="block-component">
                      <span></span>
                    </bdt>
                    <div>
                      <bdt className="block-component">
                        <span></span>
                      </bdt>
                    </div>
                    <ul>
                      <li data-custom-class="body_text">
                        <span>
                          <span>
                            <span data-custom-class="body_text">
                              <strong>
                                To send administrative information to you.&nbsp;
                              </strong>
                              We may process your information to send you
                              details about our products and services, changes
                              to our terms and policies, and other similar
                              information.
                              <span>
                                <span>
                                  <span data-custom-class="body_text">
                                    <span>
                                      <span>
                                        <span data-custom-class="body_text">
                                          <bdt className="statement-end-if-in-editor"></bdt>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </li>
                    </ul>
                    <div>
                      <bdt className="block-component">
                        <span></span>
                      </bdt>
                      <div>
                        <bdt className="block-component">
                          <span>
                            <span data-custom-class="body_text"></span>
                          </span>
                        </bdt>
                        <p>
                          <bdt className="block-component">
                            <span></span>
                          </bdt>
                        </p>
                        <p>
                          <bdt className="block-component">
                            <span></span>
                          </bdt>
                        </p>
                        <p>
                          <bdt className="block-component"></bdt>
                        </p>
                        <p>
                          <bdt className="block-component"></bdt>
                        </p>
                        <ul>
                          <li data-custom-class="body_text">
                            <span>
                              <span>
                                <span>
                                  <span data-custom-class="body_text">
                                    <strong>To request feedback.&nbsp;</strong>
                                    We may process your information when
                                    necessary to request feedback and to contact
                                    you about your use of our Services.
                                    <span>
                                      <span>
                                        <span data-custom-class="body_text">
                                          <span>
                                            <span data-custom-class="body_text">
                                              <span>
                                                <span data-custom-class="body_text">
                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </li>
                        </ul>
                        <div>
                          <bdt className="block-component">
                            <span>
                              <span data-custom-class="body_text"></span>
                            </span>
                          </bdt>
                          <div>
                            <bdt className="block-component">
                              <span></span>
                            </bdt>
                          </div>
                          <ul>
                            <li data-custom-class="body_text">
                              <span>
                                <span>
                                  <span data-custom-class="body_text">
                                    <strong>
                                      To send you marketing and promotional
                                      communications.&nbsp;
                                    </strong>
                                    We may process the personal information you
                                    send to us for our marketing purposes, if
                                    this is in accordance with your marketing
                                    preferences. You can opt out of our
                                    marketing emails at any time. For more
                                    information, see{" "}
                                    <bdt className="block-component"></bdt>"
                                    <bdt className="statement-end-if-in-editor"></bdt>
                                  </span>
                                </span>
                              </span>
                              <a data-custom-class="link" href="#privacyrights">
                                <span>
                                  <span>
                                    <span data-custom-class="body_text">
                                      WHAT ARE YOUR PRIVACY RIGHTS?
                                    </span>
                                  </span>
                                </span>
                              </a>
                              <span>
                                <span>
                                  <span data-custom-class="body_text">
                                    <bdt className="block-component"></bdt>"
                                    <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                    below.
                                  </span>
                                  <span data-custom-class="body_text">
                                    <bdt className="statement-end-if-in-editor"></bdt>
                                  </span>
                                </span>
                              </span>
                            </li>
                          </ul>
                          <div>
                            <bdt className="block-component">
                              <span></span>
                            </bdt>
                            <div>
                              <span>
                                <bdt className="block-component">
                                  <span data-custom-class="body_text"></span>
                                </bdt>
                              </span>
                            </div>
                            <ul>
                              <li data-custom-class="body_text">
                                <span>
                                  <span data-custom-class="body_text">
                                    <strong>
                                      To deliver targeted advertising to you.
                                    </strong>{" "}
                                    We may process your information to develop
                                    and display{" "}
                                    <bdt className="block-component"></bdt>
                                    personalized
                                    <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                    content and advertising tailored to your
                                    interests, location, and more.
                                    <bdt className="block-component"></bdt>
                                  </span>
                                </span>
                              </li>
                            </ul>
                            <div>
                              <bdt className="block-component">
                                <span>
                                  <span data-custom-class="body_text"></span>
                                </span>
                              </bdt>
                              <div>
                                <bdt className="block-component">
                                  <span>
                                    <span data-custom-class="body_text"></span>
                                  </span>
                                </bdt>
                                <div>
                                  <bdt className="block-component">
                                    <span>
                                      <span data-custom-class="body_text"></span>
                                    </span>
                                  </bdt>
                                </div>
                                <ul>
                                  <li data-custom-class="body_text">
                                    <span data-custom-class="body_text">
                                      <span>
                                        <strong>
                                          To protect our Services.
                                        </strong>{" "}
                                        We may process your information as part
                                        of our efforts to keep our Services safe
                                        and secure, including fraud monitoring
                                        and prevention.
                                      </span>
                                    </span>
                                    <bdt className="statement-end-if-in-editor">
                                      <span>
                                        <span data-custom-class="body_text"></span>
                                      </span>
                                    </bdt>
                                  </li>
                                </ul>
                                <div>
                                  <bdt className="block-component">
                                    <span>
                                      <span data-custom-class="body_text"></span>
                                    </span>
                                  </bdt>
                                  <div>
                                    <bdt className="block-component">
                                      <span>
                                        <span data-custom-class="body_text"></span>
                                      </span>
                                    </bdt>
                                    <div>
                                      <bdt className="block-component">
                                        <span>
                                          <span data-custom-class="body_text"></span>
                                        </span>
                                      </bdt>
                                      <div>
                                        <bdt className="block-component">
                                          <span>
                                            <span data-custom-class="body_text"></span>
                                          </span>
                                        </bdt>
                                      </div>
                                      <ul>
                                        <li data-custom-class="body_text">
                                          <span data-custom-class="body_text">
                                            <span>
                                              <strong>
                                                To identify usage trends.
                                              </strong>{" "}
                                              We may process information about
                                              how you use our Services to better
                                              understand how they are being used
                                              so we can improve them.
                                            </span>
                                          </span>
                                          <bdt className="statement-end-if-in-editor">
                                            <span>
                                              <span data-custom-class="body_text"></span>
                                            </span>
                                          </bdt>
                                        </li>
                                      </ul>
                                      <div>
                                        <bdt className="block-component">
                                          <span>
                                            <span data-custom-class="body_text"></span>
                                          </span>
                                        </bdt>
                                        <div>
                                          <bdt className="block-component">
                                            <span>
                                              <span data-custom-class="body_text"></span>
                                            </span>
                                          </bdt>
                                        </div>
                                        <ul>
                                          <li data-custom-class="body_text">
                                            <span>
                                              <span data-custom-class="body_text">
                                                <strong>
                                                  To determine the effectiveness
                                                  of our marketing and
                                                  promotional campaigns.
                                                </strong>{" "}
                                                We may process your information
                                                to better understand how to
                                                provide marketing and
                                                promotional campaigns that are
                                                most relevant to you.
                                                <bdt className="statement-end-if-in-editor"></bdt>
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div>
                                          <bdt className="block-component">
                                            <span>
                                              <span data-custom-class="body_text"></span>
                                            </span>
                                          </bdt>
                                          <div>
                                            <bdt className="block-component">
                                              <span>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  <strong>
                                                    To save or protect an
                                                    individual's vital interest.
                                                  </strong>{" "}
                                                  We may process your
                                                  information when necessary to
                                                  save or protect an
                                                  individual’s vital interest,
                                                  such as to prevent harm.
                                                </span>
                                              </span>
                                              <bdt className="statement-end-if-in-editor">
                                                <span>
                                                  <span data-custom-class="body_text"></span>
                                                </span>
                                              </bdt>
                                            </li>
                                          </ul>
                                          <div>
                                            <bdt className="block-component">
                                              <span>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                            <bdt className="block-component">
                                              <span>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                            <bdt className="block-component">
                                              <span>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                            <bdt className="block-component">
                                              <span>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                          </div>
                                          <div>
                                            <br></br>
                                          </div>
                                          <div id="legalbases">
                                            <strong>
                                              <span>
                                                <span data-custom-class="heading_1">
                                                  3. WHAT LEGAL BASES DO WE RELY
                                                  ON TO PROCESS YOUR
                                                  INFORMATION?
                                                </span>
                                              </span>
                                            </strong>
                                          </div>
                                          <div>
                                            <br></br>
                                          </div>
                                          <div>
                                            <em>
                                              <span>
                                                <span data-custom-class="body_text">
                                                  <strong>
                                                    In Short:&nbsp;
                                                  </strong>
                                                  We only process your personal
                                                  information when we believe it
                                                  is necessary and we have a
                                                  valid legal reason (i.e.
                                                  <bdt className="block-component"></bdt>
                                                  ,
                                                  <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                  legal basis) to do so under
                                                  applicable law, like with your
                                                  consent, to comply with laws,
                                                  to provide you with services
                                                  to enter into or{" "}
                                                  <bdt className="block-component"></bdt>
                                                  fulfill
                                                  <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                  our contractual obligations,
                                                  to protect your rights, or to{" "}
                                                  <bdt className="block-component"></bdt>
                                                  fulfill
                                                  <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                  our legitimate business
                                                  interests.
                                                </span>
                                              </span>
                                            </em>
                                          </div>
                                          <div>
                                            <br></br>
                                          </div>
                                          <div>
                                            <span>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component"></bdt>
                                              </span>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component"></bdt>
                                              </span>
                                            </span>
                                          </div>
                                          <div>
                                            <em>
                                              <span>
                                                <span data-custom-class="body_text">
                                                  <strong>
                                                    <u>
                                                      If you are located in the
                                                      EU or UK, this section
                                                      applies to you.
                                                    </u>
                                                  </strong>
                                                </span>
                                              </span>
                                            </em>
                                          </div>
                                          <div>
                                            <br></br>
                                          </div>
                                          <div>
                                            <span>
                                              <span data-custom-class="body_text">
                                                <bdt className="statement-end-if-in-editor"></bdt>
                                              </span>
                                            </span>
                                          </div>
                                          <div>
                                            <span>
                                              <span data-custom-class="body_text">
                                                The General Data Protection
                                                Regulation (GDPR) and UK GDPR
                                                require us to explain the valid
                                                legal bases we rely on in order
                                                to process your personal
                                                information. As such, we may
                                                rely on the following legal
                                                bases to process your personal
                                                information:
                                              </span>
                                            </span>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span>
                                                <span data-custom-class="body_text">
                                                  <strong>
                                                    Consent.&nbsp;
                                                  </strong>
                                                  We may process your
                                                  information if you have given
                                                  us permission (i.e.
                                                  <bdt className="block-component"></bdt>
                                                  ,
                                                  <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                  consent) to use your personal
                                                  information for a specific
                                                  purpose. You can withdraw your
                                                  consent at any time. Learn
                                                  more about&nbsp;
                                                </span>
                                              </span>
                                              <a
                                                data-custom-class="link"
                                                href="#withdrawconsent"
                                              >
                                                <span>
                                                  <span data-custom-class="body_text">
                                                    withdrawing your consent
                                                  </span>
                                                </span>
                                              </a>
                                              <span data-custom-class="body_text">
                                                .
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <bdt className="block-component">
                                              <span>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  <strong>
                                                    Performance of a Contract.
                                                  </strong>{" "}
                                                  We may process your personal
                                                  information when we believe it
                                                  is necessary to{" "}
                                                  <bdt className="block-component"></bdt>
                                                  fulfill
                                                  <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                  our contractual obligations to
                                                  you, including providing our
                                                  Services or at your request
                                                  prior to entering into a
                                                  contract with you.
                                                </span>
                                              </span>
                                              <bdt className="statement-end-if-in-editor">
                                                <span>
                                                  <span data-custom-class="body_text"></span>
                                                </span>
                                              </bdt>
                                            </li>
                                          </ul>
                                          <div>
                                            <bdt className="block-component">
                                              <span>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  <strong>
                                                    Legitimate Interests.
                                                  </strong>{" "}
                                                  We may process your
                                                  information when we believe it
                                                  is reasonably necessary to
                                                  achieve our legitimate
                                                  business interests and those
                                                  interests do not outweigh your
                                                  interests and fundamental
                                                  rights and freedoms. For
                                                  example, we may process your
                                                  personal information for some
                                                  of the purposes described in
                                                  order to:
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <span data-custom-class="body_text">
                                              <span>
                                                <bdt className="block-component"></bdt>
                                              </span>
                                            </span>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  Send users information about
                                                  special offers and discounts
                                                  on our products and services
                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <span data-custom-class="body_text">
                                              <span>
                                                <bdt className="block-component"></bdt>
                                              </span>
                                            </span>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  Develop and display{" "}
                                                  <bdt className="block-component"></bdt>
                                                  personalized
                                                  <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                  and relevant advertising
                                                  content for our users
                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <span data-custom-class="body_text">
                                              <span>
                                                <bdt className="block-component"></bdt>
                                              </span>
                                            </span>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  <bdt className="block-component"></bdt>
                                                  Analyze
                                                  <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                  how our Services are used so
                                                  we can improve them to engage
                                                  and retain users
                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <span data-custom-class="body_text">
                                              <span>
                                                <bdt className="block-component"></bdt>
                                              </span>
                                            </span>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  Support our marketing
                                                  activities
                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <span data-custom-class="body_text">
                                              <span>
                                                <bdt className="block-component"></bdt>
                                              </span>
                                            </span>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  Diagnose problems and/or
                                                  prevent fraudulent activities
                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <span data-custom-class="body_text">
                                              <span>
                                                <bdt className="block-component"></bdt>
                                              </span>
                                            </span>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  Understand how our users use
                                                  our products and services so
                                                  we can improve user experience
                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <span data-custom-class="body_text">
                                              <span>
                                                <bdt className="block-component"></bdt>
                                              </span>
                                            </span>
                                            <bdt className="statement-end-if-in-editor">
                                              <span>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                            <bdt className="block-component">
                                              <span>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  <strong>
                                                    Legal Obligations.
                                                  </strong>{" "}
                                                  We may process your
                                                  information where we believe
                                                  it is necessary for compliance
                                                  with our legal obligations,
                                                  such as to cooperate with a
                                                  law enforcement body or
                                                  regulatory agency, exercise or
                                                  defend our legal rights, or
                                                  disclose your information as
                                                  evidence in litigation in
                                                  which we are involved.
                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                  <br></br>
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <bdt className="block-component">
                                              <span>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  <strong>
                                                    Vital Interests.
                                                  </strong>{" "}
                                                  We may process your
                                                  information where we believe
                                                  it is necessary to protect
                                                  your vital interests or the
                                                  vital interests of a third
                                                  party, such as situations
                                                  involving potential threats to
                                                  the safety of any person.
                                                </span>
                                              </span>
                                              <bdt className="statement-end-if-in-editor">
                                                <span>
                                                  <span data-custom-class="body_text"></span>
                                                </span>
                                              </bdt>
                                            </li>
                                          </ul>
                                          <div>
                                            <bdt className="block-component">
                                              <span>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                            <span data-custom-class="body_text">
                                              <span>
                                                <bdt className="block-component">
                                                  <bdt className="block-component"></bdt>
                                                </bdt>
                                              </span>
                                            </span>
                                          </div>
                                          <div>
                                            <span data-custom-class="body_text">
                                              <span>
                                                <strong>
                                                  <u>
                                                    <em>
                                                      If you are located in
                                                      Canada, this section
                                                      applies to you.
                                                    </em>
                                                  </u>
                                                </strong>
                                              </span>
                                            </span>
                                          </div>
                                          <div>
                                            <br></br>
                                          </div>
                                          <div>
                                            <span data-custom-class="body_text">
                                              <span>
                                                <bdt className="statement-end-if-in-editor"></bdt>
                                              </span>
                                            </span>
                                          </div>
                                          <div>
                                            <span data-custom-class="body_text">
                                              <span>
                                                We may process your information
                                                if you have given us specific
                                                permission (i.e.
                                                <bdt className="block-component"></bdt>
                                                ,
                                                <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                express consent) to use your
                                                personal information for a
                                                specific purpose, or in
                                                situations where your permission
                                                can be inferred (i.e.
                                                <bdt className="block-component"></bdt>
                                                ,
                                                <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                implied consent). You can&nbsp;
                                              </span>
                                            </span>
                                            <a
                                              data-custom-class="link"
                                              href="#withdrawconsent"
                                            >
                                              <span data-custom-class="body_text">
                                                <span>
                                                  withdraw your consent
                                                </span>
                                              </span>
                                            </a>
                                            <span data-custom-class="body_text">
                                              <span>&nbsp;at any time.</span>
                                            </span>
                                          </div>
                                          <div>
                                            <br></br>
                                          </div>
                                          <div>
                                            <span data-custom-class="body_text">
                                              <span>
                                                In some exceptional cases, we
                                                may be legally permitted under
                                                applicable law to process your
                                                information without your
                                                consent, including, for example:
                                              </span>
                                            </span>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  If collection is clearly in
                                                  the interests of an individual
                                                  and consent cannot be obtained
                                                  in a timely way
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <span data-custom-class="body_text">
                                              <span>
                                                <bdt className="block-component"></bdt>
                                              </span>
                                            </span>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  For investigations and fraud
                                                  detection and prevention
                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <bdt className="block-component">
                                              <span>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  For business transactions
                                                  provided certain conditions
                                                  are met
                                                </span>
                                              </span>
                                              <bdt className="statement-end-if-in-editor">
                                                <span>
                                                  <span data-custom-class="body_text"></span>
                                                </span>
                                              </bdt>
                                            </li>
                                          </ul>
                                          <div>
                                            <bdt className="block-component">
                                              <span>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  If it is contained in a
                                                  witness statement and the
                                                  collection is necessary to
                                                  assess, process, or settle an
                                                  insurance claim
                                                </span>
                                              </span>
                                              <bdt className="statement-end-if-in-editor">
                                                <span>
                                                  <span data-custom-class="body_text"></span>
                                                </span>
                                              </bdt>
                                            </li>
                                          </ul>
                                          <div>
                                            <bdt className="block-component">
                                              <span>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  For identifying injured, ill,
                                                  or deceased persons and
                                                  communicating with next of kin
                                                </span>
                                              </span>
                                              <bdt className="statement-end-if-in-editor">
                                                <span>
                                                  <span data-custom-class="body_text"></span>
                                                </span>
                                              </bdt>
                                            </li>
                                          </ul>
                                          <div>
                                            <span data-custom-class="body_text">
                                              <span>
                                                <bdt className="block-component"></bdt>
                                              </span>
                                            </span>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  If we have reasonable grounds
                                                  to believe an individual has
                                                  been, is, or may be victim of
                                                  financial abuse
                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <span data-custom-class="body_text">
                                              <span>
                                                <bdt className="block-component"></bdt>
                                              </span>
                                            </span>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  If it is reasonable to expect
                                                  collection and use with
                                                  consent would compromise the
                                                  availability or the accuracy
                                                  of the information and the
                                                  collection is reasonable for
                                                  purposes related to
                                                  investigating a breach of an
                                                  agreement or a contravention
                                                  of the laws of Canada or a
                                                  province
                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <span data-custom-class="body_text">
                                              <span>
                                                <bdt className="block-component"></bdt>
                                              </span>
                                            </span>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span data-custom-class="body_text">
                                                <span>
                                                  If disclosure is required to
                                                  comply with a subpoena,
                                                  warrant, court order, or rules
                                                  of the court relating to the
                                                  production of records
                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <bdt className="block-component">
                                              <span>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span>
                                                <span data-custom-class="body_text">
                                                  If it was produced by an
                                                  individual in the course of
                                                  their employment, business, or
                                                  profession and the collection
                                                  is consistent with the
                                                  purposes for which the
                                                  information was produced
                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <span>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component"></bdt>
                                              </span>
                                            </span>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span>
                                                <span data-custom-class="body_text">
                                                  If the collection is solely
                                                  for journalistic, artistic, or
                                                  literary purposes
                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <span>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component"></bdt>
                                              </span>
                                            </span>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span>
                                                <span data-custom-class="body_text">
                                                  If the information is publicly
                                                  available and is specified by
                                                  the regulations
                                                </span>
                                                <bdt className="statement-end-if-in-editor">
                                                  <span data-custom-class="body_text"></span>
                                                </bdt>
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <bdt className="statement-end-if-in-editor">
                                              <span>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                            <bdt className="statement-end-if-in-editor">
                                              <span>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </bdt>
                                          </div>
                                          <div>
                                            <br></br>
                                          </div>
                                          <div id="whoshare">
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <span id="control">
                                                      <strong>
                                                        <span data-custom-class="heading_1">
                                                          4. WHEN AND WITH WHOM
                                                          DO WE SHARE YOUR
                                                          PERSONAL INFORMATION?
                                                        </span>
                                                      </strong>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </div>
                                          <div>
                                            <br></br>
                                          </div>
                                          <div>
                                            <span>
                                              <span>
                                                <span data-custom-class="body_text">
                                                  <strong>
                                                    <em>In Short:</em>
                                                  </strong>
                                                  <em>
                                                    &nbsp;We may share
                                                    information in specific
                                                    situations described in this
                                                    section and/or with the
                                                    following{" "}
                                                    <bdt className="block-component"></bdt>
                                                    third parties.
                                                  </em>
                                                </span>
                                              </span>
                                            </span>
                                          </div>
                                          <div>
                                            <span>
                                              <span>
                                                <span data-custom-class="body_text">
                                                  <bdt className="block-component"></bdt>
                                                </span>
                                              </span>
                                            </span>
                                          </div>
                                          <div>
                                            <br></br>
                                          </div>
                                          <div>
                                            <span>
                                              <span data-custom-class="body_text">
                                                We{" "}
                                                <bdt className="block-component"></bdt>
                                                may need to share your personal
                                                information in the following
                                                situations:
                                              </span>
                                            </span>
                                          </div>
                                          <ul>
                                            <li data-custom-class="body_text">
                                              <span>
                                                <span data-custom-class="body_text">
                                                  <strong>
                                                    Business Transfers.
                                                  </strong>{" "}
                                                  We may share or transfer your
                                                  information in connection
                                                  with, or during negotiations
                                                  of, any merger, sale of
                                                  company assets, financing, or
                                                  acquisition of all or a
                                                  portion of our business to
                                                  another company.
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div>
                                            <span>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component"></bdt>
                                              </span>
                                            </span>
                                            <div>
                                              <span>
                                                <bdt className="block-component">
                                                  <span data-custom-class="body_text"></span>
                                                </bdt>
                                              </span>
                                              <div>
                                                <bdt className="block-component">
                                                  <span>
                                                    <span data-custom-class="body_text"></span>
                                                  </span>
                                                </bdt>
                                              </div>
                                              <ul>
                                                <li data-custom-class="body_text">
                                                  <span data-custom-class="body_text">
                                                    <span>
                                                      <strong>
                                                        Business Partners.
                                                      </strong>{" "}
                                                      We may share your
                                                      information with our
                                                      business partners to offer
                                                      you certain products,
                                                      services, or promotions.
                                                    </span>
                                                  </span>
                                                  <bdt className="statement-end-if-in-editor">
                                                    <span>
                                                      <span data-custom-class="body_text"></span>
                                                    </span>
                                                  </bdt>
                                                </li>
                                              </ul>
                                              <div>
                                                <bdt className="block-component">
                                                  <span>
                                                    <span data-custom-class="body_text"></span>
                                                  </span>
                                                </bdt>
                                                <div>
                                                  <bdt className="block-component">
                                                    <span>
                                                      <span data-custom-class="body_text"></span>
                                                    </span>
                                                  </bdt>
                                                  <span data-custom-class="body_text">
                                                    <span>
                                                      <bdt className="block-component"></bdt>
                                                    </span>
                                                  </span>
                                                  <bdt className="statement-end-if-in-editor">
                                                    <span>
                                                      <span data-custom-class="body_text"></span>
                                                    </span>
                                                  </bdt>
                                                  <div>
                                                    <bdt className="block-component">
                                                      <span>
                                                        <span data-custom-class="body_text"></span>
                                                      </span>
                                                    </bdt>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <strong>
                                                      <span id="3pwebsites">
                                                        <span data-custom-class="heading_1">
                                                          5. WHAT IS OUR STANCE
                                                          ON THIRD-PARTY
                                                          WEBSITES?
                                                        </span>
                                                      </span>
                                                    </strong>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          <em>In Short:</em>
                                                        </strong>
                                                        <em>
                                                          &nbsp;We are not
                                                          responsible for the
                                                          safety of any
                                                          information that you
                                                          share with third
                                                          parties that we may
                                                          link to or who
                                                          advertise on our
                                                          Services, but are not
                                                          affiliated with, our
                                                          Services.
                                                        </em>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span data-custom-class="body_text">
                                                        The Services
                                                        <bdt className="block-component"></bdt>
                                                        <bdt className="block-component"></bdt>
                                                        <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                        may link to third-party
                                                        websites, online
                                                        services, or mobile
                                                        applications and/or
                                                        contain advertisements
                                                        from third parties that
                                                        are not affiliated with
                                                        us and which may link to
                                                        other websites,
                                                        services, or
                                                        applications.
                                                        Accordingly, we do not
                                                        make any guarantee
                                                        regarding any such third
                                                        parties, and we will not
                                                        be liable for any loss
                                                        or damage caused by the
                                                        use of such third-party
                                                        websites, services, or
                                                        applications. The
                                                        inclusion of a link
                                                        towards a third-party
                                                        website, service, or
                                                        application does not
                                                        imply an endorsement by
                                                        us. We cannot guarantee
                                                        the safety and privacy
                                                        of data you provide to
                                                        any third parties. Any
                                                        data collected by third
                                                        parties is not covered
                                                        by this privacy notice.
                                                        We are not responsible
                                                        for the content or
                                                        privacy and security
                                                        practices and policies
                                                        of any third parties,
                                                        including other
                                                        websites, services, or
                                                        applications that may be
                                                        linked to or from the
                                                        Services. You should
                                                        review the policies of
                                                        such third parties and
                                                        contact them directly to
                                                        respond to your
                                                        questions.
                                                      </span>
                                                    </span>
                                                    <bdt className="statement-end-if-in-editor">
                                                      <span>
                                                        <span data-custom-class="body_text"></span>
                                                      </span>
                                                    </bdt>
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span>
                                                              <bdt className="block-component">
                                                                <span data-custom-class="heading_1">
                                                                  <bdt className="block-component"></bdt>
                                                                </span>
                                                              </bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div id="cookies">
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span id="control">
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  6. DO WE USE
                                                                  COOKIES AND
                                                                  OTHER TRACKING
                                                                  TECHNOLOGIES?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>In Short:</em>
                                                          </strong>
                                                          <em>
                                                            &nbsp;We may use
                                                            cookies and other
                                                            tracking
                                                            technologies to
                                                            collect and store
                                                            your information.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          We may use cookies and
                                                          similar tracking
                                                          technologies (like web
                                                          beacons and pixels) to
                                                          gather information
                                                          when you interact with
                                                          our Services. Some
                                                          online tracking
                                                          technologies help us
                                                          maintain the security
                                                          of our Services
                                                          <bdt className="block-component"></bdt>{" "}
                                                          and your account
                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                          , prevent crashes, fix
                                                          bugs, save your
                                                          preferences, and
                                                          assist with basic site
                                                          functions.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          We also permit third
                                                          parties and service
                                                          providers to use
                                                          online tracking
                                                          technologies on our
                                                          Services for analytics
                                                          and advertising,
                                                          including to help
                                                          manage and display
                                                          advertisements, to
                                                          tailor advertisements
                                                          to your interests, or
                                                          to send abandoned
                                                          shopping cart
                                                          reminders (depending
                                                          on your communication
                                                          preferences). The
                                                          third parties and
                                                          service providers use
                                                          their technology to
                                                          provide advertising
                                                          about products and
                                                          services tailored to
                                                          your interests which
                                                          may appear either on
                                                          our Services or on
                                                          other websites.
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <bdt className="block-component">
                                                      <span></span>
                                                    </bdt>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <br></br>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span data-custom-class="body_text">
                                                        To the extent these
                                                        online tracking
                                                        technologies are deemed
                                                        to be a{" "}
                                                        <bdt className="block-component"></bdt>
                                                        "sale"/"sharing"
                                                        <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                        (which includes targeted
                                                        advertising, as defined
                                                        under the applicable
                                                        laws) under applicable
                                                        US state laws, you can
                                                        opt out of these online
                                                        tracking technologies by
                                                        submitting a request as
                                                        described below under
                                                        section{" "}
                                                        <bdt className="block-component"></bdt>
                                                        "
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                      </span>
                                                    </span>
                                                    <span data-custom-class="body_text">
                                                      <a
                                                        data-custom-class="link"
                                                        href="#uslaws"
                                                      >
                                                        <span>
                                                          DO UNITED STATES
                                                          RESIDENTS HAVE
                                                          SPECIFIC PRIVACY
                                                          RIGHTS?
                                                        </span>
                                                      </a>
                                                    </span>
                                                    <span>
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component"></bdt>
                                                        "
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                      </span>
                                                      <bdt className="statement-end-if-in-editor"></bdt>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          Specific information
                                                          about how we use such
                                                          technologies and how
                                                          you can refuse certain
                                                          cookies is set out in
                                                          our Cookie Notice
                                                          <span>
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component"></bdt>
                                                              .
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <bdt className="block-component">
                                                      <span></span>
                                                    </bdt>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <br></br>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <strong>
                                                        <span data-custom-class="heading_2">
                                                          Google Analytics
                                                        </span>
                                                      </strong>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <br></br>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span data-custom-class="body_text">
                                                        We may share your
                                                        information with Google
                                                        Analytics to track and{" "}
                                                        <bdt className="block-component"></bdt>
                                                        analyze
                                                        <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                        the use of the Services.
                                                        <bdt className="block-component"></bdt>{" "}
                                                        The Google Analytics
                                                        Advertising Features
                                                        that we may use include:{" "}
                                                        <bdt className="forloop-component"></bdt>
                                                        <bdt className="block-component"></bdt>
                                                        <bdt className="question">
                                                          Google Analytics
                                                          Demographics and
                                                          Interests Reporting
                                                        </bdt>
                                                        <bdt className="block-component"></bdt>
                                                        <bdt className="forloop-component"></bdt>
                                                        .
                                                        <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                        To opt out of being
                                                        tracked by Google
                                                        Analytics across the
                                                        Services, visit{" "}
                                                        <span>
                                                          <a
                                                            data-fr-linked="true"
                                                            href="https://tools.google.com/dlpage/gaoptout"
                                                            rel="noopener noreferrer"
                                                            target="_blank"
                                                          >
                                                            https://tools.google.com/dlpage/gaoptout
                                                          </a>
                                                          .
                                                        </span>
                                                        <bdt className="block-component"></bdt>{" "}
                                                        You can opt out of
                                                        Google Analytics
                                                        Advertising Features
                                                        through{" "}
                                                        <span>
                                                          <a
                                                            data-custom-class="link"
                                                            href="https://adssettings.google.com/"
                                                            rel="noopener noreferrer"
                                                            target="_blank"
                                                          >
                                                            Ads Settings
                                                          </a>
                                                        </span>{" "}
                                                        and Ad Settings for
                                                        mobile apps. Other opt
                                                        out means include{" "}
                                                        <span>
                                                          <span>
                                                            <a
                                                              data-custom-class="link"
                                                              href="http://optout.networkadvertising.org/"
                                                              rel="noopener noreferrer"
                                                              target="_blank"
                                                            >
                                                              http://optout.networkadvertising.org/
                                                            </a>
                                                          </span>
                                                        </span>{" "}
                                                        and{" "}
                                                        <span>
                                                          <a
                                                            data-custom-class="link"
                                                            href="http://www.networkadvertising.org/mobile-choice"
                                                            rel="noopener noreferrer"
                                                            target="_blank"
                                                          >
                                                            http://www.networkadvertising.org/mobile-choice
                                                          </a>
                                                        </span>
                                                        .
                                                        <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                        For more information on
                                                        the privacy practices of
                                                        Google, please visit the{" "}
                                                        <span>
                                                          <a
                                                            data-custom-class="link"
                                                            href="https://policies.google.com/privacy"
                                                            rel="noopener noreferrer"
                                                            target="_blank"
                                                          >
                                                            Google Privacy &amp;
                                                            Terms page
                                                          </a>
                                                        </span>
                                                        .
                                                      </span>
                                                    </span>
                                                    <bdt className="statement-end-if-in-editor">
                                                      <span></span>
                                                    </bdt>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <span>
                                                            <span>
                                                              <span>
                                                                <span>
                                                                  <span>
                                                                    <span>
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span data-custom-class="body_text">
                                                          <span>
                                                            <span>
                                                              <span>
                                                                <span>
                                                                  <span>
                                                                    <span>
                                                                      <span>
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component"></bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div id="sociallogins">
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span id="control">
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  7. HOW DO WE
                                                                  HANDLE YOUR
                                                                  SOCIAL LOGINS?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>
                                                              In Short:&nbsp;
                                                            </em>
                                                          </strong>
                                                          <em>
                                                            If you choose to
                                                            register or log in
                                                            to our Services
                                                            using a social media
                                                            account, we may have
                                                            access to certain
                                                            information about
                                                            you.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          Our Services offer you
                                                          the ability to
                                                          register and log in
                                                          using your third-party
                                                          social media account
                                                          details (like your
                                                          Facebook or X logins).
                                                          Where you choose to do
                                                          this, we will receive
                                                          certain profile
                                                          information about you
                                                          from your social media
                                                          provider. The profile
                                                          information we receive
                                                          may vary depending on
                                                          the social media
                                                          provider concerned,
                                                          but will often include
                                                          your name, email
                                                          address, friends list,
                                                          and profile picture,
                                                          as well as other
                                                          information you choose
                                                          to make public on such
                                                          a social media
                                                          platform.
                                                          <span>
                                                            <span>
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component"></bdt>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          We will use the
                                                          information we receive
                                                          only for the purposes
                                                          that are described in
                                                          this privacy notice or
                                                          that are otherwise
                                                          made clear to you on
                                                          the relevant Services.
                                                          Please note that we do
                                                          not control, and are
                                                          not responsible for,
                                                          other uses of your
                                                          personal information
                                                          by your third-party
                                                          social media provider.
                                                          We recommend that you
                                                          review their privacy
                                                          notice to understand
                                                          how they collect, use,
                                                          and share your
                                                          personal information,
                                                          and how you can set
                                                          your privacy
                                                          preferences on their
                                                          sites and apps.
                                                          <span>
                                                            <span>
                                                              <span>
                                                                <span>
                                                                  <span>
                                                                    <span>
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="statement-end-if-in-editor"></bdt>
                                                                          </span>
                                                                          <bdt className="block-component">
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component"></bdt>
                                                                            </span>
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div id="intltransfers">
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span id="control">
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  8. IS YOUR
                                                                  INFORMATION
                                                                  TRANSFERRED
                                                                  INTERNATIONALLY?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>
                                                              In Short:&nbsp;
                                                            </em>
                                                          </strong>
                                                          <em>
                                                            We may transfer,
                                                            store, and process
                                                            your information in
                                                            countries other than
                                                            your own.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div data-custom-class="body_text">
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          Our servers are
                                                          located in
                                                          <span>
                                                            <span>
                                                              <span data-custom-class="body_text">
                                                                <bdt className="forloop-component"></bdt>
                                                                <bdt className="block-component"></bdt>
                                                                <span>
                                                                  <span>
                                                                    <span data-custom-class="body_text">
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component"></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>{" "}
                                                                the{" "}
                                                                <span>
                                                                  <span>
                                                                    <span data-custom-class="body_text">
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="question">
                                                                              United
                                                                              States
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component"></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <bdt className="block-component"></bdt>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <bdt className="forloop-component"></bdt>
                                                    <bdt className="block-component"></bdt>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <span>
                                                            <span>
                                                              <span data-custom-class="body_text">
                                                                <span>
                                                                  <span>
                                                                    <span data-custom-class="body_text">
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component"></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>{" "}
                                                                and{" "}
                                                                <bdt className="question">
                                                                  Lithuania
                                                                  <span>
                                                                    <span>
                                                                      <span data-custom-class="body_text">
                                                                        <span>
                                                                          <span>
                                                                            <span data-custom-class="body_text">
                                                                              <span>
                                                                                <span data-custom-class="body_text">
                                                                                  <span>
                                                                                    <span>
                                                                                      <span data-custom-class="body_text">
                                                                                        <span>
                                                                                          <span>
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="statement-end-if-in-editor"></bdt>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </bdt>
                                                                <bdt className="else-block"></bdt>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <bdt className="forloop-component"></bdt>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          . If you are accessing
                                                          our Services from
                                                          outside
                                                        </span>
                                                        <span data-custom-class="body_text">
                                                          <span>
                                                            <span>
                                                              <span data-custom-class="body_text">
                                                                <bdt className="forloop-component"></bdt>
                                                                <bdt className="block-component"></bdt>
                                                                <span>
                                                                  <span>
                                                                    <span data-custom-class="body_text">
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component"></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>{" "}
                                                                the{" "}
                                                                <span>
                                                                  <span>
                                                                    <span data-custom-class="body_text">
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="question">
                                                                              United
                                                                              States
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component"></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <bdt className="block-component"></bdt>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <bdt className="forloop-component"></bdt>
                                                    <bdt className="block-component"></bdt>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <span>
                                                            <span>
                                                              <span data-custom-class="body_text">
                                                                <span>
                                                                  <span>
                                                                    <span data-custom-class="body_text">
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component"></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>{" "}
                                                                and{" "}
                                                                <bdt className="question">
                                                                  Lithuania
                                                                  <span>
                                                                    <span>
                                                                      <span data-custom-class="body_text">
                                                                        <span>
                                                                          <span>
                                                                            <span data-custom-class="body_text">
                                                                              <span>
                                                                                <span data-custom-class="body_text">
                                                                                  <span>
                                                                                    <span>
                                                                                      <span data-custom-class="body_text">
                                                                                        <span>
                                                                                          <span>
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="statement-end-if-in-editor"></bdt>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </bdt>
                                                                <bdt className="else-block"></bdt>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <bdt className="forloop-component"></bdt>
                                                    <span data-custom-class="body_text">
                                                      , please be aware that
                                                      your information may be
                                                      transferred to, stored,
                                                      and processed by us in our
                                                      facilities and by those
                                                      third parties with whom we
                                                      may share your personal
                                                      information (see{" "}
                                                      <bdt className="block-component"></bdt>
                                                      "
                                                      <bdt className="statement-end-if-in-editor"></bdt>
                                                    </span>
                                                    <a
                                                      data-custom-class="link"
                                                      href="#whoshare"
                                                    >
                                                      <span>
                                                        <span>
                                                          WHEN AND WITH WHOM DO
                                                          WE SHARE YOUR PERSONAL
                                                          INFORMATION?
                                                        </span>
                                                      </span>
                                                    </a>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component"></bdt>
                                                          "
                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                          above), in
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <bdt className="forloop-component"></bdt>
                                                          <span>
                                                            <span>
                                                              <span data-custom-class="body_text">
                                                                <span>
                                                                  <span>
                                                                    <span data-custom-class="body_text">
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component"></bdt>
                                                                            <span>
                                                                              <span>
                                                                                <span data-custom-class="body_text">
                                                                                  <span>
                                                                                    <span>
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="block-component"></bdt>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>{" "}
                                                                            the{" "}
                                                                            <span>
                                                                              <span>
                                                                                <span data-custom-class="body_text">
                                                                                  <span>
                                                                                    <span>
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="question">
                                                                                          United
                                                                                          States,
                                                                                        </bdt>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                                <span data-custom-class="body_text">
                                                                                  <span>
                                                                                    <span>
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="block-component"></bdt>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                            <span>
                                                                              <span>
                                                                                <span data-custom-class="body_text">
                                                                                  <span>
                                                                                    <span>
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="else-block"></bdt>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          <bdt className="forloop-component"></bdt>
                                                          <span>
                                                            <span>
                                                              <span data-custom-class="body_text">
                                                                <span>
                                                                  <span>
                                                                    <span data-custom-class="body_text">
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component"></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <span>
                                                                              <span>
                                                                                <span data-custom-class="body_text">
                                                                                  <span>
                                                                                    <span>
                                                                                      <span data-custom-class="body_text">
                                                                                        <span>
                                                                                          <span>
                                                                                            <span data-custom-class="body_text">
                                                                                              <span>
                                                                                                <span>
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <bdt className="block-component"></bdt>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>{" "}
                                                                            <bdt className="question">
                                                                              Lithuania,
                                                                            </bdt>
                                                                            <bdt className="statement-end-if-in-editor">
                                                                              <span>
                                                                                <span>
                                                                                  <span data-custom-class="body_text">
                                                                                    <span>
                                                                                      <span>
                                                                                        <span data-custom-class="body_text">
                                                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          <bdt className="forloop-component"></bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          &nbsp;and other
                                                          countries.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          If you are a resident
                                                          in the European
                                                          Economic Area (EEA),
                                                          United Kingdom (UK),
                                                          or Switzerland, then
                                                          these countries may
                                                          not necessarily have
                                                          data protection laws
                                                          or other similar laws
                                                          as comprehensive as
                                                          those in your country.
                                                          However, we will take
                                                          all necessary measures
                                                          to protect your
                                                          personal information
                                                          in accordance with
                                                          this privacy notice
                                                          and applicable law.
                                                          <span>
                                                            <span>
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component"></bdt>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component"></bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span data-custom-class="body_text">
                                                      <span>
                                                        <span>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component"></bdt>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <span>
                                                            <span>
                                                              <span>
                                                                <span>
                                                                  <span>
                                                                    <span>
                                                                      <span>
                                                                        <span>
                                                                          <span>
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="statement-end-if-in-editor"></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div id="inforetain">
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span id="control">
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  9. HOW LONG DO
                                                                  WE KEEP YOUR
                                                                  INFORMATION?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>
                                                              In Short:&nbsp;
                                                            </em>
                                                          </strong>
                                                          <em>
                                                            We keep your
                                                            information for as
                                                            long as necessary to{" "}
                                                            <bdt className="block-component"></bdt>
                                                            fulfill
                                                            <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                            the purposes
                                                            outlined in this
                                                            privacy notice
                                                            unless otherwise
                                                            required by law.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          We will only keep your
                                                          personal information
                                                          for as long as it is
                                                          necessary for the
                                                          purposes set out in
                                                          this privacy notice,
                                                          unless a longer
                                                          retention period is
                                                          required or permitted
                                                          by law (such as tax,
                                                          accounting, or other
                                                          legal requirements).
                                                          <bdt className="block-component"></bdt>{" "}
                                                          No purpose in this
                                                          notice will require us
                                                          keeping your personal
                                                          information for longer
                                                          than{" "}
                                                          <span>
                                                            <span>
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component"></bdt>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          <bdt className="block-component"></bdt>
                                                          <bdt className="question">
                                                            thirty six (36)
                                                          </bdt>{" "}
                                                          months past the
                                                          termination of the
                                                          user's account{" "}
                                                          <bdt className="block-component"></bdt>
                                                          <span>
                                                            <span>
                                                              <span data-custom-class="body_text">
                                                                <bdt className="else-block"></bdt>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          .
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          When we have no
                                                          ongoing legitimate
                                                          business need to
                                                          process your personal
                                                          information, we will
                                                          either delete or{" "}
                                                          <bdt className="block-component"></bdt>
                                                          anonymize
                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                          such information, or,
                                                          if this is not
                                                          possible (for example,
                                                          because your personal
                                                          information has been
                                                          stored in backup
                                                          archives), then we
                                                          will securely store
                                                          your personal
                                                          information and
                                                          isolate it from any
                                                          further processing
                                                          until deletion is
                                                          possible.
                                                          <span>
                                                            <bdt className="block-component"></bdt>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div id="infosafe">
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span id="control">
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  10. HOW DO WE
                                                                  KEEP YOUR
                                                                  INFORMATION
                                                                  SAFE?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>
                                                              In Short:&nbsp;
                                                            </em>
                                                          </strong>
                                                          <em>
                                                            We aim to protect
                                                            your personal
                                                            information through
                                                            a system of{" "}
                                                            <bdt className="block-component"></bdt>
                                                            organizational
                                                            <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                            and technical
                                                            security measures.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          We have implemented
                                                          appropriate and
                                                          reasonable technical
                                                          and{" "}
                                                          <bdt className="block-component"></bdt>
                                                          organizational
                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                          security measures
                                                          designed to protect
                                                          the security of any
                                                          personal information
                                                          we process. However,
                                                          despite our safeguards
                                                          and efforts to secure
                                                          your information, no
                                                          electronic
                                                          transmission over the
                                                          Internet or
                                                          information storage
                                                          technology can be
                                                          guaranteed to be 100%
                                                          secure, so we cannot
                                                          promise or guarantee
                                                          that hackers,
                                                          cybercriminals, or
                                                          other{" "}
                                                          <bdt className="block-component"></bdt>
                                                          unauthorized
                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                          third parties will not
                                                          be able to defeat our
                                                          security and
                                                          improperly collect,
                                                          access, steal, or
                                                          modify your
                                                          information. Although
                                                          we will do our best to
                                                          protect your personal
                                                          information,
                                                          transmission of
                                                          personal information
                                                          to and from our
                                                          Services is at your
                                                          own risk. You should
                                                          only access the
                                                          Services within a
                                                          secure environment.
                                                          <span>
                                                            <bdt className="statement-end-if-in-editor"></bdt>
                                                          </span>
                                                          <span>
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component"></bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div id="infominors">
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span id="control">
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  11. DO WE
                                                                  COLLECT
                                                                  INFORMATION
                                                                  FROM MINORS?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>In Short:</em>
                                                          </strong>
                                                          <em>
                                                            &nbsp;We do not
                                                            knowingly collect
                                                            data from or market
                                                            to{" "}
                                                            <bdt className="block-component"></bdt>
                                                            children under 18
                                                            years of age
                                                            <bdt className="else-block"></bdt>
                                                            .
                                                          </em>
                                                          <bdt className="block-component"></bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          We do not knowingly
                                                          collect, solicit data
                                                          from, or market to
                                                          children under 18
                                                          years of age, nor do
                                                          we knowingly sell such
                                                          personal information.
                                                          By using the Services,
                                                          you represent that you
                                                          are at least 18 or
                                                          that you are the
                                                          parent or guardian of
                                                          such a minor and
                                                          consent to such minor
                                                          dependent’s use of the
                                                          Services. If we learn
                                                          that personal
                                                          information from users
                                                          less than 18 years of
                                                          age has been
                                                          collected, we will
                                                          deactivate the account
                                                          and take reasonable
                                                          measures to promptly
                                                          delete such data from
                                                          our records. If you
                                                          become aware of any
                                                          data we may have
                                                          collected from
                                                          children under age 18,
                                                          please contact us at{" "}
                                                          <span>
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component"></bdt>
                                                              <bdt className="question">
                                                                info@andrewscookin.com
                                                              </bdt>
                                                              <bdt className="else-block"></bdt>
                                                            </span>
                                                          </span>
                                                          .
                                                        </span>
                                                        <span data-custom-class="body_text">
                                                          <bdt className="else-block">
                                                            <bdt className="block-component"></bdt>
                                                          </bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div id="privacyrights">
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span id="control">
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  12. WHAT ARE
                                                                  YOUR PRIVACY
                                                                  RIGHTS?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>In Short:</em>
                                                          </strong>
                                                          <em>
                                                            &nbsp;
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  <em>
                                                                    <bdt className="block-component"></bdt>
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <bdt className="block-component"></bdt>
                                                            Depending on your
                                                            state of residence
                                                            in the US or in{" "}
                                                            <bdt className="else-block"></bdt>
                                                            some regions, such
                                                            as{" "}
                                                            <bdt className="block-component"></bdt>
                                                            the European
                                                            Economic Area (EEA),
                                                            United Kingdom (UK),
                                                            Switzerland, and
                                                            Canada
                                                            <bdt className="block-component"></bdt>
                                                            , you have rights
                                                            that allow you
                                                            greater access to
                                                            and control over
                                                            your personal
                                                            information.
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  <em>
                                                                    <bdt className="statement-end-if-in-editor"></bdt>
                                                                  </em>
                                                                </span>
                                                              </span>
                                                              &nbsp;
                                                            </span>
                                                            You may review,
                                                            change, or terminate
                                                            your account at any
                                                            time, depending on
                                                            your country,
                                                            province, or state
                                                            of residence.
                                                          </em>
                                                          <span>
                                                            <span>
                                                              <bdt className="block-component"></bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          In some regions (like{" "}
                                                          <bdt className="block-component"></bdt>
                                                          the EEA, UK,
                                                          Switzerland, and
                                                          Canada
                                                          <bdt className="block-component"></bdt>
                                                          ), you have certain
                                                          rights under
                                                          applicable data
                                                          protection laws. These
                                                          may include the right
                                                          (i) to request access
                                                          and obtain a copy of
                                                          your personal
                                                          information, (ii) to
                                                          request rectification
                                                          or erasure; (iii) to
                                                          restrict the
                                                          processing of your
                                                          personal information;
                                                          (iv) if applicable, to
                                                          data portability; and
                                                          (v) not to be subject
                                                          to automated
                                                          decision-making. In
                                                          certain circumstances,
                                                          you may also have the
                                                          right to object to the
                                                          processing of your
                                                          personal information.
                                                          You can make such a
                                                          request by contacting
                                                          us by using the
                                                          contact details
                                                          provided in the
                                                          section{" "}
                                                          <bdt className="block-component"></bdt>
                                                          "
                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <a
                                                      data-custom-class="link"
                                                      href="#contact"
                                                    >
                                                      <span>
                                                        <span>
                                                          <span data-custom-class="body_text">
                                                            HOW CAN YOU CONTACT
                                                            US ABOUT THIS
                                                            NOTICE?
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </a>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component"></bdt>
                                                          "
                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                          below.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          We will consider and
                                                          act upon any request
                                                          in accordance with
                                                          applicable data
                                                          protection laws.
                                                          <bdt className="block-component"></bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <span>&nbsp;</span>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          If you are located in
                                                          the EEA or UK and you
                                                          believe we are
                                                          unlawfully processing
                                                          your personal
                                                          information, you also
                                                          have the right to
                                                          complain to your{" "}
                                                          <span>
                                                            <span>
                                                              <span data-custom-class="body_text">
                                                                <span>
                                                                  <span data-custom-class="body_text">
                                                                    <a
                                                                      data-custom-class="link"
                                                                      href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                      rel="noopener noreferrer"
                                                                      target="_blank"
                                                                    >
                                                                      <span>
                                                                        Member
                                                                        State
                                                                        data
                                                                        protection
                                                                        authority
                                                                      </span>
                                                                    </a>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>{" "}
                                                          or&nbsp;
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <a
                                                      data-custom-class="link"
                                                      href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                                                      rel="noopener noreferrer"
                                                      target="_blank"
                                                    >
                                                      <span>
                                                        <span>
                                                          <span data-custom-class="body_text">
                                                            UK data protection
                                                            authority
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </a>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          .
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          If you are located in
                                                          Switzerland, you may
                                                          contact the{" "}
                                                          <span>
                                                            <span>
                                                              <span data-custom-class="body_text">
                                                                <span>
                                                                  <span data-custom-class="body_text">
                                                                    <span>
                                                                      <a
                                                                        data-custom-class="link"
                                                                        href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                      >
                                                                        Federal
                                                                        Data
                                                                        Protection
                                                                        and
                                                                        Information
                                                                        Commissioner
                                                                      </a>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          .
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div id="withdrawconsent">
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <u>
                                                              Withdrawing your
                                                              consent:
                                                            </u>
                                                          </strong>{" "}
                                                          If we are relying on
                                                          your consent to
                                                          process your personal
                                                          information,
                                                          <bdt className="block-component"></bdt>{" "}
                                                          which may be express
                                                          and/or implied consent
                                                          depending on the
                                                          applicable law,
                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                          you have the right to
                                                          withdraw your consent
                                                          at any time. You can
                                                          withdraw your consent
                                                          at any time by
                                                          contacting us by using
                                                          the contact details
                                                          provided in the
                                                          section{" "}
                                                          <bdt className="block-component"></bdt>
                                                          "
                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <a
                                                      data-custom-class="link"
                                                      href="#contact"
                                                    >
                                                      <span>
                                                        <span>
                                                          <span data-custom-class="body_text">
                                                            HOW CAN YOU CONTACT
                                                            US ABOUT THIS
                                                            NOTICE?
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </a>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component"></bdt>
                                                          "
                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                          below
                                                          <bdt className="block-component"></bdt>
                                                          .
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span data-custom-class="body_text">
                                                        However, please note
                                                        that this will not
                                                        affect the lawfulness of
                                                        the processing before
                                                        its withdrawal nor,
                                                        <bdt className="block-component"></bdt>{" "}
                                                        when applicable law
                                                        allows,
                                                        <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                        will it affect the
                                                        processing of your
                                                        personal information
                                                        conducted in reliance on
                                                        lawful processing
                                                        grounds other than
                                                        consent.
                                                        <bdt className="block-component"></bdt>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          <u>
                                                            Opting out of
                                                            marketing and
                                                            promotional
                                                            communications:
                                                          </u>
                                                        </strong>
                                                        <strong>&nbsp;</strong>
                                                        You can unsubscribe from
                                                        our marketing and
                                                        promotional
                                                        communications at any
                                                        time by
                                                        <bdt className="block-component"></bdt>{" "}
                                                        clicking on the
                                                        unsubscribe link in the
                                                        emails that we send,
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                        <bdt className="block-component"></bdt>
                                                        <bdt className="block-component"></bdt>{" "}
                                                        or by contacting us
                                                        using the details
                                                        provided in the section{" "}
                                                        <bdt className="block-component"></bdt>
                                                        "
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                      </span>
                                                    </span>
                                                    <a
                                                      data-custom-class="link"
                                                      href="#contact"
                                                    >
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          HOW CAN YOU CONTACT US
                                                          ABOUT THIS NOTICE?
                                                        </span>
                                                      </span>
                                                    </a>
                                                    <span>
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component"></bdt>
                                                        "
                                                        <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                        below. You will then be
                                                        removed from the
                                                        marketing lists.
                                                        However, we may still
                                                        communicate with you —
                                                        for example, to send you
                                                        service-related messages
                                                        that are necessary for
                                                        the administration and
                                                        use of your account, to
                                                        respond to service
                                                        requests, or for other
                                                        non-marketing purposes.
                                                      </span>
                                                      <span data-custom-class="body_text">
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                      </span>
                                                    </span>
                                                    <bdt className="block-component">
                                                      <span>
                                                        <span data-custom-class="body_text"></span>
                                                      </span>
                                                    </bdt>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span data-custom-class="heading_2">
                                                        <strong>
                                                          Account Information
                                                        </strong>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span data-custom-class="body_text">
                                                      <span>
                                                        If you would at any time
                                                        like to review or change
                                                        the information in your
                                                        account or terminate
                                                        your account, you can:
                                                        <bdt className="forloop-component"></bdt>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li data-custom-class="body_text">
                                                      <span data-custom-class="body_text">
                                                        <span>
                                                          <bdt className="question">
                                                            Log in to your
                                                            account settings and
                                                            update your user
                                                            account.
                                                          </bdt>
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div>
                                                    <span data-custom-class="body_text">
                                                      <span>
                                                        <bdt className="forloop-component"></bdt>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span data-custom-class="body_text">
                                                        Upon your request to
                                                        terminate your account,
                                                        we will deactivate or
                                                        delete your account and
                                                        information from our
                                                        active databases.
                                                        However, we may retain
                                                        some information in our
                                                        files to prevent fraud,
                                                        troubleshoot problems,
                                                        assist with any
                                                        investigations, enforce
                                                        our legal terms and/or
                                                        comply with applicable
                                                        legal requirements.
                                                      </span>
                                                    </span>
                                                    <bdt className="statement-end-if-in-editor">
                                                      <span>
                                                        <span data-custom-class="body_text"></span>
                                                      </span>
                                                    </bdt>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <span>
                                                            <span>
                                                              <span>
                                                                <span>
                                                                  <span data-custom-class="body_text">
                                                                    <span>
                                                                      <span>
                                                                        <bdt className="block-component"></bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <u>
                                                              Cookies and
                                                              similar
                                                              technologies:
                                                            </u>
                                                          </strong>{" "}
                                                          Most Web browsers are
                                                          set to accept cookies
                                                          by default. If you
                                                          prefer, you can
                                                          usually choose to set
                                                          your browser to remove
                                                          cookies and to reject
                                                          cookies. If you choose
                                                          to remove cookies or
                                                          reject cookies, this
                                                          could affect certain
                                                          features or services
                                                          of our Services.{" "}
                                                          <bdt className="block-component"></bdt>
                                                          You may also{" "}
                                                          <span>
                                                            <span data-custom-class="body_text">
                                                              <a
                                                                data-custom-class="link"
                                                                href="http://www.aboutads.info/choices/"
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                              >
                                                                <span>
                                                                  opt out of
                                                                  interest-based
                                                                  advertising by
                                                                  advertisers
                                                                </span>
                                                              </a>
                                                            </span>
                                                          </span>{" "}
                                                          on our Services.{" "}
                                                          <span>
                                                            <span>
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component"></bdt>
                                                                <span>
                                                                  <span>
                                                                    <span>
                                                                      <span>
                                                                        <span>
                                                                          <span>
                                                                            <bdt className="statement-end-if-in-editor"></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <bdt className="block-component">
                                                      <span>
                                                        <span data-custom-class="body_text"></span>
                                                      </span>
                                                    </bdt>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span data-custom-class="body_text">
                                                      <span>
                                                        If you have questions or
                                                        comments about your
                                                        privacy rights, you may
                                                        email us at{" "}
                                                        <bdt className="question">
                                                          privacy@andrewscookin.com
                                                        </bdt>
                                                        .
                                                      </span>
                                                    </span>
                                                    <bdt className="statement-end-if-in-editor">
                                                      <span>
                                                        <span data-custom-class="body_text"></span>
                                                      </span>
                                                    </bdt>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div id="DNT">
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span id="control">
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  13. CONTROLS
                                                                  FOR
                                                                  DO-NOT-TRACK
                                                                  FEATURES
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          Most web browsers and
                                                          some mobile operating
                                                          systems and mobile
                                                          applications include a
                                                          Do-Not-Track (
                                                          <bdt className="block-component"></bdt>
                                                          "DNT"
                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                          ) feature or setting
                                                          you can activate to
                                                          signal your privacy
                                                          preference not to have
                                                          data about your online
                                                          browsing activities
                                                          monitored and
                                                          collected. At this
                                                          stage, no uniform
                                                          technology standard
                                                          for{" "}
                                                          <bdt className="block-component"></bdt>
                                                          recognizing
                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                          and implementing DNT
                                                          signals has been{" "}
                                                          <bdt className="block-component"></bdt>
                                                          finalized
                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                          . As such, we do not
                                                          currently respond to
                                                          DNT browser signals or
                                                          any other mechanism
                                                          that automatically
                                                          communicates your
                                                          choice not to be
                                                          tracked online. If a
                                                          standard for online
                                                          tracking is adopted
                                                          that we must follow in
                                                          the future, we will
                                                          inform you about that
                                                          practice in a revised
                                                          version of this
                                                          privacy notice.
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <bdt className="block-component">
                                                      <span></span>
                                                    </bdt>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <br></br>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span data-custom-class="body_text">
                                                        California law requires
                                                        us to let you know how
                                                        we respond to web
                                                        browser DNT signals.
                                                        Because there currently
                                                        is not an industry or
                                                        legal standard for{" "}
                                                        <bdt className="block-component"></bdt>
                                                        recognizing
                                                        <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                        or{" "}
                                                        <bdt className="block-component"></bdt>
                                                        honoring
                                                        <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                        DNT signals, we do not
                                                        respond to them at this
                                                        time.
                                                      </span>
                                                    </span>
                                                    <bdt className="statement-end-if-in-editor">
                                                      <span></span>
                                                    </bdt>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component"></bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div id="uslaws">
                                                    <span>
                                                      <span>
                                                        <span>
                                                          <span>
                                                            <span id="control">
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  14. DO UNITED
                                                                  STATES
                                                                  RESIDENTS HAVE
                                                                  SPECIFIC
                                                                  PRIVACY
                                                                  RIGHTS?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>
                                                              In Short:&nbsp;
                                                            </em>
                                                          </strong>
                                                          <em>
                                                            If you are a
                                                            resident of
                                                            <bdt className="block-component"></bdt>{" "}
                                                            California,
                                                            Colorado,
                                                            Connecticut,
                                                            Delaware, Florida,
                                                            Indiana, Iowa,
                                                            Kentucky, Montana,
                                                            New Hampshire, New
                                                            Jersey, Oregon,
                                                            Tennessee, Texas,
                                                            Utah, or Virginia
                                                            <bdt className="else-block"></bdt>
                                                            , you may have the
                                                            right to request
                                                            access to and
                                                            receive details
                                                            about the personal
                                                            information we
                                                            maintain about you
                                                            and how we have
                                                            processed it,
                                                            correct
                                                            inaccuracies, get a
                                                            copy of, or delete
                                                            your personal
                                                            information. You may
                                                            also have the right
                                                            to withdraw your
                                                            consent to our
                                                            processing of your
                                                            personal
                                                            information. These
                                                            rights may be
                                                            limited in some
                                                            circumstances by
                                                            applicable law. More
                                                            information is
                                                            provided below.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <strong>
                                                          <span data-custom-class="heading_2">
                                                            Categories of
                                                            Personal Information
                                                            We Collect
                                                          </span>
                                                        </strong>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span>
                                                        <span data-custom-class="body_text">
                                                          We have collected the
                                                          following categories
                                                          of personal
                                                          information in the
                                                          past twelve (12)
                                                          months:
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <table className="table">
                                                    <thead>
                                                      <tr>
                                                        <td>
                                                          <span>
                                                            <span>
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  Category
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span>
                                                            <span>
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  Examples
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span>
                                                            <span>
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  Collected
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  A. Identifiers
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  Contact
                                                                  details, such
                                                                  as real name,
                                                                  alias, postal
                                                                  address,
                                                                  telephone or
                                                                  mobile contact
                                                                  number, unique
                                                                  personal
                                                                  identifier,
                                                                  online
                                                                  identifier,
                                                                  Internet
                                                                  Protocol
                                                                  address, email
                                                                  address, and
                                                                  account name
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component">
                                                                    <bdt className="block-component"></bdt>
                                                                  </bdt>
                                                                  YES
                                                                  <bdt className="else-block">
                                                                    <bdt className="block-component"></bdt>
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  <div>
                                                    <bdt className="block-component"></bdt>
                                                  </div>
                                                  <table className="table">
                                                    <tbody>
                                                      <tr>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  B. Personal
                                                                  information as
                                                                  defined in the
                                                                  California
                                                                  Customer
                                                                  Records
                                                                  statute
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  Name, contact
                                                                  information,
                                                                  education,
                                                                  employment,
                                                                  employment
                                                                  history, and
                                                                  financial
                                                                  information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="forloop-component">
                                                                    <bdt className="block-component">
                                                                      <bdt className="block-component"></bdt>
                                                                      YES
                                                                      <bdt className="block-component"></bdt>
                                                                    </bdt>
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  <div>
                                                    <bdt className="block-component"></bdt>
                                                  </div>
                                                  <table className="table">
                                                    <tbody>
                                                      <tr>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component"></bdt>
                                                                  C
                                                                  <bdt className="else-block"></bdt>
                                                                  . Protected
                                                                  classification
                                                                  characteristics
                                                                  under state or
                                                                  federal law
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  Gender, age,
                                                                  date of birth,
                                                                  race and
                                                                  ethnicity,
                                                                  national
                                                                  origin,
                                                                  marital
                                                                  status, and
                                                                  other
                                                                  demographic
                                                                  data
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                          <div data-custom-class="body_text">
                                                            <bdt className="forloop-component">
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component"></bdt>
                                                                <bdt className="block-component"></bdt>
                                                                NO
                                                                <bdt className="statement-end-if-in-editor"></bdt>
                                                                <bdt className="block-component"></bdt>
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component"></bdt>
                                                                  D
                                                                  <bdt className="else-block"></bdt>
                                                                  . Commercial
                                                                  information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  Transaction
                                                                  information,
                                                                  purchase
                                                                  history,
                                                                  financial
                                                                  details, and
                                                                  payment
                                                                  information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                          <div data-custom-class="body_text">
                                                            <bdt className="forloop-component">
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component"></bdt>
                                                                <bdt className="block-component"></bdt>
                                                                NO
                                                                <bdt className="statement-end-if-in-editor">
                                                                  <bdt className="block-component"></bdt>
                                                                </bdt>
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component"></bdt>
                                                                  E
                                                                  <bdt className="else-block"></bdt>
                                                                  . Biometric
                                                                  information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  Fingerprints
                                                                  and
                                                                  voiceprints
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                          <div data-custom-class="body_text">
                                                            <bdt className="forloop-component">
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component">
                                                                  <bdt className="block-component">
                                                                    NO
                                                                  </bdt>
                                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                                  <bdt className="block-component"></bdt>
                                                                </bdt>
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component"></bdt>
                                                                  F
                                                                  <bdt className="else-block"></bdt>
                                                                  . Internet or
                                                                  other similar
                                                                  network
                                                                  activity
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  Browsing
                                                                  history,
                                                                  search
                                                                  history,
                                                                  online{" "}
                                                                  <bdt className="block-component"></bdt>
                                                                  behavior
                                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                                  , interest
                                                                  data, and
                                                                  interactions
                                                                  with our and
                                                                  other
                                                                  websites,
                                                                  applications,
                                                                  systems, and
                                                                  advertisements
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                          <div data-custom-class="body_text">
                                                            <bdt className="forloop-component">
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component"></bdt>
                                                                <bdt className="block-component"></bdt>
                                                                NO
                                                                <bdt className="statement-end-if-in-editor"></bdt>
                                                                <bdt className="block-component"></bdt>
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component"></bdt>
                                                                  G
                                                                  <bdt className="else-block"></bdt>
                                                                  . Geolocation
                                                                  data
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  Device
                                                                  location
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                          <div data-custom-class="body_text">
                                                            <bdt className="forloop-component">
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component"></bdt>
                                                                <bdt className="block-component"></bdt>
                                                                NO
                                                                <bdt className="statement-end-if-in-editor"></bdt>
                                                                <bdt className="block-component"></bdt>
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component"></bdt>
                                                                  H
                                                                  <bdt className="else-block"></bdt>
                                                                  . Audio,
                                                                  electronic,
                                                                  sensory, or
                                                                  similar
                                                                  information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  Images and
                                                                  audio, video
                                                                  or call
                                                                  recordings
                                                                  created in
                                                                  connection
                                                                  with our
                                                                  business
                                                                  activities
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                          <div data-custom-class="body_text">
                                                            <bdt className="forloop-component">
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component"></bdt>
                                                                <bdt className="block-component"></bdt>
                                                                NO
                                                                <bdt className="statement-end-if-in-editor"></bdt>
                                                                <bdt className="block-component"></bdt>
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component"></bdt>
                                                                  I
                                                                  <bdt className="else-block"></bdt>
                                                                  . Professional
                                                                  or
                                                                  employment-related
                                                                  information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  Business
                                                                  contact
                                                                  details in
                                                                  order to
                                                                  provide you
                                                                  our Services
                                                                  at a business
                                                                  level or job
                                                                  title, work
                                                                  history, and
                                                                  professional
                                                                  qualifications
                                                                  if you apply
                                                                  for a job with
                                                                  us
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                          <div data-custom-class="body_text">
                                                            <bdt className="forloop-component">
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component"></bdt>
                                                                <bdt className="block-component"></bdt>
                                                                NO
                                                                <bdt className="statement-end-if-in-editor"></bdt>
                                                                <bdt className="block-component"></bdt>
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component"></bdt>
                                                                  J
                                                                  <bdt className="else-block"></bdt>
                                                                  . Education
                                                                  Information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  Student
                                                                  records and
                                                                  directory
                                                                  information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                          <div data-custom-class="body_text">
                                                            <bdt className="forloop-component">
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component"></bdt>
                                                                <bdt className="block-component"></bdt>
                                                                NO
                                                                <bdt className="statement-end-if-in-editor"></bdt>
                                                                <bdt className="block-component"></bdt>
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component"></bdt>
                                                                  K
                                                                  <bdt className="else-block"></bdt>
                                                                  . Inferences
                                                                  drawn from
                                                                  collected
                                                                  personal
                                                                  information
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <span>
                                                              <span>
                                                                <span data-custom-class="body_text">
                                                                  Inferences
                                                                  drawn from any
                                                                  of the
                                                                  collected
                                                                  personal
                                                                  information
                                                                  listed above
                                                                  to create a
                                                                  profile or
                                                                  summary about,
                                                                  for example,
                                                                  an
                                                                  individual’s
                                                                  preferences
                                                                  and
                                                                  characteristics
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                          <div data-custom-class="body_text">
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component"></bdt>
                                                              NO
                                                              <span>
                                                                <span>
                                                                  <span data-custom-class="body_text">
                                                                    <span>
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div>
                                                            <br></br>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component"></bdt>
                                                            L
                                                            <bdt className="else-block"></bdt>
                                                            . Sensitive personal
                                                            Information
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <bdt className="block-component">
                                                            <span data-custom-class="body_text"></span>
                                                          </bdt>
                                                        </td>
                                                        <td>
                                                          <div data-empty="true">
                                                            <br></br>
                                                          </div>
                                                          <div
                                                            data-custom-class="body_text"
                                                            data-empty="true"
                                                          >
                                                            <bdt className="block-component">
                                                              <span data-custom-class="body_text"></span>
                                                            </bdt>
                                                            NO
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span data-custom-class="body_text"></span>
                                                            </bdt>
                                                          </div>
                                                          <div data-empty="true">
                                                            <br></br>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  <div>
                                                    <bdt className="block-component">
                                                      <span></span>
                                                    </bdt>
                                                  </div>
                                                  <div>
                                                    <br></br>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <span data-custom-class="body_text">
                                                        We may also collect
                                                        other personal
                                                        information outside of
                                                        these categories through
                                                        instances where you
                                                        interact with us in
                                                        person, online, or by
                                                        phone or mail in the
                                                        context of:
                                                      </span>
                                                      <bdt className="block-component"></bdt>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li data-custom-class="body_text">
                                                      <span>
                                                        Receiving help through
                                                        our customer support
                                                        channels;
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div>
                                                    <span>
                                                      <bdt className="block-component"></bdt>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li data-custom-class="body_text">
                                                      <span>
                                                        Participation in
                                                        customer surveys or
                                                        contests; and
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div>
                                                    <span>
                                                      <bdt className="block-component"></bdt>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li data-custom-class="body_text">
                                                      <span>
                                                        Facilitation in the
                                                        delivery of our Services
                                                        and to respond to your
                                                        inquiries.
                                                      </span>
                                                      <bdt className="statement-end-if-in-editor">
                                                        <span></span>
                                                      </bdt>
                                                    </li>
                                                  </ul>
                                                  <div>
                                                    <bdt className="block-component">
                                                      <span data-custom-class="body_text"></span>
                                                    </bdt>
                                                    <span data-custom-class="body_text">
                                                      We will use and retain the
                                                      collected personal
                                                      information as needed to
                                                      provide the Services or
                                                      for:
                                                      <bdt className="block-component"></bdt>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li data-custom-class="body_text">
                                                      <span data-custom-class="body_text">
                                                        Category A -{" "}
                                                        <bdt className="question">
                                                          36 months after the
                                                          user has terminated
                                                          their account with us.
                                                        </bdt>
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div>
                                                    <span data-custom-class="body_text">
                                                      <bdt className="block-component">
                                                        <bdt className="block-component"></bdt>
                                                      </bdt>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li data-custom-class="body_text">
                                                      <span data-custom-class="body_text">
                                                        Category B -{" "}
                                                        <bdt className="question">
                                                          36 months after the
                                                          account has been
                                                          terminated.
                                                        </bdt>
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div>
                                                    <span data-custom-class="body_text">
                                                      <bdt className="block-component"></bdt>
                                                    </span>
                                                    <div>
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component"></bdt>
                                                      </span>
                                                      <div>
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component"></bdt>
                                                        </span>
                                                        <div>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component"></bdt>
                                                          </span>
                                                          <div>
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component"></bdt>
                                                            </span>
                                                            <div>
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component"></bdt>
                                                              </span>
                                                            </div>
                                                            <ul>
                                                              <li data-custom-class="body_text">
                                                                <span data-custom-class="body_text">
                                                                  Category{" "}
                                                                  <bdt className="block-component"></bdt>
                                                                  H
                                                                  <bdt className="else-block"></bdt>{" "}
                                                                  -{" "}
                                                                  <bdt className="question">
                                                                    As long as
                                                                    the user has
                                                                    an account
                                                                    with us
                                                                  </bdt>
                                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                                </span>
                                                              </li>
                                                            </ul>
                                                            <div>
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component"></bdt>
                                                              </span>
                                                              <div>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component"></bdt>
                                                                </span>
                                                                <div>
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="block-component"></bdt>
                                                                  </span>
                                                                  <div>
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component"></bdt>
                                                                    </span>
                                                                    <bdt className="statement-end-if-in-editor">
                                                                      <span data-custom-class="body_text"></span>
                                                                    </bdt>
                                                                    <div>
                                                                      <strong>
                                                                        <span>
                                                                          <span data-custom-class="heading_2">
                                                                            Sources
                                                                            of
                                                                            Personal
                                                                            Information
                                                                          </span>
                                                                        </span>
                                                                      </strong>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span data-custom-class="body_text">
                                                                          Learn
                                                                          more
                                                                          about
                                                                          the
                                                                          sources
                                                                          of
                                                                          personal
                                                                          information
                                                                          we
                                                                          collect
                                                                          in{" "}
                                                                          <bdt className="block-component"></bdt>
                                                                          "
                                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                                        </span>
                                                                      </span>
                                                                      <span data-custom-class="body_text">
                                                                        <a
                                                                          data-custom-class="link"
                                                                          href="#infocollect"
                                                                        >
                                                                          <span>
                                                                            WHAT
                                                                            INFORMATION
                                                                            DO
                                                                            WE
                                                                            COLLECT?
                                                                          </span>
                                                                        </a>
                                                                      </span>
                                                                      <span>
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component"></bdt>
                                                                          "
                                                                        </span>
                                                                        <bdt className="statement-end-if-in-editor">
                                                                          <span data-custom-class="body_text"></span>
                                                                        </bdt>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span>
                                                                          <strong>
                                                                            <span data-custom-class="heading_2">
                                                                              How
                                                                              We
                                                                              Use
                                                                              and
                                                                              Share
                                                                              Personal
                                                                              Information
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div>
                                                                      <span data-custom-class="body_text">
                                                                        Learn
                                                                        about
                                                                        how we
                                                                        use your
                                                                        personal
                                                                        information
                                                                        in the
                                                                        section,{" "}
                                                                        <bdt className="block-component"></bdt>
                                                                        "
                                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                                      </span>
                                                                      <a
                                                                        data-custom-class="link"
                                                                        href="#infouse"
                                                                      >
                                                                        <span>
                                                                          HOW DO
                                                                          WE
                                                                          PROCESS
                                                                          YOUR
                                                                          INFORMATION?
                                                                        </span>
                                                                      </a>
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component"></bdt>
                                                                        "
                                                                      </span>
                                                                      <bdt className="statement-end-if-in-editor">
                                                                        <span data-custom-class="body_text"></span>
                                                                      </bdt>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <span>
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="block-component"></bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <strong>
                                                                              Will
                                                                              your
                                                                              information
                                                                              be
                                                                              shared
                                                                              with
                                                                              anyone
                                                                              else?
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            We
                                                                            may
                                                                            disclose
                                                                            your
                                                                            personal
                                                                            information
                                                                            with
                                                                            our
                                                                            service
                                                                            providers
                                                                            pursuant
                                                                            to a
                                                                            written
                                                                            contract
                                                                            between
                                                                            us
                                                                            and
                                                                            each
                                                                            service
                                                                            provider.
                                                                            Learn
                                                                            more
                                                                            about
                                                                            how
                                                                            we
                                                                            disclose
                                                                            personal
                                                                            information
                                                                            to
                                                                            in
                                                                            the
                                                                            section,{" "}
                                                                            <bdt className="block-component"></bdt>
                                                                            "
                                                                            <bdt className="statement-end-if-in-editor"></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <a
                                                                        data-custom-class="link"
                                                                        href="#whoshare"
                                                                      >
                                                                        <span>
                                                                          <span>
                                                                            WHEN
                                                                            AND
                                                                            WITH
                                                                            WHOM
                                                                            DO
                                                                            WE
                                                                            SHARE
                                                                            YOUR
                                                                            PERSONAL
                                                                            INFORMATION?
                                                                          </span>
                                                                        </span>
                                                                      </a>
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component"></bdt>
                                                                            "
                                                                            <bdt className="statement-end-if-in-editor"></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            We
                                                                            may
                                                                            use
                                                                            your
                                                                            personal
                                                                            information
                                                                            for
                                                                            our
                                                                            own
                                                                            business
                                                                            purposes,
                                                                            such
                                                                            as
                                                                            for
                                                                            undertaking
                                                                            internal
                                                                            research
                                                                            for
                                                                            technological
                                                                            development
                                                                            and
                                                                            demonstration.
                                                                            This
                                                                            is
                                                                            not
                                                                            considered
                                                                            to
                                                                            be{" "}
                                                                            <bdt className="block-component"></bdt>
                                                                            "selling"
                                                                            <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                                            of
                                                                            your
                                                                            personal
                                                                            information.
                                                                            <span>
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="block-component"></bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            We
                                                                            have
                                                                            not
                                                                            disclosed,
                                                                            sold,
                                                                            or
                                                                            shared
                                                                            any
                                                                            personal
                                                                            information
                                                                            to
                                                                            third
                                                                            parties
                                                                            for
                                                                            a
                                                                            business
                                                                            or
                                                                            commercial
                                                                            purpose
                                                                            in
                                                                            the
                                                                            preceding
                                                                            twelve
                                                                            (12)
                                                                            months.
                                                                            We
                                                                            <span>
                                                                              &nbsp;
                                                                            </span>
                                                                            will
                                                                            not
                                                                            sell
                                                                            or
                                                                            share
                                                                            personal
                                                                            information
                                                                            in
                                                                            the
                                                                            future
                                                                            belonging
                                                                            to
                                                                            website
                                                                            visitors,
                                                                            users,
                                                                            and
                                                                            other
                                                                            consumers.
                                                                            <span>
                                                                              <span data-custom-class="body_text">
                                                                                <span>
                                                                                  <span data-custom-class="body_text">
                                                                                    <span>
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                                <bdt className="block-component"></bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <span data-custom-class="body_text">
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component"></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <strong>
                                                                          <span data-custom-class="heading_2">
                                                                            Your
                                                                            Rights
                                                                          </span>
                                                                        </strong>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <br></br>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span data-custom-class="body_text">
                                                                          You
                                                                          have
                                                                          rights
                                                                          under
                                                                          certain
                                                                          US
                                                                          state
                                                                          data
                                                                          protection
                                                                          laws.
                                                                          However,
                                                                          these
                                                                          rights
                                                                          are
                                                                          not
                                                                          absolute,
                                                                          and in
                                                                          certain
                                                                          cases,
                                                                          we may
                                                                          decline
                                                                          your
                                                                          request
                                                                          as
                                                                          permitted
                                                                          by
                                                                          law.
                                                                          These
                                                                          rights
                                                                          include:
                                                                        </span>
                                                                        <bdt className="block-component"></bdt>
                                                                      </span>
                                                                    </div>
                                                                    <ul>
                                                                      <li data-custom-class="body_text">
                                                                        <span>
                                                                          <strong>
                                                                            Right
                                                                            to
                                                                            know
                                                                          </strong>{" "}
                                                                          whether
                                                                          or not
                                                                          we are
                                                                          processing
                                                                          your
                                                                          personal
                                                                          data
                                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                                        </span>
                                                                      </li>
                                                                    </ul>
                                                                    <div>
                                                                      <span>
                                                                        <bdt className="block-component"></bdt>
                                                                      </span>
                                                                    </div>
                                                                    <ul>
                                                                      <li data-custom-class="body_text">
                                                                        <span>
                                                                          <strong>
                                                                            Right
                                                                            to
                                                                            access&nbsp;
                                                                          </strong>
                                                                          your
                                                                          personal
                                                                          data
                                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                                        </span>
                                                                      </li>
                                                                    </ul>
                                                                    <div>
                                                                      <span>
                                                                        <bdt className="block-component"></bdt>
                                                                      </span>
                                                                    </div>
                                                                    <ul>
                                                                      <li data-custom-class="body_text">
                                                                        <span>
                                                                          <strong>
                                                                            Right
                                                                            to
                                                                            correct&nbsp;
                                                                          </strong>
                                                                          inaccuracies
                                                                          in
                                                                          your
                                                                          personal
                                                                          data
                                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                                        </span>
                                                                      </li>
                                                                    </ul>
                                                                    <div>
                                                                      <span>
                                                                        <bdt className="block-component"></bdt>
                                                                      </span>
                                                                    </div>
                                                                    <ul>
                                                                      <li data-custom-class="body_text">
                                                                        <span>
                                                                          <strong>
                                                                            Right
                                                                            to
                                                                            request
                                                                          </strong>{" "}
                                                                          the
                                                                          deletion
                                                                          of
                                                                          your
                                                                          personal
                                                                          data
                                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                                        </span>
                                                                      </li>
                                                                    </ul>
                                                                    <div>
                                                                      <span>
                                                                        <bdt className="block-component"></bdt>
                                                                      </span>
                                                                    </div>
                                                                    <ul>
                                                                      <li data-custom-class="body_text">
                                                                        <span>
                                                                          <strong>
                                                                            Right
                                                                            to
                                                                            obtain
                                                                            a
                                                                            copy&nbsp;
                                                                          </strong>
                                                                          of the
                                                                          personal
                                                                          data
                                                                          you
                                                                          previously
                                                                          shared
                                                                          with
                                                                          us
                                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                                        </span>
                                                                      </li>
                                                                    </ul>
                                                                    <div>
                                                                      <span>
                                                                        <bdt className="block-component"></bdt>
                                                                      </span>
                                                                    </div>
                                                                    <ul>
                                                                      <li data-custom-class="body_text">
                                                                        <span>
                                                                          <strong>
                                                                            Right
                                                                            to
                                                                            non-discrimination
                                                                          </strong>{" "}
                                                                          for
                                                                          exercising
                                                                          your
                                                                          rights
                                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                                        </span>
                                                                      </li>
                                                                    </ul>
                                                                    <div>
                                                                      <span>
                                                                        <bdt className="block-component"></bdt>
                                                                      </span>
                                                                    </div>
                                                                    <ul>
                                                                      <li data-custom-class="body_text">
                                                                        <span>
                                                                          <strong>
                                                                            Right
                                                                            to
                                                                            opt
                                                                            out
                                                                          </strong>{" "}
                                                                          of the
                                                                          processing
                                                                          of
                                                                          your
                                                                          personal
                                                                          data
                                                                          if it
                                                                          is
                                                                          used
                                                                          for
                                                                          targeted
                                                                          advertising
                                                                          <bdt className="block-component"></bdt>{" "}
                                                                          (or
                                                                          sharing
                                                                          as
                                                                          defined
                                                                          under
                                                                          California’s
                                                                          privacy
                                                                          law)
                                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                                          , the
                                                                          sale
                                                                          of
                                                                          personal
                                                                          data,
                                                                          or
                                                                          profiling
                                                                          in
                                                                          furtherance
                                                                          of
                                                                          decisions
                                                                          that
                                                                          produce
                                                                          legal
                                                                          or
                                                                          similarly
                                                                          significant
                                                                          effects
                                                                          (
                                                                          <bdt className="block-component"></bdt>
                                                                          "profiling"
                                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                                          )
                                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                                        </span>
                                                                      </li>
                                                                    </ul>
                                                                    <div>
                                                                      <span>
                                                                        <bdt className="block-component"></bdt>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span data-custom-class="body_text">
                                                                          Depending
                                                                          upon
                                                                          the
                                                                          state
                                                                          where
                                                                          you
                                                                          live,
                                                                          you
                                                                          may
                                                                          also
                                                                          have
                                                                          the
                                                                          following
                                                                          rights:
                                                                        </span>
                                                                        <bdt className="block-component"></bdt>
                                                                      </span>
                                                                    </div>
                                                                    <ul>
                                                                      <li data-custom-class="body_text">
                                                                        <span>
                                                                          Right
                                                                          to
                                                                          obtain
                                                                          a list
                                                                          of the
                                                                          categories
                                                                          of
                                                                          third
                                                                          parties
                                                                          to
                                                                          which
                                                                          we
                                                                          have
                                                                          disclosed
                                                                          personal
                                                                          data
                                                                          (as
                                                                          permitted
                                                                          by
                                                                          applicable
                                                                          law,
                                                                          including
                                                                          <bdt className="block-component"></bdt>{" "}
                                                                          California's
                                                                          and
                                                                          Delaware's
                                                                          <bdt className="else-block"></bdt>{" "}
                                                                          privacy
                                                                          law)
                                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                                        </span>
                                                                      </li>
                                                                    </ul>
                                                                    <div>
                                                                      <bdt className="block-component">
                                                                        <span></span>
                                                                      </bdt>
                                                                    </div>
                                                                    <ul>
                                                                      <li data-custom-class="body_text">
                                                                        <span>
                                                                          Right
                                                                          to
                                                                          obtain
                                                                          a list
                                                                          of
                                                                          specific
                                                                          third
                                                                          parties
                                                                          to
                                                                          which
                                                                          we
                                                                          have
                                                                          disclosed
                                                                          personal
                                                                          data
                                                                          (as
                                                                          permitted
                                                                          by
                                                                          applicable
                                                                          law,
                                                                          including
                                                                          Oregon’s
                                                                          privacy
                                                                          law)
                                                                        </span>
                                                                        <bdt className="statement-end-if-in-editor">
                                                                          <span></span>
                                                                        </bdt>
                                                                      </li>
                                                                    </ul>
                                                                    <div>
                                                                      <bdt className="block-component">
                                                                        <span></span>
                                                                      </bdt>
                                                                    </div>
                                                                    <ul>
                                                                      <li data-custom-class="body_text">
                                                                        <span>
                                                                          Right
                                                                          to
                                                                          limit
                                                                          use
                                                                          and
                                                                          disclosure
                                                                          of
                                                                          sensitive
                                                                          personal
                                                                          data
                                                                          (as
                                                                          permitted
                                                                          by
                                                                          applicable
                                                                          law,
                                                                          including
                                                                          California’s
                                                                          privacy
                                                                          law)
                                                                        </span>
                                                                        <bdt className="statement-end-if-in-editor">
                                                                          <span></span>
                                                                        </bdt>
                                                                      </li>
                                                                    </ul>
                                                                    <div>
                                                                      <bdt className="block-component">
                                                                        <span></span>
                                                                      </bdt>
                                                                    </div>
                                                                    <ul>
                                                                      <li data-custom-class="body_text">
                                                                        <span>
                                                                          Right
                                                                          to opt
                                                                          out of
                                                                          the
                                                                          collection
                                                                          of
                                                                          sensitive
                                                                          data
                                                                          and
                                                                          personal
                                                                          data
                                                                          collected
                                                                          through
                                                                          the
                                                                          operation
                                                                          of a
                                                                          voice
                                                                          or
                                                                          facial
                                                                          recognition
                                                                          feature
                                                                          (as
                                                                          permitted
                                                                          by
                                                                          applicable
                                                                          law,
                                                                          including
                                                                          Florida’s
                                                                          privacy
                                                                          law)
                                                                        </span>
                                                                        <bdt className="statement-end-if-in-editor">
                                                                          <span></span>
                                                                        </bdt>
                                                                      </li>
                                                                    </ul>
                                                                    <div>
                                                                      <span>
                                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <strong>
                                                                        <span>
                                                                          <span data-custom-class="heading_2">
                                                                            How
                                                                            to
                                                                            Exercise
                                                                            Your
                                                                            Rights
                                                                          </span>
                                                                        </span>
                                                                      </strong>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            To
                                                                            exercise
                                                                            these
                                                                            rights,
                                                                            you
                                                                            can
                                                                            contact
                                                                            us{" "}
                                                                            <bdt className="block-component"></bdt>
                                                                            by
                                                                            submitting
                                                                            a&nbsp;
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <a
                                                                        data-custom-class="link"
                                                                        href="https://app.termly.io/notify/90f5534d-c7ed-436e-8e7e-ebcb009ec13a"
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                      >
                                                                        <span>
                                                                          <span>
                                                                            data
                                                                            subject
                                                                            access
                                                                            request
                                                                          </span>
                                                                        </span>
                                                                      </a>
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            ,{" "}
                                                                            <bdt className="block-component"></bdt>
                                                                          </span>
                                                                          <span>
                                                                            <span data-custom-class="body_text">
                                                                              <span>
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="block-component"></bdt>
                                                                                  by
                                                                                  emailing
                                                                                  us
                                                                                  at{" "}
                                                                                  <bdt className="question">
                                                                                    info@andrewscookin.com
                                                                                  </bdt>

                                                                                  ,{" "}
                                                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                                                  <bdt className="block-component"></bdt>
                                                                                </span>
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="block-component"></bdt>
                                                                                  <bdt className="block-component">
                                                                                    <span data-custom-class="body_text">
                                                                                      <bdt className="block-component"></bdt>
                                                                                    </span>
                                                                                  </bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <span data-custom-class="body_text">
                                                                        or by
                                                                        referring
                                                                        to the
                                                                        contact
                                                                        details
                                                                        at the
                                                                        bottom
                                                                        of this
                                                                        document.
                                                                      </span>
                                                                      <bdt className="block-component">
                                                                        <span></span>
                                                                      </bdt>
                                                                      <bdt className="block-component">
                                                                        <span></span>
                                                                      </bdt>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span data-custom-class="body_text">
                                                                          Under
                                                                          certain
                                                                          US
                                                                          state
                                                                          data
                                                                          protection
                                                                          laws,
                                                                          you
                                                                          can
                                                                          designate
                                                                          an{" "}
                                                                          <bdt className="block-component"></bdt>
                                                                          authorized
                                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                                          agent
                                                                          to
                                                                          make a
                                                                          request
                                                                          on
                                                                          your
                                                                          behalf.
                                                                          We may
                                                                          deny a
                                                                          request
                                                                          from
                                                                          an{" "}
                                                                          <bdt className="block-component"></bdt>
                                                                          authorized
                                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                                          agent
                                                                          that
                                                                          does
                                                                          not
                                                                          submit
                                                                          proof
                                                                          that
                                                                          they
                                                                          have
                                                                          been
                                                                          validly{" "}
                                                                          <bdt className="block-component"></bdt>
                                                                          authorized
                                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                                          to act
                                                                          on
                                                                          your
                                                                          behalf
                                                                          in
                                                                          accordance
                                                                          with
                                                                          applicable
                                                                          laws.
                                                                        </span>
                                                                        <br></br>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <strong>
                                                                          <span data-custom-class="heading_2">
                                                                            Request
                                                                            Verification
                                                                          </span>
                                                                        </strong>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <br></br>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span data-custom-class="body_text">
                                                                          Upon
                                                                          receiving
                                                                          your
                                                                          request,
                                                                          we
                                                                          will
                                                                          need
                                                                          to
                                                                          verify
                                                                          your
                                                                          identity
                                                                          to
                                                                          determine
                                                                          you
                                                                          are
                                                                          the
                                                                          same
                                                                          person
                                                                          about
                                                                          whom
                                                                          we
                                                                          have
                                                                          the
                                                                          information
                                                                          in our
                                                                          system.
                                                                          We
                                                                          will
                                                                          only
                                                                          use
                                                                          personal
                                                                          information
                                                                          provided
                                                                          in
                                                                          your
                                                                          request
                                                                          to
                                                                          verify
                                                                          your
                                                                          identity
                                                                          or
                                                                          authority
                                                                          to
                                                                          make
                                                                          the
                                                                          request.
                                                                          However,
                                                                          if we
                                                                          cannot
                                                                          verify
                                                                          your
                                                                          identity
                                                                          from
                                                                          the
                                                                          information
                                                                          already
                                                                          maintained
                                                                          by us,
                                                                          we may
                                                                          request
                                                                          that
                                                                          you
                                                                          provide
                                                                          additional
                                                                          information
                                                                          for
                                                                          the
                                                                          purposes
                                                                          of
                                                                          verifying
                                                                          your
                                                                          identity
                                                                          and
                                                                          for
                                                                          security
                                                                          or
                                                                          fraud-prevention
                                                                          purposes.
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <br></br>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span data-custom-class="body_text">
                                                                          If you
                                                                          submit
                                                                          the
                                                                          request
                                                                          through
                                                                          an{" "}
                                                                          <bdt className="block-component"></bdt>
                                                                          authorized
                                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                                          agent,
                                                                          we may
                                                                          need
                                                                          to
                                                                          collect
                                                                          additional
                                                                          information
                                                                          to
                                                                          verify
                                                                          your
                                                                          identity
                                                                          before
                                                                          processing
                                                                          your
                                                                          request
                                                                          and
                                                                          the
                                                                          agent
                                                                          will
                                                                          need
                                                                          to
                                                                          provide
                                                                          a
                                                                          written
                                                                          and
                                                                          signed
                                                                          permission
                                                                          from
                                                                          you to
                                                                          submit
                                                                          such
                                                                          request
                                                                          on
                                                                          your
                                                                          behalf.
                                                                        </span>
                                                                      </span>
                                                                      <bdt className="block-component">
                                                                        <span></span>
                                                                      </bdt>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <br></br>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span data-custom-class="heading_2">
                                                                          <strong>
                                                                            Appeals
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <br></br>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span data-custom-class="body_text">
                                                                          Under
                                                                          certain
                                                                          US
                                                                          state
                                                                          data
                                                                          protection
                                                                          laws,
                                                                          if we
                                                                          decline
                                                                          to
                                                                          take
                                                                          action
                                                                          regarding
                                                                          your
                                                                          request,
                                                                          you
                                                                          may
                                                                          appeal
                                                                          our
                                                                          decision
                                                                          by
                                                                          emailing
                                                                          us at{" "}
                                                                          <bdt className="block-component"></bdt>
                                                                          <bdt className="question">
                                                                            privacy@andrewscookin.com
                                                                          </bdt>
                                                                          <bdt className="else-block"></bdt>
                                                                          . We
                                                                          will
                                                                          inform
                                                                          you in
                                                                          writing
                                                                          of any
                                                                          action
                                                                          taken
                                                                          or not
                                                                          taken
                                                                          in
                                                                          response
                                                                          to the
                                                                          appeal,
                                                                          including
                                                                          a
                                                                          written
                                                                          explanation
                                                                          of the
                                                                          reasons
                                                                          for
                                                                          the
                                                                          decisions.
                                                                          If
                                                                          your
                                                                          appeal
                                                                          is
                                                                          denied,
                                                                          you
                                                                          may
                                                                          submit
                                                                          a
                                                                          complaint
                                                                          to
                                                                          your
                                                                          state
                                                                          attorney
                                                                          general.
                                                                        </span>
                                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                                      </span>
                                                                      <span>
                                                                        <span>
                                                                          <span>
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component">
                                                                                <bdt className="block-component"></bdt>
                                                                              </bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <bdt className="block-component">
                                                                        <span></span>
                                                                      </bdt>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <br></br>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <strong>
                                                                          <span data-custom-class="heading_2">
                                                                            California{" "}
                                                                            <bdt className="block-component"></bdt>
                                                                            "Shine
                                                                            The
                                                                            Light"
                                                                            <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                                            Law
                                                                          </span>
                                                                        </strong>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <br></br>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span data-custom-class="body_text">
                                                                          California
                                                                          Civil
                                                                          Code
                                                                          Section
                                                                          1798.83,
                                                                          also
                                                                          known
                                                                          as the{" "}
                                                                          <bdt className="block-component"></bdt>
                                                                          "Shine
                                                                          The
                                                                          Light"
                                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                                          law,
                                                                          permits
                                                                          our
                                                                          users
                                                                          who
                                                                          are
                                                                          California
                                                                          residents
                                                                          to
                                                                          request
                                                                          and
                                                                          obtain
                                                                          from
                                                                          us,
                                                                          once a
                                                                          year
                                                                          and
                                                                          free
                                                                          of
                                                                          charge,
                                                                          information
                                                                          about
                                                                          categories
                                                                          of
                                                                          personal
                                                                          information
                                                                          (if
                                                                          any)
                                                                          we
                                                                          disclosed
                                                                          to
                                                                          third
                                                                          parties
                                                                          for
                                                                          direct
                                                                          marketing
                                                                          purposes
                                                                          and
                                                                          the
                                                                          names
                                                                          and
                                                                          addresses
                                                                          of all
                                                                          third
                                                                          parties
                                                                          with
                                                                          which
                                                                          we
                                                                          shared
                                                                          personal
                                                                          information
                                                                          in the
                                                                          immediately
                                                                          preceding
                                                                          calendar
                                                                          year.
                                                                          If you
                                                                          are a
                                                                          California
                                                                          resident
                                                                          and
                                                                          would
                                                                          like
                                                                          to
                                                                          make
                                                                          such a
                                                                          request,
                                                                          please
                                                                          submit
                                                                          your
                                                                          request
                                                                          in
                                                                          writing
                                                                          to us
                                                                          by
                                                                          using
                                                                          the
                                                                          contact
                                                                          details
                                                                          provided
                                                                          in the
                                                                          section{" "}
                                                                          <bdt className="block-component"></bdt>
                                                                          "
                                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                                        </span>
                                                                      </span>
                                                                      <span data-custom-class="body_text">
                                                                        <a
                                                                          data-custom-class="link"
                                                                          href="#contact"
                                                                        >
                                                                          <span>
                                                                            HOW
                                                                            CAN
                                                                            YOU
                                                                            CONTACT
                                                                            US
                                                                            ABOUT
                                                                            THIS
                                                                            NOTICE?
                                                                          </span>
                                                                        </a>
                                                                      </span>
                                                                      <span>
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component"></bdt>
                                                                          "
                                                                        </span>
                                                                        <bdt className="statement-end-if-in-editor">
                                                                          <span data-custom-class="body_text"></span>
                                                                        </bdt>
                                                                      </span>
                                                                      <bdt className="statement-end-if-in-editor">
                                                                        <span></span>
                                                                      </bdt>
                                                                      <span>
                                                                        <span>
                                                                          <span>
                                                                            <span data-custom-class="body_text">
                                                                              <span>
                                                                                <span data-custom-class="body_text">
                                                                                  <span>
                                                                                    <span data-custom-class="body_text">
                                                                                      <span>
                                                                                        <span data-custom-class="body_text">
                                                                                          <bdt className="statement-end-if-in-editor">
                                                                                            <span data-custom-class="body_text">
                                                                                              <span>
                                                                                                <span>
                                                                                                  <span>
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span>
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <bdt className="statement-end-if-in-editor">
                                                                                                                    <bdt className="statement-end-if-in-editor"></bdt>
                                                                                                                  </bdt>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </bdt>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <bdt className="block-component">
                                                                        <span></span>
                                                                      </bdt>
                                                                      <bdt className="block-component">
                                                                        <span></span>
                                                                      </bdt>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div id="policyupdates">
                                                                      <span>
                                                                        <span>
                                                                          <span>
                                                                            <span>
                                                                              <span id="control">
                                                                                <strong>
                                                                                  <span data-custom-class="heading_1">
                                                                                    15.
                                                                                    DO
                                                                                    WE
                                                                                    MAKE
                                                                                    UPDATES
                                                                                    TO
                                                                                    THIS
                                                                                    NOTICE?
                                                                                  </span>
                                                                                </strong>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <em>
                                                                              <strong>
                                                                                In
                                                                                Short:&nbsp;
                                                                              </strong>
                                                                              Yes,
                                                                              we
                                                                              will
                                                                              update
                                                                              this
                                                                              notice
                                                                              as
                                                                              necessary
                                                                              to
                                                                              stay
                                                                              compliant
                                                                              with
                                                                              relevant
                                                                              laws.
                                                                            </em>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            We
                                                                            may
                                                                            update
                                                                            this
                                                                            privacy
                                                                            notice
                                                                            from
                                                                            time
                                                                            to
                                                                            time.
                                                                            The
                                                                            updated
                                                                            version
                                                                            will
                                                                            be
                                                                            indicated
                                                                            by
                                                                            an
                                                                            updated{" "}
                                                                            <bdt className="block-component"></bdt>
                                                                            "Revised"
                                                                            <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                                            date
                                                                            at
                                                                            the
                                                                            top
                                                                            of
                                                                            this
                                                                            privacy
                                                                            notice.
                                                                            If
                                                                            we
                                                                            make
                                                                            material
                                                                            changes
                                                                            to
                                                                            this
                                                                            privacy
                                                                            notice,
                                                                            we
                                                                            may
                                                                            notify
                                                                            you
                                                                            either
                                                                            by
                                                                            prominently
                                                                            posting
                                                                            a
                                                                            notice
                                                                            of
                                                                            such
                                                                            changes
                                                                            or
                                                                            by
                                                                            directly
                                                                            sending
                                                                            you
                                                                            a
                                                                            notification.
                                                                            We
                                                                            encourage
                                                                            you
                                                                            to
                                                                            review
                                                                            this
                                                                            privacy
                                                                            notice
                                                                            frequently
                                                                            to
                                                                            be
                                                                            informed
                                                                            of
                                                                            how
                                                                            we
                                                                            are
                                                                            protecting
                                                                            your
                                                                            information.
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div id="contact">
                                                                      <span>
                                                                        <span>
                                                                          <span>
                                                                            <span>
                                                                              <span id="control">
                                                                                <strong>
                                                                                  <span data-custom-class="heading_1">
                                                                                    16.
                                                                                    HOW
                                                                                    CAN
                                                                                    YOU
                                                                                    CONTACT
                                                                                    US
                                                                                    ABOUT
                                                                                    THIS
                                                                                    NOTICE?
                                                                                  </span>
                                                                                </strong>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            If
                                                                            you
                                                                            have
                                                                            questions
                                                                            or
                                                                            comments
                                                                            about
                                                                            this
                                                                            notice,
                                                                            you
                                                                            may{" "}
                                                                            <span>
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="block-component">
                                                                                  <bdt className="block-component"></bdt>
                                                                                </bdt>
                                                                                email
                                                                                us
                                                                                at{" "}
                                                                                <bdt className="question">
                                                                                  info@andrewscookin.com
                                                                                  or&nbsp;
                                                                                </bdt>
                                                                                <bdt className="statement-end-if-in-editor">
                                                                                  <bdt className="block-component"></bdt>
                                                                                </bdt>
                                                                              </span>
                                                                            </span>
                                                                            <span>
                                                                              <span>
                                                                                <span data-custom-class="body_text">
                                                                                  contact
                                                                                  us
                                                                                  by
                                                                                  post
                                                                                  at:
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <span>
                                                                              <span>
                                                                                <span>
                                                                                  <span data-custom-class="body_text">
                                                                                    <bdt className="question">
                                                                                      Andrew's Cookin'
                                                                                    </bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          <span data-custom-class="body_text">
                                                                            <span>
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="block-component"></bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="question">
                                                                            Taikos
                                                                            pr.97
                                                                          </bdt>
                                                                          <span>
                                                                            <span>
                                                                              <bdt className="block-component"></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="question">
                                                                            Kaunas
                                                                          </bdt>
                                                                          <span>
                                                                            <span>
                                                                              <bdt className="block-component"></bdt>
                                                                              ,{" "}
                                                                              <bdt className="question">
                                                                                Kaunas
                                                                                citty
                                                                              </bdt>
                                                                              <bdt className="statement-end-if-in-editor"></bdt>
                                                                              <bdt className="block-component"></bdt>{" "}
                                                                              <bdt className="question">
                                                                                50450
                                                                              </bdt>
                                                                              <bdt className="statement-end-if-in-editor"></bdt>
                                                                              <bdt className="block-component"></bdt>
                                                                              <bdt className="block-component"></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span data-custom-class="body_text">
                                                                          <span>
                                                                            <span data-custom-class="body_text">
                                                                              <span>
                                                                                <bdt className="block-component"></bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          <bdt className="question">
                                                                            Lithuania
                                                                          </bdt>
                                                                          <span>
                                                                            <span data-custom-class="body_text">
                                                                              <span>
                                                                                <bdt className="statement-end-if-in-editor">
                                                                                  <span>
                                                                                    <span data-custom-class="body_text">
                                                                                      <span>
                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                          <span>
                                                                                            <span data-custom-class="body_text">
                                                                                              <span>
                                                                                                <bdt className="statement-end-if-in-editor"></bdt>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </bdt>
                                                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                  <span data-custom-class="body_text">
                                                                                    <span>
                                                                                      <span>
                                                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <span data-custom-class="body_text">
                                                                          <span>
                                                                            <span data-custom-class="body_text">
                                                                              <span>
                                                                                <bdt className="statement-end-if-in-editor">
                                                                                  <span>
                                                                                    <span>
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="block-component">
                                                                                          <bdt className="block-component"></bdt>
                                                                                        </bdt>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          <bdt className="block-component">
                                                                            <span></span>
                                                                          </bdt>
                                                                          <span>
                                                                            <span data-custom-class="body_text">
                                                                              <span>
                                                                                <span>
                                                                                  <span data-custom-class="body_text">
                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                      <bdt className="block-component"></bdt>
                                                                                    </bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div id="request">
                                                                      <span>
                                                                        <span>
                                                                          <span>
                                                                            <span>
                                                                              <span id="control">
                                                                                <strong>
                                                                                  <span data-custom-class="heading_1">
                                                                                    17.
                                                                                    HOW
                                                                                    CAN
                                                                                    YOU
                                                                                    REVIEW,
                                                                                    UPDATE,
                                                                                    OR
                                                                                    DELETE
                                                                                    THE
                                                                                    DATA
                                                                                    WE
                                                                                    COLLECT
                                                                                    FROM
                                                                                    YOU?
                                                                                  </span>
                                                                                </strong>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <br></br>
                                                                    </div>
                                                                    <div>
                                                                      <span>
                                                                        <span>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component"></bdt>
                                                                            Based
                                                                            on
                                                                            the
                                                                            applicable
                                                                            laws
                                                                            of
                                                                            your
                                                                            country
                                                                            <bdt className="block-component"></bdt>{" "}
                                                                            or
                                                                            state
                                                                            of
                                                                            residence
                                                                            in
                                                                            the
                                                                            US
                                                                            <bdt className="statement-end-if-in-editor"></bdt>
                                                                            ,
                                                                            you
                                                                            may
                                                                            <bdt className="else-block">
                                                                              <bdt className="block-component">
                                                                                {" "}
                                                                                have
                                                                                the
                                                                                right
                                                                                to
                                                                                request
                                                                                access
                                                                                to
                                                                                the
                                                                                personal
                                                                                information
                                                                                we
                                                                                collect
                                                                                from
                                                                                you,
                                                                                details
                                                                                about
                                                                                how
                                                                                we
                                                                                have
                                                                                processed
                                                                                it,
                                                                                correct
                                                                                inaccuracies,
                                                                                or
                                                                                delete
                                                                                your
                                                                                personal
                                                                                information.
                                                                                You
                                                                                may
                                                                                also
                                                                                have
                                                                                the
                                                                                right
                                                                                to
                                                                                withdraw
                                                                                your
                                                                                consent
                                                                                to
                                                                                our
                                                                                processing
                                                                                of
                                                                                your
                                                                                personal
                                                                                information.
                                                                                These
                                                                                rights
                                                                                may
                                                                                be
                                                                                limited
                                                                                in
                                                                                some
                                                                                circumstances
                                                                                by
                                                                                applicable
                                                                                law.
                                                                                To
                                                                                request
                                                                                to
                                                                                review,
                                                                                update,
                                                                                or
                                                                                delete
                                                                                your
                                                                                personal
                                                                                information,
                                                                                please{" "}
                                                                                <bdt className="block-component"></bdt>
                                                                                fill
                                                                                out
                                                                                and
                                                                                submit
                                                                                a&nbsp;
                                                                              </bdt>
                                                                            </bdt>
                                                                          </span>
                                                                          <span>
                                                                            <span data-custom-class="body_text">
                                                                              <span>
                                                                                <a
                                                                                  data-custom-class="link"
                                                                                  href="https://app.termly.io/notify/90f5534d-c7ed-436e-8e7e-ebcb009ec13a"
                                                                                  rel="noopener noreferrer"
                                                                                  target="_blank"
                                                                                >
                                                                                  data
                                                                                  subject
                                                                                  access
                                                                                  request
                                                                                </a>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          <bdt className="block-component">
                                                                            <span data-custom-class="body_text"></span>
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                      <span data-custom-class="body_text">
                                                                        .
                                                                      </span>
                                                                    </div>
                                                                  </div>{" "}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </Container>
    );
  }
}
export default Privacy;
