import "./App.css";
import RouterComponent from "./components/navbar/RouterComponent";
import React from "react";
import "./i18n";
import "./styles/style.scss";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_ID);

function App() {
  const { t } = useTranslation();

  return (
    <div className="App">
      <RouterComponent />
    </div>
  );
}

export default App;
