import React from "react";
import { Container } from "react-bootstrap";
import { Footer } from "../components/main/Footer";

class Terms extends React.Component {
  render() {
    return (
      <Container>
        <div>
          <div align="center">
            <div className="MsoNormal" data-custom-class="title">
              <bdt className="block-component">
                <span></span>
              </bdt>
              <bdt className="question">
                <strong>TERMS OF SERVICE</strong>
              </bdt>
              <bdt className="statement-end-if-in-editor"></bdt>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="subtitle">
              <strong>Last updated</strong>{" "}
              <bdt className="question">
                <strong>July 12, 2024</strong>
              </bdt>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div>
              <strong>
                <span data-custom-class="heading_1">
                  AGREEMENT TO OUR LEGAL TERMS
                </span>
              </strong>
            </div>
          </div>
          <div align="center">
            <div className="MsoNormal" id="agreement">
              <a name="_6aa3gkhykvst"></a>
            </div>
          </div>
          <div align="center">
            <br></br>
          </div>
          <div align="center">
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                We are{" "}
                <bdt
                  className="block-container question question-in-editor"
                  data-id="9d459c4e-c548-e5cb-7729-a118548965d2"
                  data-type="question"
                >
                 Andrew's Cookin'
                </bdt>
                <bdt className="block-component"></bdt> (
                <bdt className="block-component"></bdt>"<strong>Company</strong>
                ," "<strong>we</strong>," "<strong>us</strong>
                ," "<strong>our</strong>"
                <bdt className="statement-end-if-in-editor"></bdt>)
                <span>
                  <span>
                    <span>
                      <bdt className="question">
                        <bdt className="block-component"></bdt>
                      </bdt>
                      <span>
                        <bdt className="block-component"></bdt>
                      </span>
                      , a company registered in
                      <bdt className="block-component"></bdt>
                      <bdt className="block-component"></bdt>{" "}
                      <span>
                        <span>
                          <bdt className="question">Lithuania</bdt>
                          <bdt className="statement-end-if-in-editor">
                            <span>
                              <span>
                                <bdt className="statement-end-if-in-editor"></bdt>
                              </span>
                            </span>
                          </bdt>
                        </span>
                      </span>{" "}
                      at <bdt className="question">Taikos pr.97</bdt>
                      <bdt className="block-component"></bdt>
                    </span>
                  </span>
                  , <bdt className="question">Kaunas</bdt>
                </span>
              </span>
              <span>
                <span data-custom-class="body_text">
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <bdt className="block-component"></bdt>,{" "}
                        <bdt className="question">Kaunas citty</bdt>
                        <bdt className="statement-end-if-in-editor"></bdt>
                        <bdt className="block-component"></bdt>{" "}
                        <bdt className="question">50450</bdt>
                        <bdt className="statement-end-if-in-editor"></bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <bdt className="else-block"></bdt>
                </span>
              </span>
              <bdt className="statement-end-if-in-editor">.</bdt>
              <bdt className="block-component"></bdt> Our VAT number is{" "}
              <bdt className="question">6913371339.</bdt>
              <bdt className="statement-end-if-in-editor"></bdt>
            </div>
          </div>
          <div align="center">
            <br></br>
          </div>
          <div align="center">
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                We operate <bdt className="block-component"></bdt>the website{" "}
                <span>
                  <bdt className="question">
                    <a
                      href="http://www.andrewscookin.com"
                      target="_blank"
                      data-custom-class="link"
                    >
                      http://www.andrewscookin.com
                    </a>
                  </bdt>
                </span>{" "}
                (the <bdt className="block-component"></bdt>"
                <strong>Site</strong>"
                <bdt className="statement-end-if-in-editor"></bdt>)
                <bdt className="block-component"></bdt>,{" "}
                <bdt className="statement-end-if-in-editor"></bdt>
                <bdt className="block-component"></bdt>the mobile application{" "}
                <bdt className="question">andrewscookin</bdt> (the{" "}
                <bdt className="block-component"></bdt>"<strong>App</strong>"
                <bdt className="statement-end-if-in-editor"></bdt>)
                <bdt className="statement-end-if-in-editor"></bdt>, as well as
                any other related products and services that refer or link to
                these legal terms (the <bdt className="block-component"></bdt>"
                <strong>Legal Terms</strong>"
                <bdt className="statement-end-if-in-editor"></bdt>)
                (collectively, the <bdt className="block-component"></bdt>"
                <strong>Services</strong>"
                <bdt className="statement-end-if-in-editor"></bdt>).
                <bdt className="block-component"></bdt>
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                You can contact us by{" "}
                <bdt className="block-component">
                  email at <bdt className="question">info@andrewscookin.com</bdt>
                  <bdt className="block-component"></bdt> or by mail to{" "}
                  <bdt className="question">Taikos pr.97</bdt>
                  <bdt className="block-component"></bdt>,{" "}
                  <bdt className="question">Kaunas</bdt>
                  <bdt className="block-component"></bdt>,{" "}
                  <bdt className="question">Kaunas citty</bdt>
                  <bdt className="statement-end-if-in-editor"></bdt>
                  <bdt className="block-component"></bdt>{" "}
                  <bdt className="question">50450</bdt>
                  <bdt className="statement-end-if-in-editor"></bdt>
                  <bdt className="block-component">
                    <bdt className="block-component">,&nbsp;</bdt>
                    <bdt className="question">Lithuania</bdt>
                    <bdt className="statement-end-if-in-editor"></bdt>
                  </bdt>
                  .
                </bdt>
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                These Legal Terms constitute a legally binding agreement made
                between you, whether personally or on behalf of an entity (
                <bdt className="block-component"></bdt>"<strong>you</strong>"
                <bdt className="statement-end-if-in-editor"></bdt>), and{" "}
                <bdt className="question">Andrew's Cookin'</bdt>, concerning your access
                to and use of the Services. You agree that by accessing the
                Services, you have read, understood, and agreed to be bound by
                all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE
                LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
                SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                <bdt className="block-component"></bdt>
                <bdt className="block-component"></bdt>
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                We will provide you with prior notice of any scheduled changes
                to the Services you are using. The modified Legal Terms will
                become effective upon posting or notifying you by{" "}
                <bdt className="question">info@andrewscookin.com</bdt>, as stated in the
                email message. By continuing to use the Services after the
                effective date of any changes, you agree to be bound by the
                modified terms.
                <bdt className="block-component"></bdt>
                <bdt className="statement-end-if-in-editor"></bdt>
              </span>
            </div>
          </div>
          <div align="center">
            <br></br>
          </div>
          <div align="center">
            <div className="MsoNormal" data-custom-class="body_text">
              <bdt
                className="block-container if"
                data-type="if"
                id="a2595956-7028-dbe5-123e-d3d3a93ed076"
              >
                <bdt data-type="conditional-block">
                  <bdt data-type="body">
                    <span>
                      <bdt className="block-component"></bdt>
                      <bdt
                        className="block-container if"
                        data-type="if"
                        id="a2595956-7028-dbe5-123e-d3d3a93ed076"
                      >
                        <bdt data-type="conditional-block">
                          <bdt data-type="body">
                            <span>
                              All users who are minors in the jurisdiction in
                              which they reside (generally under the age of 18)
                              must have the permission of, and be directly
                              supervised by, their parent or guardian to use the
                              Services. If you are a minor, you must have your
                              parent or guardian read and agree to these Legal
                              Terms prior to you using the Services.
                            </span>
                          </bdt>
                        </bdt>
                      </bdt>
                    </span>
                  </bdt>
                </bdt>
                <bdt
                  className="statement-end-if-in-editor"
                  data-type="close"
                ></bdt>
              </bdt>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              We recommend that you print a copy of these Legal Terms for your
              records.
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="heading_1">
              <strong>TABLE OF CONTENTS</strong>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal">
              <a href="#services">
                <span data-custom-class="link">
                  <span>
                    <span data-custom-class="body_text">1. OUR SERVICES</span>
                  </span>
                </span>
              </a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#ip">
                <span>
                  <span data-custom-class="body_text">
                    2. INTELLECTUAL PROPERTY RIGHTS
                  </span>
                </span>
              </a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#userreps"></a>
              <a data-custom-class="link" href="#userreps">
                <span>
                  <span data-custom-class="body_text">
                    3. USER REPRESENTATIONS
                  </span>
                </span>
              </a>
            </div>
            <div className="MsoNormal">
              <span>
                <span data-custom-class="body_text">
                  <bdt className="block-component"></bdt>
                </span>
              </span>
              <a data-custom-class="link" href="#userreg">
                <span>
                  <span data-custom-class="body_text">
                    4. USER REGISTRATION
                  </span>
                </span>
              </a>
              <span>
                <span data-custom-class="body_text">
                  <bdt className="statement-end-if-in-editor"></bdt>
                </span>
              </span>{" "}
              <a data-custom-class="link" href="#products"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#products">
                <span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component"></bdt>
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#purchases"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#purchases">
                <span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component"></bdt>5. PURCHASES AND
                    PAYMENT
                    <bdt className="statement-end-if-in-editor"></bdt>
                  </span>
                </span>
              </a>
            </div>
            <div className="MsoNormal">
              <bdt className="block-component">
                <span></span>
              </bdt>
              <a data-custom-class="link" href="#subscriptions">
                <span>
                  <span data-custom-class="body_text">6. SUBSCRIPTIONS</span>
                </span>
              </a>
              <bdt className="statement-end-if-in-editor">
                <span></span>
              </bdt>
            </div>
            <div className="MsoNormal">
              <span>
                <span data-custom-class="body_text">
                  <bdt className="block-component"></bdt>
                </span>
              </span>{" "}
              <a data-custom-class="link" href="#software"></a>{" "}
              <a data-custom-class="link" href="#software"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#software">
                <span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component"></bdt>
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#prohibited"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#prohibited">
                <span>
                  <span data-custom-class="body_text">
                    7. PROHIBITED ACTIVITIES
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#ugc"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#ugc">
                <span>
                  <span data-custom-class="body_text">
                    8. USER GENERATED CONTRIBUTIONS
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#license"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#license">
                <span>
                  <span data-custom-class="body_text">
                    9. CONTRIBUTION <bdt className="block-component"></bdt>
                    LICENSE
                    <bdt className="statement-end-if-in-editor"></bdt>
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#reviews"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#reviews">
                <span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component"></bdt>10. GUIDELINES FOR
                    REVIEWS
                    <bdt className="statement-end-if-in-editor"></bdt>
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#mobile"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#mobile">
                <span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component"></bdt>11. MOBILE
                    APPLICATION <bdt className="block-component"></bdt>LICENSE
                    <bdt className="statement-end-if-in-editor"></bdt>
                    <bdt className="statement-end-if-in-editor"></bdt>
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#socialmedia"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#socialmedia">
                <span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component"></bdt>
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#thirdparty"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#thirdparty">
                <span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component"></bdt>12. THIRD-PARTY
                    WEBSITES AND CONTENT
                    <bdt className="statement-end-if-in-editor"></bdt>
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#advertisers"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#advertisers">
                <span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component"></bdt>13. ADVERTISERS
                    <bdt className="statement-end-if-in-editor"></bdt>
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#sitemanage"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#sitemanage">
                <span>
                  <span data-custom-class="body_text">
                    14. SERVICES MANAGEMENT
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#ppyes"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#ppyes">
                <span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component"></bdt>
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#ppno"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#ppno">
                <span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component"></bdt>15. PRIVACY POLICY
                    <bdt className="statement-end-if-in-editor"></bdt>
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#dmca"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#dmca">
                <span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component"></bdt>
                    <bdt className="block-component"></bdt>
                    <bdt className="statement-end-if-in-editor"></bdt>
                  </span>
                </span>
              </a>
            </div>
            <div className="MsoNormal">
              <span>
                <span data-custom-class="body_text">
                  <bdt className="block-component"></bdt>
                  <bdt className="block-component"></bdt>
                </span>
              </span>
              <a data-custom-class="link" href="#copyrightyes">
                <span>
                  <span data-custom-class="body_text">
                    16. COPYRIGHT INFRINGEMENTS
                  </span>
                </span>
              </a>
              <span>
                <span data-custom-class="body_text">
                  <bdt className="statement-end-if-in-editor"></bdt>
                  <bdt className="block-component"></bdt>
                </span>
              </span>{" "}
              <a data-custom-class="link" href="#terms"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#terms">
                <span>
                  <span data-custom-class="body_text">
                    17. TERM AND TERMINATION
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#modifications"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#modifications">
                <span>
                  <span data-custom-class="body_text">
                    18. MODIFICATIONS AND INTERRUPTIONS
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#law"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#law">
                <span>
                  <span data-custom-class="body_text">19. GOVERNING LAW</span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#disputes"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#disputes">
                <span>
                  <span data-custom-class="body_text">
                    20. DISPUTE RESOLUTION
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#corrections"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#corrections">
                <span>
                  <span data-custom-class="body_text">21. CORRECTIONS</span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#disclaimer"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#disclaimer">
                <span>
                  <span data-custom-class="body_text">22. DISCLAIMER</span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#liability"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#liability">
                <span>
                  <span data-custom-class="body_text">
                    23. LIMITATIONS OF LIABILITY
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#indemnification"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#indemnification">
                <span>
                  <span data-custom-class="body_text">24. INDEMNIFICATION</span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#userdata"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#userdata">
                <span>
                  <span data-custom-class="body_text">25. USER DATA</span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#electronic"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#electronic">
                <span>
                  <span data-custom-class="body_text">
                    26. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#california"></a>
            </div>
            <div className="MsoNormal">
              <bdt className="block-component">
                <span></span>
              </bdt>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#california">
                <span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component"></bdt>27. CALIFORNIA USERS
                    AND RESIDENTS
                    <bdt className="statement-end-if-in-editor"></bdt>
                  </span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#misc"></a>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#misc">
                <span>
                  <span data-custom-class="body_text">28. MISCELLANEOUS</span>
                </span>
              </a>{" "}
              <a data-custom-class="link" href="#contact"></a>
            </div>
            <div className="MsoNormal">
              <bdt className="block-component"></bdt>
            </div>
            <div className="MsoNormal">
              <bdt className="block-component"></bdt>
            </div>
            <div className="MsoNormal">
              <bdt className="block-component"></bdt>
            </div>
            <div className="MsoNormal">
              <bdt className="block-component"></bdt>
            </div>
            <div className="MsoNormal">
              <bdt className="block-component"></bdt>
            </div>
            <div className="MsoNormal">
              <bdt className="block-component"></bdt>
            </div>
            <div className="MsoNormal">
              <bdt className="block-component"></bdt>
            </div>
            <div className="MsoNormal">
              <bdt className="block-component"></bdt>
            </div>
            <div className="MsoNormal">
              <bdt className="block-component"></bdt>
            </div>
            <div className="MsoNormal">
              <bdt className="block-component"></bdt>
            </div>
            <div className="MsoNormal">
              <a data-custom-class="link" href="#contact">
                <span>
                  <span data-custom-class="body_text">29. CONTACT US</span>
                </span>
              </a>
            </div>
          </div>
          <div align="center">
            <div className="MsoNormal" data-custom-class="heading_1">
              <a name="_b6y29mp52qvx"></a>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div
              className="MsoNormal"
              data-custom-class="heading_1"
              id="services"
            >
              <strong>
                <span>1. OUR SERVICES</span>
              </strong>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                The information provided when using the Services is not intended
                for distribution to or use by any person or entity in any
                jurisdiction or country where such distribution or use would be
                contrary to law or regulation or which would subject us to any
                registration requirement within such jurisdiction or country.
                Accordingly, those persons who choose to access the Services
                from other locations do so on their own initiative and are
                solely responsible for compliance with local laws, if and to the
                extent local laws are applicable.
                <bdt className="block-component"></bdt>
              </span>
              <bdt className="block-component">
                <span></span>
              </bdt>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                The Services are not tailored to comply with industry-specific
                regulations (Health Insurance Portability and Accountability Act
                (HIPAA), Federal Information Security Management Act (FISMA),
                etc.), so if your interactions would be subjected to such laws,
                you may not use the Services. You may not use the Services in a
                way that would violate the Gramm-Leach-Bliley Act (GLBA).
                <bdt className="block-component"></bdt>
                <bdt className="statement-end-if-in-editor"></bdt>
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
          </div>
          <div align="center" data-custom-class="heading_1">
            <strong>
              <span id="ip">2. INTELLECTUAL PROPERTY RIGHTS</span>
            </strong>
          </div>
          <div align="center">
            <br></br>
          </div>
          <div align="center">
            <div className="MsoNormal" data-custom-class="heading_2">
              <strong>Our intellectual property</strong>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                We are the owner or the licensee of all intellectual property
                rights in our Services, including all source code, databases,
                functionality, software, website designs, audio, video, text,
                photographs, and graphics in the Services (collectively, the{" "}
                <bdt className="block-component"></bdt>
                "Content"<bdt className="statement-end-if-in-editor"></bdt>), as
                well as the trademarks, service marks, and logos contained
                therein (the <bdt className="block-component"></bdt>"Marks"
                <bdt className="statement-end-if-in-editor"></bdt>).
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                Our Content and Marks are protected by copyright and trademark
                laws (and various other intellectual property rights and unfair
                competition laws) and treaties in the United States and around
                the world.
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                The Content and Marks are provided in or through the Services{" "}
                <bdt className="block-component"></bdt>"AS IS"
                <bdt className="statement-end-if-in-editor"></bdt> for your{" "}
                <bdt className="block-component"></bdt>personal, non-commercial
                use
                <bdt className="block-component"></bdt> only.
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="heading_2">
              <strong>Your use of our Services</strong>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                Subject to your compliance with these Legal Terms, including the{" "}
                <bdt className="block-component"></bdt>"
                <bdt className="statement-end-if-in-editor"></bdt>
              </span>
              <a data-custom-class="link" href="#prohibited">
                <span>PROHIBITED ACTIVITIES</span>
              </a>
              <span>
                <bdt className="block-component"></bdt>"
                <bdt className="statement-end-if-in-editor"></bdt> section
                below, we grant you a non-exclusive, non-transferable, revocable{" "}
                <bdt className="block-component"></bdt>license
                <bdt className="statement-end-if-in-editor"></bdt> to:
              </span>
            </div>
            <ul>
              <li className="MsoNormal" data-custom-class="body_text">
                <span>access the Services; and</span>
              </li>
              <li className="MsoNormal" data-custom-class="body_text">
                <span>
                  download or print a copy of any portion of the Content to
                  which you have properly gained access.
                </span>
              </li>
            </ul>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                solely for your <bdt className="block-component"></bdt>personal,
                non-commercial use<bdt className="block-component"></bdt>.
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                Except as set out in this section or elsewhere in our Legal
                Terms, no part of the Services and no Content or Marks may be
                copied, reproduced, aggregated, republished, uploaded, posted,
                publicly displayed, encoded, translated, transmitted,
                distributed, sold, licensed, or otherwise exploited for any
                commercial purpose whatsoever, without our express prior written
                permission.
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                If you wish to make any use of the Services, Content, or Marks
                other than as set out in this section or elsewhere in our Legal
                Terms, please address your request to:{" "}
                <bdt className="question">info@andrewscookin.com</bdt>. If we ever grant
                you the permission to post, reproduce, or publicly display any
                part of our Services or Content, you must identify us as the
                owners or licensors of the Services, Content, or Marks and
                ensure that any copyright or proprietary notice appears or is
                visible on posting, reproducing, or displaying our Content.
              </span>
            </div>
          </div>
          <div align="center">
            <br></br>
          </div>
          <div align="center">
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                We reserve all rights not expressly granted to you in and to the
                Services, Content, and Marks.
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                Any breach of these Intellectual Property Rights will constitute
                a material breach of our Legal Terms and your right to use our
                Services will terminate immediately.
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="heading_2">
              <span>
                <strong>
                  Your submissions<bdt className="block-component"></bdt> and
                  contributions
                </strong>
                <bdt className="statement-end-if-in-editor">
                  <strong></strong>
                </bdt>
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                Please review this section and the{" "}
                <bdt className="block-component"></bdt>"
                <bdt className="statement-end-if-in-editor"></bdt>
                <a data-custom-class="link" href="#prohibited">
                  <span>PROHIBITED ACTIVITIES</span>
                </a>
                <bdt className="block-component"></bdt>"
                <bdt className="statement-end-if-in-editor"></bdt> section
                carefully prior to using our Services to understand the (a)
                rights you give us and (b) obligations you have when you post or
                upload any content through the Services.
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                <strong>Submissions:</strong> By directly sending us any
                question, comment, suggestion, idea, feedback, or other
                information about the Services (
                <bdt className="block-component"></bdt>"Submissions"
                <bdt className="statement-end-if-in-editor"></bdt>), you agree
                to assign to us all intellectual property rights in such
                Submission. You agree that we shall own this Submission and be
                entitled to its unrestricted use and dissemination for any
                lawful purpose, commercial or otherwise, without acknowledgment
                or compensation to you.
                <bdt className="block-component"></bdt>
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                <strong>Contributions:</strong> The Services may invite you to
                chat, contribute to, or participate in blogs, message boards,
                online forums, and other functionality during which you may
                create, submit, post, display, transmit, publish, distribute, or
                broadcast content and materials to us or through the Services,
                including but not limited to text, writings, video, audio,
                photographs, music, graphics, comments, reviews, rating
                suggestions, personal information, or other material (
                <bdt className="block-component"></bdt>"Contributions"
                <bdt className="statement-end-if-in-editor"></bdt>). Any
                Submission that is publicly posted shall also be treated as a
                Contribution.
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                You understand that Contributions may be viewable by other users
                of the Services<bdt className="block-component"></bdt> and
                possibly through third-party websites
                <bdt className="statement-end-if-in-editor"></bdt>.
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                <strong>
                  When you post Contributions, you grant us a{" "}
                  <bdt className="block-component"></bdt>license
                  <bdt className="statement-end-if-in-editor"></bdt> (including
                  use of your name, trademarks, and logos):&nbsp;
                </strong>
                By posting any Contributions, you grant us an unrestricted,
                unlimited, irrevocable, perpetual, non-exclusive, transferable,
                royalty-free, fully-paid, worldwide right, and{" "}
                <bdt className="block-component"></bdt>
                license<bdt className="statement-end-if-in-editor"></bdt> to:
                use, copy, reproduce, distribute, sell, resell, publish,
                broadcast, retitle, store, publicly perform, publicly display,
                reformat, translate, excerpt (in whole or in part), and exploit
                your Contributions (including, without limitation, your image,
                name, and voice) for any purpose, commercial, advertising, or
                otherwise, to prepare derivative works of, or incorporate into
                other works, your Contributions, and to{" "}
                <bdt className="block-component"></bdt>
                sublicense the licenses
                <bdt className="statement-end-if-in-editor"></bdt> granted in
                this section. Our use and distribution may occur in any media
                formats and through any media channels.
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                This <bdt className="block-component"></bdt>license
                <bdt className="statement-end-if-in-editor"></bdt> includes our
                use of your name, company name, and franchise name, as
                applicable, and any of the trademarks, service marks, trade
                names, logos, and personal and commercial images you provide.
                <bdt className="statement-end-if-in-editor"></bdt>
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                <strong>
                  You are responsible for what you post or upload:
                </strong>{" "}
                By sending us Submissions<bdt className="block-component"></bdt>{" "}
                and/or posting Contributions
                <bdt className="statement-end-if-in-editor"></bdt> through any
                part of the Services<bdt className="block-component"></bdt> or
                making Contributions accessible through the Services by linking
                your account through the Services to any of your social
                networking accounts,
                <bdt className="statement-end-if-in-editor"></bdt> you:
              </span>
            </div>
            <ul>
              <li className="MsoNormal" data-custom-class="body_text">
                <span>
                  confirm that you have read and agree with our{" "}
                  <bdt className="block-component"></bdt>"
                  <bdt className="statement-end-if-in-editor"></bdt>
                </span>
                <a data-custom-class="link" href="#prohibited">
                  <span>PROHIBITED ACTIVITIES</span>
                </a>
                <span>
                  <bdt className="block-component"></bdt>"
                  <bdt className="statement-end-if-in-editor"></bdt> and will
                  not post, send, publish, upload, or transmit through the
                  Services any Submission
                  <bdt className="block-component"></bdt> nor post any
                  Contribution
                  <bdt className="statement-end-if-in-editor"></bdt> that is
                  illegal, harassing, hateful, harmful, defamatory, obscene,
                  bullying, abusive, discriminatory, threatening to any person
                  or group, sexually explicit, false, inaccurate, deceitful, or
                  misleading;
                </span>
              </li>
              <li className="MsoNormal" data-custom-class="body_text">
                <span>
                  to the extent permissible by applicable law, waive any and all
                  moral rights to any such Submission
                  <bdt className="block-component"></bdt> and/or Contribution
                  <bdt className="statement-end-if-in-editor"></bdt>;
                </span>
              </li>
              <li className="MsoNormal" data-custom-class="body_text">
                <span>
                  warrant that any such Submission
                  <bdt className="block-component"></bdt> and/or Contributions
                  <bdt className="statement-end-if-in-editor"></bdt> are
                  original to you or that you have the necessary rights and{" "}
                  <bdt className="block-component"></bdt>licenses
                  <bdt className="statement-end-if-in-editor"></bdt> to submit
                  such Submissions<bdt className="block-component"></bdt> and/or
                  Contributions
                  <bdt className="statement-end-if-in-editor"></bdt> and that
                  you have full authority to grant us the above-mentioned rights
                  in relation to your Submissions
                  <bdt className="block-component"></bdt> and/or Contributions
                  <bdt className="statement-end-if-in-editor"></bdt>; and
                </span>
              </li>
              <li className="MsoNormal" data-custom-class="body_text">
                <span>
                  warrant and represent that your Submissions
                  <bdt className="block-component"></bdt> and/or Contributions
                  <bdt className="statement-end-if-in-editor"></bdt> do not
                  constitute confidential information.
                </span>
              </li>
            </ul>
            <div className="MsoNormal" data-custom-class="body_text">
              You are solely responsible for your Submissions
              <bdt className="block-component"></bdt> and/or Contributions
              <bdt className="statement-end-if-in-editor"></bdt> and you
              expressly agree to reimburse us for any and all losses that we may
              suffer because of your breach of (a) this section, (b) any third
              party’s intellectual property rights, or (c) applicable law.
              <bdt className="block-component"></bdt>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <strong>We may remove or edit your Content:</strong> Although we
              have no obligation to monitor any Contributions, we shall have the
              right to remove or edit any Contributions at any time without
              notice if in our reasonable opinion we consider such Contributions
              harmful or in breach of these Legal Terms. If we remove or edit
              any such Contributions, we may also suspend or disable your
              account and report you to the authorities.
              <bdt className="statement-end-if-in-editor"></bdt>
              <bdt className="block-component"></bdt>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="heading_2">
              <strong>Copyright infringement</strong>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              We respect the intellectual property rights of others. If you
              believe that any material available on or through the Services
              infringes upon any copyright you own or control, please
              immediately refer to the <bdt className="block-component"></bdt>"
              <bdt className="statement-end-if-in-editor"></bdt>
              <a data-custom-class="link" href="#dmca">
                <span>
                  <bdt className="block-component"></bdt>
                  <bdt className="block-component"></bdt>
                </span>
                <bdt className="statement-end-if-in-editor"></bdt>
              </a>
              <bdt className="block-component"></bdt>
              <a data-custom-class="link" href="#copyrightyes">
                <span>
                  <bdt className="block-component"></bdt>COPYRIGHT INFRINGEMENTS
                  <bdt className="statement-end-if-in-editor"></bdt>
                </span>
              </a>
              <bdt className="block-component"></bdt>
              <bdt className="block-component"></bdt>"
              <bdt className="statement-end-if-in-editor"></bdt> section below.
              <bdt className="statement-end-if-in-editor"></bdt>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
          </div>
          <div align="center">
            <div
              className="MsoNormal"
              data-custom-class="heading_1"
              id="userreps"
            >
              <a name="_5hg7kgyv9l8z"></a>
              <strong>
                <span>
                  <strong>
                    <span>
                      <strong>
                        <span>
                          <strong>
                            <span>3.</span>
                          </strong>
                        </span>
                        &nbsp;
                      </strong>
                    </span>
                  </strong>
                  USER REPRESENTATIONS
                </span>
              </strong>
            </div>
          </div>
          <div align="center">
            <br></br>
          </div>
          <div align="center">
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                By using the Services, you represent and warrant that:
              </span>
              <bdt
                className="block-container if"
                data-type="if"
                id="d2d82ca8-275f-3f86-8149-8a5ef8054af6"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="user_account_option"
                    data-type="statement"
                  ></bdt>{" "}
                  <bdt data-type="body">
                    <span>(</span>
                    <span>1</span>
                    <span>
                      ) all registration information you submit will be true,
                      accurate, current, and complete; (
                    </span>
                    <span>2</span>
                    <span>
                      ) you will maintain the accuracy of such information and
                      promptly update such registration information as
                      necessary;
                    </span>
                  </bdt>
                </bdt>
                <bdt
                  className="statement-end-if-in-editor"
                  data-type="close"
                ></bdt>
                &nbsp;
              </bdt>
              <span>(</span>
              <span>3</span>
              <span>
                ) you have the legal capacity and you agree to comply with these
                Legal Terms;
              </span>
              <bdt
                className="block-container if"
                data-type="if"
                id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="user_u13_option"
                    data-type="statement"
                  ></bdt>
                  &nbsp;
                </bdt>
                <span>(</span>
                <span>4</span>
                <span>
                  ) you are not a minor in the jurisdiction in which you reside
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="76948fab-ec9e-266a-bb91-948929c050c9"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="user_o18_option"
                        data-type="statement"
                      ></bdt>
                      <bdt data-type="body">
                        , or if a minor, you have received parental permission
                        to use the Services
                      </bdt>
                    </bdt>
                    <bdt
                      className="statement-end-if-in-editor"
                      data-type="close"
                    ></bdt>
                  </bdt>
                  ; (
                </span>
                <span>5</span>
                <span>
                  ) you will not access the Services through automated or
                  non-human means, whether through a bot, script or otherwise; (
                </span>
                <span>6</span>
                <span>
                  ) you will not use the Services for any illegal or{" "}
                  <bdt className="block-component"></bdt>unauthorized
                  <bdt className="statement-end-if-in-editor"></bdt> purpose;
                  and (
                </span>
                <span>7</span>
                <span>
                  ) your use of the Services will not violate any applicable law
                  or regulation.
                </span>
                <span></span>
              </bdt>
            </div>
          </div>
          <div align="center">
            <br></br>
          </div>
          <div align="center">
            <div className="MsoNormal">
              <div className="MsoNormal">
                <div className="MsoNormal" data-custom-class="body_text">
                  <span>
                    If you provide any information that is untrue, inaccurate,
                    not current, or incomplete, we have the right to suspend or
                    terminate your account and refuse any and all current or
                    future use of the Services (or any portion thereof).
                  </span>
                </div>
                <div className="MsoNormal">
                  <bdt className="block-component"></bdt>
                </div>
                <div className="MsoNormal">
                  <br></br>
                </div>
              </div>
              <div className="MsoNormal">
                <bdt data-type="conditional-block">
                  <bdt data-type="body">
                    <div
                      className="MsoNormal"
                      data-custom-class="heading_1"
                      id="userreg"
                    >
                      <strong>
                        <span>
                          <strong>
                            <span>
                              <strong>
                                <span>
                                  <strong>
                                    <span>4.</span>
                                  </strong>
                                </span>
                                &nbsp;
                              </strong>
                            </span>
                          </strong>
                          USER REGISTRATION
                        </span>
                      </strong>
                    </div>
                  </bdt>
                </bdt>
              </div>
              <div className="MsoNormal">
                <br></br>
              </div>
              <div className="MsoNormal">
                <bdt data-type="conditional-block">
                  <bdt data-type="body">
                    <div className="MsoNormal" data-custom-class="body_text">
                      <span>
                        You may be required to register to use the Services. You
                        agree to keep your password confidential and will be
                        responsible for all use of your account and password. We
                        reserve the right to remove, reclaim, or change a
                        username you select if we determine, in our sole
                        discretion, that such username is inappropriate,
                        obscene, or otherwise objectionable.
                        <bdt
                          className="statement-end-if-in-editor"
                          data-type="close"
                        ></bdt>
                      </span>
                    </div>
                  </bdt>
                </bdt>
              </div>
              <div className="MsoNormal">
                <bdt className="block-component">
                  <span></span>
                </bdt>
              </div>
              <div className="MsoNormal">
                <bdt className="block-component">
                  <span></span>
                </bdt>
              </div>
              <div className="MsoNormal">
                <br></br>
              </div>
            </div>
          </div>
          <div align="center">
            <div
              className="MsoNormal"
              data-custom-class="heading_1"
              id="purchases"
            >
              <a name="_ynub0jdx8pob"></a>
              <strong>
                <span>
                  <strong>
                    <span>
                      <strong>
                        <span>
                          <strong>
                            <span>5.</span>
                          </strong>
                        </span>
                      </strong>
                    </span>
                    &nbsp;
                  </strong>
                  PURCHASES AND PAYMENT
                </span>
              </strong>
            </div>
          </div>
          <div align="center">
            <br></br>
          </div>
          <div align="center">
            <div className="MsoNormal">
              <bdt className="block-component">
                <span></span>
              </bdt>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>We accept the following forms of payment:</span>
            </div>
            <div className="MsoNormal">
              <div className="MsoNormal">
                <br></br>
              </div>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                <bdt className="forloop-component"></bdt>- &nbsp;
                <bdt className="question">Visa</bdt>
              </span>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                <bdt className="forloop-component"></bdt>- &nbsp;
                <bdt className="question">Mastercard</bdt>
              </span>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                <bdt className="forloop-component"></bdt>- &nbsp;
                <bdt className="question">PayPal</bdt>
              </span>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                <bdt className="forloop-component"></bdt>- &nbsp;
                <bdt className="question">American Express</bdt>
              </span>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                <bdt className="forloop-component"></bdt>- &nbsp;
                <bdt className="question">Paysera</bdt>
              </span>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                <bdt className="forloop-component"></bdt>
              </span>
            </div>
            <div className="MsoNormal">
              <span>
                <br></br>
              </span>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                You agree to provide current, complete, and accurate purchase
                and account information for all purchases made via the Services.
                You further agree to promptly update account and payment
                information, including email address, payment method, and
                payment card expiration date, so that we can complete your
                transactions and contact you as needed. Sales tax will be added
                to the price of purchases as deemed required by us. We may
                change prices at any time. All payments shall be&nbsp;
              </span>
              <span>
                in <bdt className="question">US dollars</bdt>.
              </span>
            </div>
          </div>
          <div align="center">
            <br></br>
          </div>
          <div align="center">
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                You agree to pay all charges at the prices then in effect for
                your purchases and any applicable shipping fees, and you{" "}
                <bdt className="block-component"></bdt>authorize
                <bdt className="statement-end-if-in-editor"></bdt> us to charge
                your chosen payment provider for any such amounts upon placing
                your order. We reserve the right to correct any errors or
                mistakes in pricing, even if we have already requested or
                received payment.
              </span>
            </div>
          </div>
          <div align="center">
            <br></br>
          </div>
          <div align="center">
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                We reserve the right to refuse any order placed through the
                Services. We may, in our sole discretion, limit or cancel
                quantities purchased per person, per household, or per order.
                These restrictions may include orders placed by or under the
                same customer account, the same payment method, and/or orders
                that use the same billing or shipping address. We reserve the
                right to limit or prohibit orders that, in our sole{" "}
                <bdt className="block-component"></bdt>judgment
                <bdt className="statement-end-if-in-editor"></bdt>, appear to be
                placed by dealers, resellers, or distributors.
              </span>
              <span>
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="return_option"
                    data-type="statement"
                  ></bdt>
                </bdt>
              </span>
            </div>
            <div className="MsoNormal">
              <bdt className="block-component">
                <span></span>
              </bdt>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div
              className="MsoNormal"
              data-custom-class="heading_1"
              id="subscriptions"
            >
              <strong>
                <span>6. SUBSCRIPTIONS</span>
              </strong>
            </div>
            <div className="MsoNormal">
              <bdt className="block-component"></bdt>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="heading_2">
              <strong>
                <span>Billing and Renewal</span>
              </strong>
            </div>
            <div className="MsoNormal">
              <span>
                <br></br>
              </span>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                <bdt className="block-component"></bdt>Your subscription will
                continue and automatically renew unless{" "}
                <bdt className="block-component"></bdt>
                canceled
                <bdt className="statement-end-if-in-editor"></bdt>. You consent
                to our charging your payment method on a recurring basis without
                requiring your prior approval for each recurring charge, until
                such time as you cancel the applicable order.
                <bdt className="block-component"></bdt> The length of your
                billing cycle <bdt className="block-component"></bdt>is monthly
                <bdt className="block-component"></bdt>.
                <bdt className="statement-end-if-in-editor"></bdt>
                <bdt className="else-block"></bdt>
              </span>
            </div>
            <div className="MsoNormal">
              <bdt className="block-component">
                <span></span>
              </bdt>
            </div>
            <div className="MsoNormal">
              <span>
                <br></br>
              </span>
            </div>
            <div className="MsoNormal" data-custom-class="heading_2">
              <span>
                <strong>Free Trial</strong>
              </span>
            </div>
            <div className="MsoNormal">
              <span>
                <br></br>
              </span>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                We offer a <bdt className="question">14</bdt>-day free trial to
                new users who register with the Services.{" "}
                <bdt className="block-component"></bdt>
                <bdt className="question">
                  The account will not be charged and the subscription will be
                  suspended until upgraded to a paid version
                </bdt>{" "}
                at the end of the free trial.<bdt className="else-block"></bdt>
              </span>
            </div>
            <div className="MsoNormal">
              <span>
                <bdt className="statement-end-if-in-editor"></bdt>
              </span>
              <bdt className="block-component">
                <span></span>
              </bdt>
            </div>
            <div className="MsoNormal">
              <span>
                <br></br>
              </span>
            </div>
            <div className="MsoNormal" data-custom-class="heading_2">
              <span>
                <strong>Cancellation</strong>
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <bdt className="block-component">
                <span></span>
              </bdt>
              <span>
                All purchases are non-refundable.{" "}
                <bdt className="statement-end-if-in-editor"></bdt>
                <bdt className="block-component"></bdt>You can cancel your
                subscription at any time by logging into your account.
                <bdt className="block-component"></bdt> Your cancellation will
                take effect at the end of the current paid term. If you have any
                questions or are unsatisfied with our Services, please email us
                at <bdt className="question">info@andrewscookin.com</bdt>.
                <bdt className="statement-end-if-in-editor"></bdt>
                <br></br>
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="heading_2">
              <strong>
                <span>Fee Changes</span>
              </strong>
            </div>
            <div className="MsoNormal">
              <span>
                <br></br>
              </span>
            </div>
            <span>
              <span data-custom-class="body_text">
                We may, from time to time, make changes to the subscription fee
                and will communicate any price changes to you in accordance with
                applicable law.
              </span>
            </span>
            <div className="MsoNormal">
              <span>
                <bdt className="statement-end-if-in-editor"></bdt>
              </span>
              <bdt className="block-component">
                <span></span>
              </bdt>
            </div>
            <div className="MsoNormal">
              <bdt className="block-component"></bdt>
            </div>
            <div className="MsoNormal">
              <span>
                <bdt data-type="conditional-block">
                  <bdt data-type="body">
                    <div className="MsoNormal">
                      <br></br>
                    </div>
                  </bdt>
                </bdt>
              </span>
              <div
                className="MsoNormal"
                data-custom-class="heading_1"
                id="prohibited"
              >
                <strong>
                  <span>
                    <strong>
                      <span>
                        <strong>
                          <span>
                            <strong>
                              <span>7.</span>
                            </strong>
                          </span>
                        </strong>
                      </span>
                      &nbsp;
                    </strong>
                    PROHIBITED ACTIVITIES
                  </span>
                </strong>
              </div>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal">
              <div className="MsoNormal" data-custom-class="body_text">
                <span>
                  You may not access or use the Services for any purpose other
                  than that for which we make the Services available. The
                  Services may not be used in connection with any commercial{" "}
                  <bdt className="block-component"></bdt>
                  endeavors<bdt className="statement-end-if-in-editor"></bdt>{" "}
                  except those that are specifically endorsed or approved by us.
                </span>
              </div>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal">
              <div className="MsoNormal">
                <div className="MsoNormal">
                  <div className="MsoNormal">
                    <div className="MsoNormal" data-custom-class="body_text">
                      <span>As a user of the Services, you agree not to:</span>
                    </div>
                  </div>
                  <ul>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          Systematically retrieve data or other content from the
                          Services to create or compile, directly or indirectly,
                          a collection, compilation, database, or directory
                          without written permission from us.
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Trick, defraud, or mislead us and other users,
                                especially in any attempt to learn sensitive
                                account information such as user passwords.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Circumvent, disable, or otherwise interfere with
                                security-related features of the Services,
                                including features that prevent or restrict the
                                use or copying of any Content or enforce
                                limitations on the use of the Services and/or
                                the Content contained therein.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Disparage, tarnish, or otherwise harm, in our
                                opinion, us and/or the Services.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Use any information obtained from the Services
                                in order to harass, abuse, or harm another
                                person.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Make improper use of our support services or
                                submit false reports of abuse or misconduct.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Use the Services in a manner inconsistent with
                                any applicable laws or regulations.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Engage in{" "}
                                <bdt className="block-component"></bdt>
                                unauthorized
                                <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                framing of or linking to the Services.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Upload or transmit (or attempt to upload or to
                                transmit) viruses, Trojan horses, or other
                                material, including excessive use of capital
                                letters and spamming (continuous posting of
                                repetitive text), that interferes with any
                                party’s uninterrupted use and enjoyment of the
                                Services or modifies, impairs, disrupts, alters,
                                or interferes with the use, features, functions,
                                operation, or maintenance of the Services.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Engage in any automated use of the system, such
                                as using scripts to send comments or messages,
                                or using any data mining, robots, or similar
                                data gathering and extraction tools.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Delete the copyright or other proprietary rights
                                notice from any Content.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Attempt to impersonate another user or person or
                                use the username of another user.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Upload or transmit (or attempt to upload or to
                                transmit) any material that acts as a passive or
                                active information collection or transmission
                                mechanism, including without limitation, clear
                                graphics interchange formats (
                                <bdt className="block-component"></bdt>"gifs"
                                <bdt className="statement-end-if-in-editor"></bdt>
                                ), 1×1 pixels, web bugs, cookies, or other
                                similar devices (sometimes referred to as{" "}
                                <bdt className="block-component"></bdt>"spyware"
                                or "passive collection mechanisms" or "pcms"
                                <bdt className="statement-end-if-in-editor"></bdt>
                                ).
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Interfere with, disrupt, or create an undue
                                burden on the Services or the networks or
                                services connected to the Services.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Harass, annoy, intimidate, or threaten any of
                                our employees or agents engaged in providing any
                                portion of the Services to you.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Attempt to bypass any measures of the Services
                                designed to prevent or restrict access to the
                                Services, or any portion of the Services.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Copy or adapt the Services' software, including
                                but not limited to Flash, PHP, HTML, JavaScript,
                                or other code.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Except as permitted by applicable law, decipher,
                                decompile, disassemble, or reverse engineer any
                                of the software comprising or in any way making
                                up a part of the Services.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Except as may be the result of standard search
                                engine or Internet browser usage, use, launch,
                                develop, or distribute any automated system,
                                including without limitation, any spider, robot,
                                cheat utility, scraper, or offline reader that
                                accesses the Services, or use or launch any{" "}
                                <bdt className="block-component"></bdt>
                                unauthorized
                                <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                script or other software.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Use a buying agent or purchasing agent to make
                                purchases on the Services.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Make any <bdt className="block-component"></bdt>
                                unauthorized
                                <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                use of the Services, including collecting
                                usernames and/or email addresses of users by
                                electronic or other means for the purpose of
                                sending unsolicited email, or creating user
                                accounts by automated means or under false{" "}
                                <bdt className="block-component"></bdt>pretenses
                                <bdt className="statement-end-if-in-editor"></bdt>
                                .
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                Use the Services as part of any effort to
                                compete with us or otherwise use the Services
                                and/or the Content for any revenue-generating{" "}
                                <bdt className="block-component"></bdt>endeavor
                                <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                or commercial enterprise.
                              </span>
                              <span>
                                <bdt className="forloop-component"></bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                    <li className="MsoNormal" data-custom-class="body_text">
                      <span>
                        <bdt className="question">
                          Sell or otherwise transfer your profile.
                        </bdt>
                        <bdt className="forloop-component"></bdt>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="MsoNormal">
                  <br></br>
                </div>
              </div>
              <div className="MsoNormal">
                <div className="MsoNormal">
                  <bdt className="block-container if" data-type="if">
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_1"
                          id="ugc"
                        >
                          <strong>
                            <span>
                              <strong>
                                <span>
                                  <strong>
                                    <span>
                                      <strong>
                                        <span>8.</span>
                                      </strong>
                                    </span>
                                  </strong>
                                </span>
                                &nbsp;
                              </strong>
                              USER GENERATED CONTRIBUTIONS
                            </span>
                          </strong>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div className="MsoNormal">
                  <br></br>
                </div>
                <div className="MsoNormal">
                  <bdt className="block-container if" data-type="if">
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                        >
                          <span>
                            <bdt
                              className="block-container if"
                              data-type="if"
                              id="24327c5d-a34f-f7e7-88f1-65a2f788484f"
                            >
                              <bdt data-type="conditional-block">
                                <bdt
                                  className="block-component"
                                  data-record-question-key="user_post_content_option"
                                  data-type="statement"
                                ></bdt>
                              </bdt>
                            </bdt>
                            The Services may invite you to chat, contribute to,
                            or participate in blogs, message boards, online
                            forums, and other functionality, and may provide you
                            with the opportunity to create, submit, post,
                            display, transmit, perform, publish, distribute, or
                            broadcast content and materials to us or on the
                            Services, including but not limited to text,
                            writings, video, audio, photographs, graphics,
                            comments, suggestions, or personal information or
                            other material (collectively,{" "}
                            <bdt className="block-component"></bdt>
                            "Contributions"
                            <bdt className="statement-end-if-in-editor"></bdt>).
                            Contributions may be viewable by other users of the
                            Services and through third-party websites. As such,
                            any Contributions you transmit may be treated as
                            non-confidential and non-proprietary. When you
                            create or make available any Contributions, you
                            thereby represent and warrant that:
                            <bdt className="else-block">
                              <bdt className="block-component"></bdt>
                            </bdt>
                          </span>
                        </div>
                        &nbsp;
                      </bdt>
                      &nbsp;
                    </bdt>
                    &nbsp;
                  </bdt>
                </div>
                <ul>
                  <li data-custom-class="body_text">
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          The creation, distribution, transmission, public
                          display, or performance, and the accessing,
                          downloading, or copying of your Contributions do not
                          and will not infringe the proprietary rights,
                          including but not limited to the copyright, patent,
                          trademark, trade secret, or moral rights of any third
                          party.
                        </span>
                      </span>
                    </span>
                  </li>
                  <li data-custom-class="body_text">
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          You are the creator and owner of or have the necessary{" "}
                          <bdt className="block-component"></bdt>licenses
                          <bdt className="statement-end-if-in-editor"></bdt>,
                          rights, consents, releases, and permissions to use and
                          to <bdt className="block-component"></bdt>authorize
                          <bdt className="statement-end-if-in-editor"></bdt> us,
                          the Services, and other users of the Services to use
                          your Contributions in any manner contemplated by the
                          Services and these Legal Terms.
                        </span>
                      </span>
                    </span>
                  </li>
                  <li data-custom-class="body_text">
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          You have the written consent, release, and/or
                          permission of each and every identifiable individual
                          person in your Contributions to use the name or
                          likeness of each and every such identifiable
                          individual person to enable inclusion and use of your
                          Contributions in any manner contemplated by the
                          Services and these Legal Terms.
                        </span>
                      </span>
                    </span>
                  </li>
                  <li data-custom-class="body_text">
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          Your Contributions are not false, inaccurate, or
                          misleading.
                        </span>
                      </span>
                      &nbsp;
                    </span>
                  </li>
                  <li data-custom-class="body_text">
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          Your Contributions are not unsolicited or{" "}
                          <bdt className="block-component"></bdt>unauthorized
                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                          advertising, promotional materials, pyramid schemes,
                          chain letters, spam, mass mailings, or other forms of
                          solicitation.
                        </span>
                      </span>
                    </span>
                  </li>
                  <li data-custom-class="body_text">
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          Your Contributions are not obscene, lewd, lascivious,
                          filthy, violent, harassing,{" "}
                          <bdt className="block-component"></bdt>
                          libelous
                          <bdt className="statement-end-if-in-editor"></bdt>,
                          slanderous, or otherwise objectionable (as determined
                          by us).
                        </span>
                      </span>
                      &nbsp;
                    </span>
                  </li>
                  <li data-custom-class="body_text">
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          Your Contributions do not ridicule, mock, disparage,
                          intimidate, or abuse anyone.
                        </span>
                      </span>
                    </span>
                  </li>
                  <li data-custom-class="body_text">
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          Your Contributions are not used to harass or threaten
                          (in the legal sense of those terms) any other person
                          and to promote violence against a specific person or
                          class of people.
                        </span>
                      </span>
                    </span>
                  </li>
                  <li data-custom-class="body_text">
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          Your Contributions do not violate any applicable law,
                          regulation, or rule.
                        </span>
                      </span>
                    </span>
                  </li>
                  <li data-custom-class="body_text">
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          Your Contributions do not violate the privacy or
                          publicity rights of any third party.
                        </span>
                      </span>
                    </span>
                  </li>
                  <li data-custom-class="body_text">
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          Your Contributions do not violate any applicable law
                          concerning child pornography, or otherwise intended to
                          protect the health or well-being of minors.
                        </span>
                      </span>
                    </span>
                  </li>
                  <li data-custom-class="body_text">
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          Your Contributions do not include any offensive
                          comments that are connected to race, national origin,
                          gender, sexual preference, or physical handicap.
                        </span>
                      </span>
                    </span>
                  </li>
                  <li data-custom-class="body_text">
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          Your Contributions do not otherwise violate, or link
                          to material that violates, any provision of these
                          Legal Terms, or any applicable law or regulation.
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="MsoNormal">
              <bdt className="block-container if" data-type="if">
                <bdt data-type="conditional-block">
                  <bdt data-type="body">
                    <div className="MsoNormal" data-custom-class="body_text">
                      <span>
                        Any use of the Services in violation of the foregoing
                        violates these Legal Terms and may result in, among
                        other things, termination or suspension of your rights
                        to use the Services.
                      </span>
                    </div>
                  </bdt>
                </bdt>
              </bdt>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal">
              <bdt className="block-container if" data-type="if">
                <bdt data-type="conditional-block">
                  <bdt data-type="body">
                    <div
                      className="MsoNormal"
                      data-custom-class="heading_1"
                      id="license"
                    >
                      <strong>
                        <span>
                          <strong>
                            <span>
                              <strong>
                                <span>
                                  <strong>
                                    <span>9.</span>
                                  </strong>
                                </span>
                              </strong>
                            </span>
                            &nbsp;
                          </strong>
                          CONTRIBUTION <bdt className="block-component"></bdt>
                          LICENSE
                          <bdt className="statement-end-if-in-editor"></bdt>
                        </span>
                      </strong>
                    </div>
                  </bdt>
                </bdt>
              </bdt>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal">
              <bdt
                className="block-container if"
                data-type="if"
                id="a088ddfb-d8c1-9e58-6f21-958c3f4f0709"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="user_post_content_option"
                    data-type="statement"
                  ></bdt>
                </bdt>
              </bdt>
              <bdt className="block-container if" data-type="if">
                <bdt data-type="conditional-block">
                  <bdt data-type="body">
                    <div className="MsoNormal" data-custom-class="body_text">
                      <span>
                        By posting your Contributions to any part of the
                        Services
                        <bdt
                          className="block-container if"
                          data-type="if"
                          id="19652acc-9a2a-5ffe-6189-9474402fa6cc"
                        >
                          <bdt data-type="conditional-block">
                            <bdt
                              className="block-component"
                              data-record-question-key="socialnetwork_link_option"
                              data-type="statement"
                            ></bdt>
                          </bdt>
                          , you automatically grant, and you represent and
                          warrant that you have the right to grant, to us an
                          unrestricted, unlimited, irrevocable, perpetual,
                          non-exclusive, transferable, royalty-free, fully-paid,
                          worldwide right, and{" "}
                          <bdt className="block-component"></bdt>license
                          <bdt className="statement-end-if-in-editor"></bdt> to
                          host, use, copy, reproduce, disclose, sell, resell,
                          publish, broadcast, retitle, archive, store, cache,
                          publicly perform, publicly display, reformat,
                          translate, transmit, excerpt (in whole or in part),
                          and distribute such Contributions (including, without
                          limitation, your image and voice) for any purpose,
                          commercial, advertising, or otherwise, and to prepare
                          derivative works of, or incorporate into other works,
                          such Contributions, and grant and{" "}
                          <bdt className="block-component"></bdt>authorize
                          sublicenses
                          <bdt className="statement-end-if-in-editor"></bdt> of
                          the foregoing. The use and distribution may occur in
                          any media formats and through any media channels.
                        </bdt>
                      </span>
                    </div>
                  </bdt>
                </bdt>
              </bdt>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal">
              <bdt className="block-container if" data-type="if">
                <bdt data-type="conditional-block">
                  <bdt data-type="body">
                    <div className="MsoNormal" data-custom-class="body_text">
                      <span>
                        This <bdt className="block-component"></bdt>license
                        <bdt className="statement-end-if-in-editor"></bdt> will
                        apply to any form, media, or technology now known or
                        hereafter developed, and includes our use of your name,
                        company name, and franchise name, as applicable, and any
                        of the trademarks, service marks, trade names, logos,
                        and personal and commercial images you provide. You
                        waive all moral rights in your Contributions, and you
                        warrant that moral rights have not otherwise been
                        asserted in your Contributions.
                      </span>
                    </div>
                  </bdt>
                </bdt>
              </bdt>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal">
              <bdt className="block-container if" data-type="if">
                <bdt data-type="conditional-block">
                  <bdt data-type="body">
                    <div className="MsoNormal" data-custom-class="body_text">
                      <span>
                        We do not assert any ownership over your Contributions.
                        You retain full ownership of all of your Contributions
                        and any intellectual property rights or other
                        proprietary rights associated with your Contributions.
                        We are not liable for any statements or representations
                        in your Contributions provided by you in any area on the
                        Services. You are solely responsible for your
                        Contributions to the Services and you expressly agree to
                        exonerate us from any and all responsibility and to
                        refrain from any legal action against us regarding your
                        Contributions.
                      </span>
                    </div>
                  </bdt>
                </bdt>
              </bdt>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal">
              <bdt className="block-container if" data-type="if">
                <bdt data-type="conditional-block">
                  <bdt data-type="body">
                    <div className="MsoNormal" data-custom-class="body_text">
                      <span>
                        We have the right, in our sole and absolute discretion,
                        (1) to edit, redact, or otherwise change any
                        Contributions; (2) to{" "}
                        <bdt className="block-component"></bdt>
                        re-categorize
                        <bdt className="statement-end-if-in-editor"></bdt> any
                        Contributions to place them in more appropriate
                        locations on the Services; and (3) to pre-screen or
                        delete any Contributions at any time and for any reason,
                        without notice. We have no obligation to monitor your
                        Contributions.
                      </span>
                    </div>
                  </bdt>
                </bdt>
              </bdt>
            </div>
          </div>
          <div align="center">
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal">
              <bdt className="block-container if" data-type="if">
                <bdt data-type="conditional-block">
                  <bdt data-type="body">
                    <span>
                      <bdt className="else-block"></bdt>
                    </span>
                  </bdt>
                </bdt>
              </bdt>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <bdt className="block-container if" data-type="if">
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="review_option"
                    data-type="statement"
                  ></bdt>
                </bdt>
              </bdt>
            </div>
            <div
              className="MsoNormal"
              data-custom-class="heading_1"
              id="reviews"
            >
              <strong>
                <span>
                  <strong>
                    <span>
                      <strong>
                        <span>10.</span>
                      </strong>
                    </span>
                  </strong>
                  &nbsp;
                </span>
                GUIDELINES FOR REVIEWS
              </strong>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                We may provide you areas on the Services to leave reviews or
                ratings. When posting a review, you must comply with the
                following criteria: (1) you should have firsthand experience
                with the person/entity being reviewed; (2) your reviews should
                not contain offensive profanity, or abusive, racist, offensive,
                or hateful language; (3) your reviews should not contain
                discriminatory references based on religion, race, gender,
                national origin, age, marital status, sexual orientation, or
                disability; (4) your reviews should not contain references to
                illegal activity; (5) you should not be affiliated with
                competitors if posting negative reviews; (6) you should not make
                any conclusions as to the legality of conduct; (7) you may not
                post any false or misleading statements; and (8) you may not{" "}
                <bdt className="block-component"></bdt>organize
                <bdt className="statement-end-if-in-editor"></bdt> a campaign
                encouraging others to post reviews, whether positive or
                negative.
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                <span>
                  We may accept, reject, or remove reviews in our sole
                  discretion. We have absolutely no obligation to screen reviews
                  or to delete reviews, even if anyone considers reviews
                  objectionable or inaccurate. Reviews are not endorsed by us,
                  and do not necessarily represent our opinions or the views of
                  any of our affiliates or partners. We do not assume liability
                  for any review or for any claims, liabilities, or losses
                  resulting from any review. By posting a review, you hereby
                  grant to us a perpetual, non-exclusive, worldwide,
                  royalty-free, fully paid, assignable, and sublicensable right
                  and <bdt className="block-component"></bdt>license
                  <bdt className="statement-end-if-in-editor"></bdt> to
                  reproduce, modify, translate, transmit by any means, display,
                  perform, and/or distribute all content relating to review.
                </span>
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal">
              <span>
                <span>
                  <span>
                    <bdt className="block-container if" data-type="if">
                      <bdt
                        className="statement-end-if-in-editor"
                        data-type="close"
                      ></bdt>
                    </bdt>
                  </span>
                </span>
              </span>
            </div>
            <div className="MsoNormal">
              <bdt className="block-container if" data-type="if">
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="mobile_app_option"
                    data-type="statement"
                  ></bdt>
                </bdt>
              </bdt>
            </div>
            <div
              className="MsoNormal"
              data-custom-class="heading_1"
              id="mobile"
            >
              <strong>
                <span>
                  <strong>
                    <span>
                      <strong>
                        <span>11.</span>
                      </strong>
                    </span>
                  </strong>
                  &nbsp;
                </span>
                MOBILE APPLICATION <bdt className="block-component"></bdt>
                LICENSE
                <bdt className="statement-end-if-in-editor"></bdt>
              </strong>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="heading_2">
              <strong>
                Use <bdt className="block-component"></bdt>License
                <bdt className="statement-end-if-in-editor"></bdt>
              </strong>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                If you access the Services via the App, then we grant you a
                revocable, non-exclusive, non-transferable, limited right to
                install and use the App on wireless electronic devices owned or
                controlled by you, and to access and use the App on such devices
                strictly in accordance with the terms and conditions of this
                mobile application <bdt className="block-component"></bdt>
                license
                <bdt className="statement-end-if-in-editor"></bdt> contained in
                these Legal Terms. You shall not: (1) except as permitted by
                applicable law, decompile, reverse engineer, disassemble,
                attempt to derive the source code of, or decrypt the App; (2)
                make any modification, adaptation, improvement, enhancement,
                translation, or derivative work from the App; (3) violate any
                applicable laws, rules, or regulations in connection with your
                access or use of the App; (4) remove, alter, or obscure any
                proprietary notice (including any notice of copyright or
                trademark) posted by us or the licensors of the App; (5) use the
                App for any revenue-generating{" "}
                <bdt className="block-component"></bdt>endeavor
                <bdt className="statement-end-if-in-editor"></bdt>, commercial
                enterprise, or other purpose for which it is not designed or
                intended; (6) make the App available over a network or other
                environment permitting access or use by multiple devices or
                users at the same time; (7) use the App for creating a product,
                service, or software that is, directly or indirectly,
                competitive with or in any way a substitute for the App; (8) use
                the App to send automated queries to any website or to send any
                unsolicited commercial email; or (9) use any proprietary
                information or any of our interfaces or our other intellectual
                property in the design, development, manufacture, licensing, or
                distribution of any applications, accessories, or devices for
                use with the App.
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="heading_2">
              <span>
                <strong>Apple and Android Devices</strong>
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal" data-custom-class="body_text">
              <span>
                The following terms apply when you use the App obtained from
                either the Apple Store or Google Play (each an{" "}
                <bdt className="block-component"></bdt>
                "App Distributor"
                <bdt className="statement-end-if-in-editor"></bdt>) to access
                the Services: (1) the <bdt className="block-component"></bdt>
                license
                <bdt className="statement-end-if-in-editor"></bdt> granted to
                you for our App is limited to a non-transferable{" "}
                <bdt className="block-component"></bdt>
                license<bdt className="statement-end-if-in-editor"></bdt> to use
                the application on a device that{" "}
                <bdt className="block-component"></bdt>
                utilizes
                <bdt className="statement-end-if-in-editor"></bdt> the Apple iOS
                or Android operating systems, as applicable, and in accordance
                with the usage rules set forth in the applicable App
                Distributor’s terms of service; (2) we are responsible for
                providing any maintenance and support services with respect to
                the App as specified in the terms and conditions of this mobile
                application <bdt className="block-component"></bdt>license
                <bdt className="statement-end-if-in-editor"></bdt> contained in
                these Legal Terms or as otherwise required under applicable law,
                and you acknowledge that each App Distributor has no obligation
                whatsoever to furnish any maintenance and support services with
                respect to the App; (3) in the event of any failure of the App
                to conform to any applicable warranty, you may notify the
                applicable App Distributor, and the App Distributor, in
                accordance with its terms and policies, may refund the purchase
                price, if any, paid for the App, and to the maximum extent
                permitted by applicable law, the App Distributor will have no
                other warranty obligation whatsoever with respect to the App;
                (4) you represent and warrant that (i) you are not located in a
                country that is subject to a US government embargo, or that has
                been designated by the US government as a{" "}
                <bdt className="block-component"></bdt>"terrorist supporting"
                <bdt className="statement-end-if-in-editor"></bdt> country and
                (ii) you are not listed on any US government list of prohibited
                or restricted parties; (5) you must comply with applicable
                third-party terms of agreement when using the App, e.g.
                <bdt className="block-component"></bdt>,
                <bdt className="statement-end-if-in-editor"></bdt> if you have a
                VoIP application, then you must not be in violation of their
                wireless data service agreement when using the App; and (6) you
                acknowledge and agree that the App Distributors are third-party
                beneficiaries of the terms and conditions in this mobile
                application <bdt className="block-component"></bdt>license
                <bdt className="statement-end-if-in-editor"></bdt> contained in
                these Legal Terms, and that each App Distributor will have the
                right (and will be deemed to have accepted the right) to enforce
                the terms and conditions in this mobile application{" "}
                <bdt className="block-component"></bdt>license
                <bdt className="statement-end-if-in-editor"></bdt> contained in
                these Legal Terms against you as a third-party beneficiary
                thereof.
              </span>
            </div>
            <div className="MsoNormal">
              <br></br>
            </div>
            <div className="MsoNormal">
              <span>
                <span>
                  <span>
                    <bdt className="block-container if" data-type="if">
                      <bdt
                        className="statement-end-if-in-editor"
                        data-type="close"
                      ></bdt>
                    </bdt>
                  </span>
                </span>
              </span>
            </div>
            <div className="MsoNormal">
              <bdt className="block-container if" data-type="if">
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="socialnetwork_link_option"
                    data-type="statement"
                  ></bdt>
                </bdt>
              </bdt>
            </div>
          </div>
          <div className="MsoNormal">
            <bdt className="block-container if" data-type="if">
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="3rd_party_option"
                  data-type="statement"
                ></bdt>
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="thirdparty"
          >
            <strong>
              <span>
                <strong>
                  <span>
                    <strong>
                      <span>12.</span>
                    </strong>
                  </span>
                </strong>
                &nbsp;
              </span>
              THIRD-PARTY WEBSITES AND CONTENT
            </strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              The Services may contain (or you may be sent via the{" "}
              <bdt className="block-component"></bdt>Site or App
              <bdt className="block-component"></bdt>) links to other websites (
              <bdt className="block-component"></bdt>"Third-Party Websites"
              <bdt className="statement-end-if-in-editor"></bdt>) as well as
              articles, photographs, text, graphics, pictures, designs, music,
              sound, video, information, applications, software, and other
              content or items belonging to or originating from third parties (
              <bdt className="block-component"></bdt>
              "Third-Party Content"
              <bdt className="statement-end-if-in-editor"></bdt>
              ). Such <span>Third-Party</span> Websites and{" "}
              <span>Third-Party</span> Content are not investigated, monitored,
              or checked for accuracy, appropriateness, or completeness by us,
              and we are not responsible for any Third-Party Websites accessed
              through the Services or any <span>Third-Party</span> Content
              posted on, available through, or installed from the Services,
              including the content, accuracy, offensiveness, opinions,
              reliability, privacy practices, or other policies of or contained
              in the <span>Third-Party</span> Websites or the{" "}
              <span>Third-Party</span> Content. Inclusion of, linking to, or
              permitting the use or installation of any <span>Third-Party</span>{" "}
              Websites or any <span>Third-Party&nbsp;</span>
              Content does not imply approval or endorsement thereof by us. If
              you decide to leave the Services and access the{" "}
              <span>Third-Party</span> Websites or to use or install any{" "}
              <span>Third-Party</span> Content, you do so at your own risk, and
              you should be aware these Legal Terms no longer govern. You should
              review the applicable terms and policies, including privacy and
              data gathering practices, of any website to which you navigate
              from the Services or relating to any applications you use or
              install from the Services. Any purchases you make through{" "}
              <span>Third-Party</span> Websites will be through other websites
              and from other companies, and we take no responsibility whatsoever
              in relation to such purchases which are exclusively between you
              and the applicable third party. You agree and acknowledge that we
              do not endorse the products or services offered on{" "}
              <span>Third-Party</span> Websites and you shall hold us blameless
              from any harm caused by your purchase of such products or
              services. Additionally, you shall hold us blameless from any
              losses sustained by you or harm caused to you relating to or
              resulting in any way from any <span>Third-Party</span> Content or
              any contact with <span>Third-Party</span> Websites.
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal">
            <bdt className="block-container if" data-type="if">
              <bdt
                className="statement-end-if-in-editor"
                data-type="close"
              ></bdt>
            </bdt>
          </div>
          <div className="MsoNormal">
            <bdt className="block-container if" data-type="if">
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="advertiser_option"
                  data-type="statement"
                ></bdt>
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="advertisers"
          >
            <strong>
              <span>
                <strong>
                  <span>
                    <strong>
                      <span>13.</span>
                    </strong>
                  </span>
                </strong>
                &nbsp;
              </span>
              ADVERTISERS
            </strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              We allow advertisers to display their advertisements and other
              information in certain areas of the Services, such as sidebar
              advertisements or banner advertisements. We simply provide the
              space to place such advertisements, and we have no other
              relationship with advertisers.
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal">
            <bdt className="block-container if" data-type="if">
              <bdt
                className="statement-end-if-in-editor"
                data-type="close"
              ></bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="sitemanage"
          >
            <strong>
              <span>
                <strong>
                  <span>
                    <strong>
                      <span>
                        <strong>
                          <span>14.</span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                SERVICES MANAGEMENT
              </span>
            </strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            We reserve the right, but not the obligation, to: (1) monitor the
            Services for violations of these Legal Terms; (2) take appropriate
            legal action against anyone who, in our sole discretion, violates
            the law or these Legal Terms, including without limitation,
            reporting such user to law enforcement authorities; (3) in our sole
            discretion and without limitation, refuse, restrict access to, limit
            the availability of, or disable (to the extent technologically
            feasible) any of your Contributions or any portion thereof; (4) in
            our sole discretion and without limitation, notice, or liability, to
            remove from the Services or otherwise disable all files and content
            that are excessive in size or are in any way burdensome to our
            systems; and (5) otherwise manage the Services in a manner designed
            to protect our rights and property and to facilitate the proper
            functioning of the Services.
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal">
            <bdt className="block-container if" data-type="if">
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="privacy_policy_option"
                  data-type="statement"
                ></bdt>
              </bdt>
              <bdt className="block-container if" data-type="if">
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="privacy_policy_followup"
                    data-type="statement"
                  ></bdt>
                </bdt>
              </bdt>
            </bdt>
          </div>
          <div className="MsoNormal" data-custom-class="heading_1" id="ppno">
            <strong>
              <span>
                <strong>
                  <span>
                    <strong>
                      <span>15.</span>
                    </strong>
                  </span>
                </strong>
                &nbsp;
              </span>
              PRIVACY POLICY
            </strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              We care about data privacy and security. By using the Services,
              you agree to be bound by our Privacy Policy posted on the
              Services, which is incorporated into these Legal Terms. Please be
              advised the Services are hosted in{" "}
              <span>
                <bdt className="block-component"></bdt>
                <bdt className="question">Lithuania</bdt>
                <bdt className="statement-end-if-in-editor"></bdt>
              </span>
              <bdt className="block-component"></bdt>
              <bdt className="forloop-component"></bdt>
              <bdt className="block-component"></bdt>
              <bdt className="block-component"></bdt> and{" "}
              <bdt className="question">United States</bdt>
              <bdt className="statement-end-if-in-editor"></bdt>
              <bdt className="forloop-component"></bdt>
              <bdt className="statement-end-if-in-editor"></bdt>. If you access
              the Services from any other region of the world with laws or other
              requirements governing personal data collection, use, or
              disclosure that differ from applicable laws in{" "}
              <span>
                <bdt className="block-component"></bdt>
                <bdt className="question">Lithuania</bdt>
                <bdt className="statement-end-if-in-editor"></bdt>
              </span>
              <bdt className="block-component"></bdt>
              <bdt className="forloop-component"></bdt>
              <bdt className="block-component"></bdt>
              <bdt className="block-component"></bdt> and{" "}
              <bdt className="question">United States</bdt>
              <bdt className="statement-end-if-in-editor"></bdt>
              <bdt className="forloop-component"></bdt>
              <bdt className="statement-end-if-in-editor"></bdt>, then through
              your continued use of the Services, you are transferring your data
              to{" "}
              <span>
                <bdt className="block-component"></bdt>
                <bdt className="question">Lithuania</bdt>
                <bdt className="statement-end-if-in-editor"></bdt>
              </span>
              <bdt className="block-component"></bdt>
              <bdt className="forloop-component"></bdt>
              <bdt className="block-component"></bdt>
              <bdt className="block-component"></bdt> and{" "}
              <bdt className="question">United States</bdt>
              <bdt className="statement-end-if-in-editor"></bdt>
              <bdt className="forloop-component"></bdt>
              <bdt className="statement-end-if-in-editor"></bdt>, and you
              expressly consent to have your data transferred to and processed
              in{" "}
              <span>
                <bdt className="block-component"></bdt>
                <bdt className="question">Lithuania</bdt>
                <bdt className="statement-end-if-in-editor"></bdt>
              </span>
              <bdt className="block-component"></bdt>
              <bdt className="forloop-component"></bdt>
              <bdt className="block-component"></bdt>
              <bdt className="block-component"></bdt> and{" "}
              <bdt className="question">United States</bdt>
              <bdt className="statement-end-if-in-editor"></bdt>
              <bdt className="forloop-component"></bdt>
              <bdt className="statement-end-if-in-editor"></bdt>.
              <bdt
                className="block-container if"
                data-type="if"
                id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7"
              >
                <bdt data-type="conditional-block">
                  <span>
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7"
                    >
                      <bdt data-type="conditional-block">
                        <bdt data-type="body">
                          <span>
                            <bdt className="block-component"></bdt>
                            <bdt
                              className="block-container if"
                              data-type="if"
                              id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7"
                            >
                              <bdt data-type="conditional-block">
                                <bdt
                                  className="block-component"
                                  data-record-question-key="user_u13_option"
                                  data-type="statement"
                                >
                                  <span>
                                    <bdt className="statement-end-if-in-editor"></bdt>
                                  </span>
                                </bdt>
                              </bdt>
                            </bdt>
                          </span>
                        </bdt>
                      </bdt>
                    </bdt>
                  </span>
                </bdt>
              </bdt>
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal">
            <span>
              <bdt className="block-container if" data-type="if">
                <bdt data-type="conditional-block">
                  <span>
                    <bdt className="block-container if" data-type="if">
                      <bdt data-type="conditional-block">
                        <bdt data-type="body">
                          <span>
                            <bdt className="block-container if" data-type="if">
                              <bdt
                                className="statement-end-if-in-editor"
                                data-type="close"
                              >
                                <span>
                                  <bdt className="statement-end-if-in-editor">
                                    <span>
                                      <bdt
                                        className="block-container if"
                                        data-type="if"
                                      >
                                        <bdt data-type="conditional-block">
                                          <span>
                                            <bdt
                                              className="block-container if"
                                              data-type="if"
                                            >
                                              <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                  <span>
                                                    <bdt
                                                      className="block-container if"
                                                      data-type="if"
                                                    >
                                                      <bdt
                                                        className="statement-end-if-in-editor"
                                                        data-type="close"
                                                      >
                                                        <span>
                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                        </span>
                                                      </bdt>
                                                    </bdt>
                                                  </span>
                                                </bdt>
                                              </bdt>
                                            </bdt>
                                          </span>
                                        </bdt>
                                      </bdt>
                                    </span>
                                  </bdt>
                                </span>
                              </bdt>
                            </bdt>
                          </span>
                        </bdt>
                      </bdt>
                    </bdt>
                  </span>
                </bdt>
              </bdt>
            </span>
          </div>
          <div className="MsoNormal">
            <bdt className="block-container if" data-type="if">
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="copyright_agent_option"
                  data-type="statement"
                >
                  <bdt className="block-component"></bdt>
                  <bdt className="block-component"></bdt>
                </bdt>
                <bdt className="block-container if" data-type="if">
                  <bdt
                    className="statement-end-if-in-editor"
                    data-type="close"
                  ></bdt>
                </bdt>
              </bdt>
            </bdt>
          </div>
          <div className="MsoNormal">
            <bdt className="block-component"></bdt>
            <bdt className="block-container if" data-type="if">
              <bdt className="statement-end-if-in-editor" data-type="close">
                <bdt className="block-component"></bdt>
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="copyrightyes"
          >
            <strong>
              <span>
                <strong>
                  <span>
                    <strong>
                      <span>
                        <strong>
                          <span>16.</span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                COPYRIGHT INFRINGEMENTS
              </span>
            </strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              We respect the intellectual property rights of others. If you
              believe that any material available on or through the Services
              infringes upon any copyright you own or control, please
              immediately notify us using the contact information provided below
              (a <bdt className="block-component"></bdt>
              "Notification"<bdt className="statement-end-if-in-editor"></bdt>).
              A copy of your Notification will be sent to the person who posted
              or stored the material addressed in the Notification. Please be
              advised that pursuant to applicable law you may be held liable for
              damages if you make material misrepresentations in a Notification.
              Thus, if you are not sure that material located on or linked to by
              the Services infringes your copyright, you should consider first
              contacting an attorney.
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal">
            <bdt className="block-container if" data-type="if">
              <bdt
                className="statement-end-if-in-editor"
                data-type="close"
              ></bdt>
            </bdt>
            <bdt className="block-component"></bdt>
          </div>
          <div className="MsoNormal" data-custom-class="heading_1" id="terms">
            <strong>
              <span>
                <strong>
                  <span>
                    <strong>
                      <span>
                        <strong>
                          <span>17.</span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                TERM AND TERMINATION
              </span>
            </strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              These Legal Terms shall remain in full force and effect while you
              use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
              LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
              WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
              SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON
              FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR
              BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN
              THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
              TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE{" "}
              <bdt
                className="block-container if"
                data-type="if"
                id="a6e121c2-36b4-5066-bf9f-a0a33512e768"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="user_account_option"
                    data-type="statement"
                  ></bdt>
                  <bdt data-type="body">YOUR ACCOUNT AND&nbsp;</bdt>
                </bdt>
                <bdt
                  className="statement-end-if-in-editor"
                  data-type="close"
                ></bdt>
              </bdt>
              ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT
              WARNING, IN OUR SOLE DISCRETION.
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              If we terminate or suspend your account for any reason, you are
              prohibited from registering and creating a new account under your
              name, a fake or borrowed name, or the name of any third party,
              even if you may be acting on behalf of the third party. In
              addition to terminating or suspending your account, we reserve the
              right to take appropriate legal action, including without
              limitation pursuing civil, criminal, and injunctive redress.
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="modifications"
          >
            <strong>
              <span>
                <strong>
                  <span>
                    <strong>
                      <span>
                        <strong>
                          <span>18.</span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                MODIFICATIONS AND INTERRUPTIONS
              </span>
            </strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              We reserve the right to change, modify, or remove the contents of
              the Services at any time or for any reason at our sole discretion
              without notice. However, we have no obligation to update any
              information on our Services.
              <bdt className="block-component"></bdt> We will not be liable to
              you or any third party for any modification, price change,
              suspension, or discontinuance of the Services.
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              We cannot guarantee the Services will be available at all times.
              We may experience hardware, software, or other problems or need to
              perform maintenance related to the Services, resulting in
              interruptions, delays, or errors. We reserve the right to change,
              revise, update, suspend, discontinue, or otherwise modify the
              Services at any time or for any reason without notice to you. You
              agree that we have no liability whatsoever for any loss, damage,
              or inconvenience caused by your inability to access or use the
              Services during any downtime or discontinuance of the Services.
              Nothing in these Legal Terms will be construed to obligate us to
              maintain and support the Services or to supply any corrections,
              updates, or releases in connection therewith.
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="heading_1" id="law">
            <strong>
              <span>
                <strong>
                  <span>
                    <strong>
                      <span>
                        <strong>
                          <span>19.</span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                GOVERNING LAW
              </span>
            </strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal">
            <span>
              <bdt className="block-component"></bdt>
            </span>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              These Legal Terms are governed by and interpreted following the
              laws of <bdt className="block-component"></bdt>
              <bdt className="question">Lithuania</bdt>
              <span>
                <bdt className="statement-end-if-in-editor"></bdt>
              </span>
              , and the use of the United Nations Convention of Contracts for
              the International Sales of Goods is expressly excluded. If your
              habitual residence is in the EU, and you are a consumer, you
              additionally possess the protection provided to you by obligatory
              provisions of the law in your country to residence.{" "}
              <bdt className="question">Andrew's Cookin'</bdt> and yourself both agree
              to submit to the non-exclusive jurisdiction of the courts of{" "}
              <bdt className="question">Kaunas</bdt>, which means that you may
              make a claim to defend your consumer protection rights in regards
              to these Legal Terms in <bdt className="block-component"></bdt>
              <bdt className="question">Lithuania</bdt>
              <span>
                <bdt className="statement-end-if-in-editor"></bdt>
              </span>
              , or in the EU country in which you reside.
              <bdt className="else-block"></bdt>
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="disputes"
          >
            <strong>
              <span>
                <strong>
                  <span>
                    <strong>
                      <span>
                        <strong>
                          <span>20.</span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                DISPUTE RESOLUTION
              </span>
            </strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal">
            <bdt className="block-component"></bdt>
          </div>
          <div className="MsoNormal">
            <bdt className="block-component"></bdt>
          </div>
          <div className="MsoNormal" data-custom-class="heading_2">
            <strong>Informal Negotiations</strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              To expedite resolution and control the cost of any dispute,
              controversy, or claim related to these Legal Terms (each a{" "}
              <bdt className="block-component"></bdt>"Dispute" and collectively,
              the "Disputes"<bdt className="statement-end-if-in-editor"></bdt>)
              brought by either you or us (individually, a{" "}
              <bdt className="block-component"></bdt>
              "Party" and collectively, the "Parties"
              <bdt className="statement-end-if-in-editor"></bdt>), the Parties
              agree to first attempt to negotiate any Dispute (except those
              Disputes expressly provided below) informally for at least{" "}
              <bdt className="question">thirty (30)</bdt> days before initiating
              arbitration. Such informal negotiations commence upon written
              notice from one Party to the other Party.
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal">
            <bdt className="statement-end-if-in-editor"></bdt>
          </div>
          <div className="MsoNormal" data-custom-class="heading_2">
            <strong>Binding Arbitration</strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <bdt className="block-component">
              <span></span>
            </bdt>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            Any dispute arising from the relationships between the Parties to
            these Legal Terms shall be determined by one arbitrator who will be
            chosen in accordance with the Arbitration and Internal Rules of the
            European Court of Arbitration being part of the European Centre of
            Arbitration having its seat in Strasbourg, and which are in force at
            the time the application for arbitration is filed, and of which
            adoption of this clause constitutes acceptance. The seat of
            arbitration shall be <bdt className="block-component"></bdt>
            <bdt className="question">Kaunas</bdt>,{" "}
            <bdt className="block-component"></bdt>
            <bdt className="question">Lithuania</bdt>
            <bdt className="else-block"></bdt>. The language of the proceedings
            shall be <bdt className="question">English</bdt>. Applicable rules
            of substantive law shall be the law of{" "}
            <bdt className="block-component"></bdt>
            <bdt className="question">Lithuania</bdt>
            <bdt className="statement-end-if-in-editor"></bdt>.
            <bdt className="else-block"></bdt>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="heading_2">
            <strong>Restrictions</strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            The Parties agree that any arbitration shall be limited to the
            Dispute between the Parties individually. To the full extent
            permitted by law, (a) no arbitration shall be joined with any other
            proceeding; (b) there is no right or authority for any Dispute to be
            arbitrated on a class-action basis or to{" "}
            <bdt className="block-component"></bdt>utilize
            <bdt className="statement-end-if-in-editor"></bdt> class action
            procedures; and (c) there is no right or authority for any Dispute
            to be brought in a purported representative capacity on behalf of
            the general public or any other persons.
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="heading_2">
            <bdt className="block-component"></bdt>
            <strong>Exceptions to Informal Negotiations and Arbitration</strong>
            <bdt className="statement-end-if-in-editor"></bdt>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <bdt className="block-component"></bdt>The Parties agree that the
            following Disputes are not subject to the above provisions
            concerning informal negotiations binding arbitration: (a) any
            Disputes seeking to enforce or protect, or concerning the validity
            of, any of the intellectual property rights of a Party; (b) any
            Dispute related to, or arising from, allegations of theft, piracy,
            invasion of privacy, or <bdt className="block-component"></bdt>
            unauthorized
            <bdt className="statement-end-if-in-editor"></bdt> use; and (c) any
            claim for injunctive relief. If this provision is found to be
            illegal or unenforceable, then neither Party will elect to arbitrate
            any Dispute falling within that portion of this provision found to
            be illegal or unenforceable and such Dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal
            jurisdiction of that court.
            <bdt className="statement-end-if-in-editor"></bdt>
          </div>
          <div className="MsoNormal">
            <bdt className="statement-end-if-in-editor">
              <bdt className="statement-end-if-in-editor"></bdt>
            </bdt>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="corrections"
          >
            <strong>
              <span>
                <strong>
                  <span>
                    <strong>
                      <span>
                        <strong>
                          <span>21.</span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                CORRECTIONS
              </span>
            </strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            There may be information on the Services that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Services at any time, without prior
            notice.
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="disclaimer"
          >
            <span>
              <strong>
                <span>
                  <strong>
                    <span>
                      <strong>
                        <span>22.</span>
                      </strong>
                    </span>
                  </strong>
                </span>{" "}
                DISCLAIMER
              </strong>
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
              AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO
              THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
              EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE
              THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
              NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT
              THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE
              CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE
              SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
              (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
              (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
              RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY{" "}
              <bdt className="block-component"></bdt>UNAUTHORIZED
              <bdt className="statement-end-if-in-editor"></bdt> ACCESS TO OR
              USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
              AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION
              OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY
              BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED
              TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY
              ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS
              OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
              CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
              SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
              RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY
              A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR
              ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
              ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
              RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
              THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
              PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
              ENVIRONMENT, YOU SHOULD USE YOUR BEST{" "}
              <bdt className="block-component"></bdt>JUDGMENT
              <bdt className="statement-end-if-in-editor"></bdt> AND EXERCISE
              CAUTION WHERE APPROPRIATE.
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="liability"
          >
            <strong>
              <span>
                <strong>
                  <span>
                    <strong>
                      <span>
                        <strong>
                          <span>23.</span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                LIMITATIONS OF LIABILITY
              </span>
            </strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal">
            <span>
              <span data-custom-class="body_text">
                IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
                LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
                CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
                DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
                OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE
                HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              </span>{" "}
              <bdt
                className="block-container if"
                data-type="if"
                id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
              >
                <span data-custom-class="body_text">
                  <bdt data-type="conditional-block">
                    <bdt
                      className="block-component"
                      data-record-question-key="limitations_liability_option"
                      data-type="statement"
                    ></bdt>
                    <bdt data-type="body">
                      NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
                      OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND
                      REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE
                      LIMITED TO{" "}
                      <bdt
                        className="block-container if"
                        data-type="if"
                        id="73189d93-ed3a-d597-3efc-15956fa8e04e"
                      >
                        <bdt data-type="conditional-block">
                          <bdt
                            className="block-component"
                            data-record-question-key="limitations_liability_option"
                            data-type="statement"
                          ></bdt>
                          <bdt data-type="body">
                            THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="limilation_liability_time_option"
                                data-type="statement"
                              ></bdt>{" "}
                              <bdt data-type="body">
                                <span>
                                  DURING THE{" "}
                                  <bdt
                                    className="block-container question question-in-editor"
                                    data-id="98461079-8520-8e55-edbd-84fdc37a26d4"
                                    data-type="question"
                                  >
                                    one (1)
                                  </bdt>{" "}
                                  MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION
                                  ARISING
                                </span>
                              </bdt>
                            </bdt>
                            <bdt
                              className="statement-end-if-in-editor"
                              data-type="close"
                            ></bdt>{" "}
                            OR <bdt className="block-component"></bdt>
                            <bdt className="question">$50.00 USD</bdt>
                            <bdt className="statement-end-if-in-editor"></bdt>
                            <bdt className="block-component"></bdt>
                          </bdt>
                        </bdt>
                      </bdt>
                      <span>
                        <bdt
                          className="block-container if"
                          data-type="if"
                          id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
                        >
                          <bdt
                            className="statement-end-if-in-editor"
                            data-type="close"
                          >
                            <span data-custom-class="body_text">.</span>
                          </bdt>
                          &nbsp;
                        </bdt>
                      </span>
                      <span data-custom-class="body_text">
                        CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT
                        ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION
                        OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO
                        YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS
                        MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL
                        RIGHTS.
                      </span>
                    </bdt>
                  </bdt>
                </span>
                <bdt className="statement-end-if-in-editor" data-type="close">
                  <span data-custom-class="body_text"></span>
                </bdt>
              </bdt>
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="indemnification"
          >
            <strong>
              <span>
                <strong>
                  <span>
                    <strong>
                      <span>
                        <strong>
                          <span>24.</span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                INDEMNIFICATION
              </span>
            </strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              You agree to defend, indemnify, and hold us harmless, including
              our subsidiaries, affiliates, and all of our respective officers,
              agents, partners, and employees, from and against any loss,
              damage, liability, claim, or demand, including reasonable
              attorneys’ fees and expenses, made by any third party due to or
              arising out of:{" "}
              <bdt
                className="block-container if"
                data-type="if"
                id="475fffa5-05ca-def8-ac88-f426b238903c"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="user_post_content_option"
                    data-type="statement"
                  ></bdt>
                  <bdt data-type="body">(1) your Contributions;&nbsp;</bdt>
                </bdt>
                <bdt
                  className="statement-end-if-in-editor"
                  data-type="close"
                ></bdt>
              </bdt>
              (<span>2</span>) use of the Services; (<span>3</span>) breach of
              these Legal Terms; (<span>4</span>) any breach of your
              representations and warranties set forth in these Legal Terms; (
              <span>5</span>) your violation of the rights of a third party,
              including but not limited to intellectual property rights; or (
              <span>6</span>) any overt harmful act toward any other user of the
              Services with whom you connected via the Services. Notwithstanding
              the foregoing, we reserve the right, at your expense, to assume
              the exclusive <bdt className="block-component"></bdt>
              defense<bdt className="statement-end-if-in-editor"></bdt> and
              control of any matter for which you are required to indemnify us,
              and you agree to cooperate, at your expense, with our{" "}
              <bdt className="block-component"></bdt>
              defense<bdt className="statement-end-if-in-editor"></bdt> of such
              claims. We will use reasonable efforts to notify you of any such
              claim, action, or proceeding which is subject to this
              indemnification upon becoming aware of it.
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="userdata"
          >
            <strong>
              <span>
                <strong>
                  <span>
                    <strong>
                      <span>
                        <strong>
                          <span>25.</span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                USER DATA
              </span>
            </strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              We will maintain certain data that you transmit to the Services
              for the purpose of managing the performance of the Services, as
              well as data relating to your use of the Services. Although we
              perform regular routine backups of data, you are solely
              responsible for all data that you transmit or that relates to any
              activity you have undertaken using the Services. You agree that we
              shall have no liability to you for any loss or corruption of any
              such data, and you hereby waive any right of action against us
              arising from any such loss or corruption of such data.
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="electronic"
          >
            <strong>
              <span>
                <strong>
                  <span>
                    <strong>
                      <span>
                        <strong>
                          <span>26.</span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
              </span>
            </strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              Visiting the Services, sending us emails, and completing online
              forms constitute electronic communications. You consent to receive
              electronic communications, and you agree that all agreements,
              notices, disclosures, and other communications we provide to you
              electronically, via email and on the Services, satisfy any legal
              requirement that such communication be in writing. YOU HEREBY
              AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
              OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES,
              AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA
              THE SERVICES. You hereby waive any rights or requirements under
              any statutes, regulations, rules, ordinances, or other laws in any
              jurisdiction which require an original signature or delivery or
              retention of non-electronic records, or to payments or the
              granting of credits by any means other than electronic means.
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal">
            <bdt className="block-component">
              <span></span>
            </bdt>
            <bdt className="block-component"></bdt>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="california"
          >
            <strong>
              <span>
                <strong>
                  <span>
                    <strong>
                      <span>
                        <strong>
                          <span>27.</span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                CALIFORNIA USERS AND RESIDENTS
              </span>
            </strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              If any complaint with us is not satisfactorily resolved, you can
              contact the Complaint Assistance Unit of the Division of Consumer
              Services of the California Department of Consumer Affairs in
              writing at 1625 North Market Blvd., Suite N 112, Sacramento,
              California 95834 or by telephone at (800) 952-5210 or (916)
              445-1254.
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal">
            <bdt className="statement-end-if-in-editor"></bdt>
          </div>
          <div className="MsoNormal" data-custom-class="heading_1" id="misc">
            <strong>
              <span>
                <strong>
                  <span>
                    <strong>
                      <span>
                        <strong>
                          <span>28.</span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                MISCELLANEOUS
              </span>
            </strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              These Legal Terms and any policies or operating rules posted by us
              on the Services or in respect to the Services constitute the
              entire agreement and understanding between you and us. Our failure
              to exercise or enforce any right or provision of these Legal Terms
              shall not operate as a waiver of such right or provision. These
              Legal Terms operate to the fullest extent permissible by law. We
              may assign any or all of our rights and obligations to others at
              any time. We shall not be responsible or liable for any loss,
              damage, delay, or failure to act caused by any cause beyond our
              reasonable control. If any provision or part of a provision of
              these Legal Terms is determined to be unlawful, void, or
              unenforceable, that provision or part of the provision is deemed
              severable from these Legal Terms and does not affect the validity
              and enforceability of any remaining provisions. There is no joint
              venture, partnership, employment or agency relationship created
              between you and us as a result of these Legal Terms or use of the
              Services. You agree that these Legal Terms will not be construed
              against us by virtue of having drafted them. You hereby waive any
              and all <bdt className="block-component"></bdt>defenses
              <bdt className="statement-end-if-in-editor"></bdt> you may have
              based on the electronic form of these Legal Terms and the lack of
              signing by the parties hereto to execute these Legal Terms.
            </span>
          </div>
          <div className="MsoNormal">
            <bdt className="block-component">
              <span></span>
            </bdt>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="heading_1" id="contact">
            <strong>
              <span>
                <span>
                  <strong>
                    <span>
                      <strong>
                        <span>
                          <strong>
                            <span>29.</span>
                          </strong>
                        </span>
                      </strong>
                    </span>
                    &nbsp;
                  </strong>
                  CONTACT US
                </span>
              </span>
            </strong>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              In order to resolve a complaint regarding the Services or to
              receive further information regarding use of the Services, please
              contact us at:
            </span>
          </div>
          <div className="MsoNormal">
            <br></br>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              <span>
                <bdt className="question">
                  <strong>Andrew's Cookin'</strong>
                </bdt>
                <strong>
                  <bdt className="block-component"></bdt>
                </strong>
              </span>
            </span>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              <span>
                <bdt className="question">
                  <strong>
                    <bdt className="question">Taikos pr.97</bdt>
                  </strong>
                </bdt>
                <span>
                  <bdt className="statement-end-if-in-editor"></bdt>
                </span>
                <bdt className="block-component"></bdt>
              </span>
            </span>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <span>
              <strong>
                <span>
                  <bdt className="question">
                    <bdt className="block-component"></bdt>
                    <bdt className="question">Kaunas</bdt>
                    <bdt className="statement-end-if-in-editor"></bdt>
                  </bdt>
                  <bdt className="block-component"></bdt>,{" "}
                  <bdt className="question">Kaunas city</bdt>
                  <bdt className="statement-end-if-in-editor"></bdt>
                  <bdt className="block-component"></bdt>{" "}
                  <bdt className="question">50450</bdt>
                  <bdt className="statement-end-if-in-editor"></bdt>
                </span>
              </strong>
              <strong>
                <span>
                  <bdt className="block-component"></bdt>
                </span>
                <bdt className="block-component"></bdt>
              </strong>
            </span>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <bdt className="block-component">
              <strong></strong>
            </bdt>
            <strong>
              <bdt className="question">Lithuania</bdt>
            </strong>
            <bdt className="statement-end-if-in-editor"></bdt>
            <bdt className="statement-end-if-in-editor"></bdt>
            <bdt className="statement-end-if-in-editor">
              <strong></strong>
            </bdt>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <strong>
              <span>
                <strong>
                  <bdt className="block-component"></bdt>
                </strong>
              </span>
              &nbsp;
            </strong>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <strong>
              <span>
                <strong>
                  <bdt className="block-component"></bdt>
                </strong>
              </span>
            </strong>
          </div>
          <div className="MsoNormal" data-custom-class="body_text">
            <strong>
              <span>
                <strong>
                  <bdt className="question">
                    <bdt className="block-component"></bdt>
                    <bdt className="question">info@andrewscookin.com</bdt>
                    <bdt className="statement-end-if-in-editor"></bdt>
                  </bdt>
                </strong>
              </span>
            </strong>
          </div>
        </div>
        <Footer></Footer>
      </Container>
    );
  }
}
export default Terms;
