import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import "bootstrap/dist/css/bootstrap.min.css";
import registerServiceWorker from "./components/api/RegisterServiceWorker";

import "./i18n";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "G-8B7DRD1PE4",
};

const mystyle = {
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
};

function Loading() {
  return (
    <div className="bg-dark text-white" style={mystyle}>
      Loading webpage...
    </div>
  );
}
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <React.Suspense fallback={<Loading></Loading>}>
        <App />
      </React.Suspense>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

registerServiceWorker();
