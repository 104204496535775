import React, { Component } from "react";
import { Link } from "react-router-dom";
import { wp } from "../api/WpApi";
import { withRouter } from "./withRouter"; // https://stackoverflow.com/a/71043072
import WpComments from "react-wordpress-comments";
import "../../styles/wpCommentStyles.scss";
// import "react-wordpress-comments/css/styles.css";
import WpService from "./WpService";
// import WpComments from "./wpComments/wpComments";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

import data from "./_setup.json";

import PageLoading from "./pageLoading";
import { Footer } from "../main/Footer";
import { PostBadge } from "../alerts/PostBadge";

class PostView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoading: true,
      posts: [],
      post: {},
      tags: [],
    };
  }

  componentDidMount() {
    this.getPost(
      this.props.match.params.post ? this.props.match.params.post : "404"
    );
  }

  // parseStarRating(content) {
  //   const el = document.querySelector(
  //     "#root > div > div > div > div:nth-child(7) > div > div.kksr-legend"
  //   );
  //   // return el.lenght;
  // }

  getPost = (s) => {
    // Fetch post with slug "xyz": http://wp-api.org/node-wpapi/using-the-client/#api-query-parameters
    wp.posts()
      .slug(s)
      .then((posts) => {
        //console.log(posts);

        this.setState({
          isLoading: false,
          post: posts[0], // Get first element from array
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  };

  render() {
    const { post, error, isLoading } = this.state;

    if (error) {
      return <div className="content error">Error: {error.message}</div>;
    } else if (isLoading) {
      return (
        <div>
          <PageLoading />
        </div>
      );
    } else if (post !== null) {
      this.state.tags = WpService.getItemFromClassList(post.class_list, "tag-");
      return (
        <div className="post container">
          <div className="flex" id="back-top">
            <Link
              to={"/" + data.url.slug_posts + "/"}
              className="btn btn-outline-secondary animate-pulse"
            >
              Back
            </Link>
          </div>
          {/* <svg
            className="w-4 h-4 text-yellow-300 me-1"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 22 20"
          >
            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
          </svg>
          <p className="ms-2 text-sm font-bold text-gray-900 dark:text-white">
            {this.parseStarRating(post.content.rendered)}
          </p> */}
          <div className="text-right">
            <small className="text-muted">
              {new Date(post.date).toLocaleDateString(
                data.format.date.locale,
                data.format.date.options
              )}
            </small>
            {post.author === 1 && (
              <PostBadge
                text="Official Recipe"
                style="absolute post-badge"
                color="success"
              ></PostBadge>
            )}
          </div>
          <h1
            className="text-center"
            dangerouslySetInnerHTML={{ __html: post.title.rendered }}
          />
          <hr />
          {this.state.tags && this.state.tags.length > 0 && (
            <div className="post-tag">
              Tags:
              <div className="container">
                {this.state.tags.map((item) => (
                  <PostBadge
                    key={item}
                    text={item}
                    style="child-tag"
                    color="danger"
                  ></PostBadge>
                ))}
              </div>
            </div>
          )}
          <div
            className="post-text-wrapper text-left"
            dangerouslySetInnerHTML={{ __html: post.content.rendered }}
          />
          <div className="text-center">
            <Link
              to={"/" + data.url.slug_posts + "/"}
              className="btn btn-outline-secondary"
            >
              Back to All Recipes
            </Link>
          </div>
          <hr />
          <div className="container form-group">
            {post.comment_status === "open" && (
              <WpComments
                maxDepth={3} // provide depth that you want comments to be nested in a list
                pageId={post.id} // id of a page you want to fetch comments from and post to
                hostUrl={process.env.REACT_APP_WP_HOST_PAGE} // url of your WordPress website
                allowComments={true} // can users post comments?
                user={null} // provide user if authorized, default is null. See below
                class="form-control"
              />
            )}
          </div>

          {post.yoast_head != null &&
            (<div>
              <Helmet>{parse(post.yoast_head)}</Helmet>
            
              <Helmet>
                <title>{post.yoast_head_json.title}</title>
              </Helmet>
            </div>)}
          {this.state.tags != null && (
            <Helmet>
              <meta
                name="parsely-tags"
                content={this.state.tags.join(",")}
              ></meta>
            </Helmet>
          )}

          <hr />
          <Footer></Footer>
        </div>
      );
    } else {
      return (
        <div className="content 404">
          <Helmet>
            <meta name="robots" content="noindex"></meta>
          </Helmet>
          404 - Not found!
        </div>
      );
    }
  }
}

export default withRouter(PostView);
