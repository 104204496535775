import React from "react";
import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import data from "./_setup.json";

const Autocomplete = (props) => {
  return (
    <ListGroup id="autocomplete-result" as="ul" className="shadow-sm">
      {props.results.map((post, i) => (
        <ListGroup.Item as="li">
          <Link to={"/" + data.url.slug_posts + "/" + post.slug}>
            {post.title.rendered}
          </Link>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default Autocomplete;
